<template>
  <div class="d-flex create-user-view secondary" v-if="isPasswordReset">
    <v-card
      elevation="24"
      class="pa-5 mx-auto my-auto d-flex flex-column"
      max-width="344"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title
            class="mt-5 text-h5 font-weight-bold mb-1 text-center"
          >
            Reset Password
          </v-list-item-title>
          <v-list-item-subtitle class="mb-1 text-center"
            >Please enter a new password to be used on your next
            login</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="text-center"
        style="width: 100%"
      >
        <v-text-field
          @focus="resetIncorrectAuth"
          v-model="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="passwordRules"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Password"
          @click:append="show1 = !show1"
          outlined
          dense
          prepend-inner-icon="mdi-key"
        ></v-text-field>

        <v-text-field
          @focus="resetIncorrectAuth"
          v-model="passwordConfirm"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[passwordMatching]"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Confirm Password"
          @click:append="show1 = !show1"
          outlined
          dense
          prepend-inner-icon="mdi-key"
        ></v-text-field>

        <v-btn
          :disabled="!valid"
          elevation="0"
          color="primary"
          class="mb-5 text-capitalize"
          @click="takeEmailAction"
        >
          Reset Password
        </v-btn>
        <v-alert v-if="incorrectAuth" dense type="error" id="alert">
          {{ incorrectAuthMsg }}
        </v-alert>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { axiosUnsafeApi } from "@/modules/axios/axiosApi";

export default {
  name: "ViewEmailAction",
  data() {
    return {
      awaitingResponse: true,
      isPasswordReset: false,
      show1: false,
      valid: true,
      passwordRules: [(v) => !!v || "Password is required"],
      password: "",
      passwordConfirm: "",
      incorrectAuth: false,
      incorrectAuthMsg: "",
    };
  },
  methods: {
    async takeEmailAction() {
      this.awaitingResponse = true;
      const payload = {};
      if (this.isPasswordReset) {
        payload["password"] = this.password;
      }
      const resp = await axiosUnsafeApi
        .put(`api/mail_ses/action/${this.$route.params.uid}/`, payload)
        .catch((err) => {
          if (err.response) {
            switch (err.response.status) {
              case 400:
                this.isPasswordReset = true;
                break;
              case 404:
                this.$router.push({ name: "Not Found" });
                break;
              default:
                throw err;
            }
          }
        });
      this.awaitingResponse = false;
      if (resp && resp.status === 200) {
        await this.$store.dispatch("userLogout");
        return;
      }
      this.isPasswordReset = true;
    },
    passwordMatching() {
      return this.password === this.passwordConfirm
        ? true
        : "Password does not match";
    },
    resetIncorrectAuth() {
      this.incorrectAuth = false;
      this.incorrectAuthMsg = "";
    },
  },
  created() {
    this.takeEmailAction();
  },
};
</script>

<style scoped>
.create-user-view {
  width: 100%;
}

#alert {
  white-space: break-spaces;
  text-align: left;
}
</style>