var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('v-card',{class:'video-overlay ma-0 rounded-0 ' + ((_vm.show) ? 'expanded' : 'collapsed')},[_c('v-divider'),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.activeStreamStore.name)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-card-subtitle',{staticClass:"text-start ma-1 pa-0"},[_vm._v("Viewers")]),_c('StreamViewers')],1),_c('v-divider'),_c('v-card-text',[_c('StreamAddIndividualsCard',{staticClass:"mx-auto pa-2 d-block",attrs:{"without-viewers":""}}),_c('StreamTeamSelection',{staticClass:"mx-auto pa-2 d-block"})],1),_c('v-divider'),(
      _vm.activeStreamStore.unknownViewers &&
      _vm.activeStreamStore.unknownViewers.length > 0
    )?_c('v-card-text',[_c('v-card-subtitle',{staticClass:"text-start ma-1 pa-0"},[_vm._v("Unknown viewers")]),_c('v-list',{staticClass:"transparent",attrs:{"dense":""}},_vm._l((_vm.activeStreamStore.unknownViewers),function(viewer){return _c('v-list-item',{key:viewer},[_vm._v(" "+_vm._s(viewer)+" ")])}),1)],1):_vm._e(),_c('v-divider'),(
      _vm.activeStreamStore.invalidViewers &&
      _vm.activeStreamStore.invalidViewers.length > 0
    )?_c('v-card-text',[_c('v-card-subtitle',{staticClass:"text-start ma-1 pa-0"},[_vm._v("Invalid viewers")]),_c('v-list',{staticClass:"transparent",attrs:{"dense":""}},_vm._l((_vm.activeStreamStore.invalidViewers),function(viewer){return _c('v-list-item',{key:viewer},[_vm._v(" "+_vm._s(viewer)+" ")])}),1)],1):_vm._e()],1):_c('v-btn',{staticClass:"rounded-0",attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v("mdi-menu")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }