import mem from "mem";
import { axiosApi } from "./axiosApi";

const refreshTokenFn = async () => {
  const refreshToken = sessionStorage.getItem("refreshToken");
  try {
    const response = await axiosApi.post("/api/token/refresh/", {
      refresh: refreshToken,
    });

    const session = response.data;

    if (!session?.access) {
      sessionStorage.removeItem("accessToken");
      sessionStorage.removeItem("refreshToken");
      sessionStorage.removeItem("authenticated");
    }
    
    sessionStorage.setItem("accessToken", session.access);
    sessionStorage.setItem("refreshToken", session.refresh);
    sessionStorage.setItem("authenticated", String(true));

    return session;

  } catch (error) {
    console.log(error)
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("authenticated");
  }
};

const maxAge = 10000;

export const memorizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});