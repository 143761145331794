import { undefinedOrValue } from "@/store/helpers";

export const DefaultColours = {
    "darkPrimary": "#BA78FF",
    "darkSecondary": "#006EFF",
    "lightPrimary": "#BA78FF",
    "lightSecondary": "#006EFF",
}

export default {
    companyID: undefinedOrValue(localStorage.getItem('companyID')),
    companyName: undefinedOrValue(localStorage.getItem('companyName')),
    darkPrimaryColour: undefinedOrValue(localStorage.getItem('darkPrimaryColour')) || DefaultColours.darkPrimary,
    darkSecondaryColour: undefinedOrValue(localStorage.getItem('darkSecondaryColour')) || DefaultColours.darkSecondary,
    lightPrimaryColour: undefinedOrValue(localStorage.getItem('lightPrimaryColour')) || DefaultColours.lightPrimary,
    lightSecondaryColour: undefinedOrValue(localStorage.getItem('lightSecondaryColour')) || DefaultColours.lightSecondary,
    logo: undefinedOrValue(localStorage.getItem('companyLogo')) || "https://app.irisstream.co/logo-icon.png",
    icon: undefinedOrValue(localStorage.getItem('companyIcon')) || "https://app.irisstream.co/logo-icon.png",
    memberPermissions: undefinedOrValue(localStorage.getItem('memberPermissions')) ?
        JSON.parse(localStorage.getItem('memberPermissions')) : null,
    allPermissions: undefinedOrValue(localStorage.getItem('allPermissions')) ?
        JSON.parse(localStorage.getItem('allPermissions')) : null
}
