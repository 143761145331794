import { render, staticRenderFns } from "./OrganisationSelect.vue?vue&type=template&id=0160e7fa&scoped=true&"
import script from "./OrganisationSelect.vue?vue&type=script&lang=js&"
export * from "./OrganisationSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0160e7fa",
  null
  
)

export default component.exports