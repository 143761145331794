<template>
  <v-expansion-panels
      flat
      focusable
  >
    <v-tabs focusable>
      <v-tab v-for="categories in items" :key="categories.title">
        <v-icon left small>{{ categories.icon }}</v-icon>
        {{ categories.title }}
      </v-tab>
      <v-tab-item v-for="categories in items" :key="categories.title">
        <v-divider/>

        <ContentSettings
            :description="categories.description"
            :subtitle="categories.subtitle"
        >
          <template v-slot:center>
            <component
                :is="categories.component"
                :description="categories.description"
                :subtitle="categories.subtitle"
            ></component>
          </template>
        </ContentSettings>

        <v-tabs focusable>
          <v-tab v-for="themeCategories in categories.themeSubItems" :key="themeCategories.title">
            <v-icon left small>{{ themeCategories.icon }}</v-icon>
            {{ themeCategories.title }}
          </v-tab>
          <v-tab-item v-for="themeCategories in categories.themeSubItems" :key="themeCategories.title">
            <v-divider/>

            <ContentSettings
                :description="themeCategories.description"
                :subtitle="themeCategories.subtitle"
            >
              <template v-slot:center>
                <component
                    :is="themeCategories.component"
                    :description="themeCategories.description"
                    :subtitle="themeCategories.subtitle"
                ></component>
              </template>
            </ContentSettings>
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
    </v-tabs>
  </v-expansion-panels>
</template>

<script>
import ContentDiv from "@/components/ContentDiv.vue";
import ContentSettings from "@/components/content/ContentSettings.vue";
import OrganisationGeneral from "@/components/organistions/OrganisationGeneral.vue";
import OrganisationMembers from "@/components/organistions/OrganisationMembers.vue";
import OrganisationTheming from "@/components/organistions/OrganisationTheming.vue";
import OrganisationThemingLogo from "@/components/organistions/OrganisationThemeingLogo.vue";
import OrganisationThemingLight from "@/components/organistions/OrganisationThemeingLight.vue";
import OrganisationThemingDark from "@/components/organistions/OrganisationThemeingDark.vue";
import PageTitle from "@/components/PageTitle.vue";
import PageTitleDivider from "@/components/dividers/PageTitleDivider.vue";
import {mapState} from "vuex";
import TeamManagement from "@/components/teams/TeamManagement.vue";
import UserProfile from "@/components/users/UserProfile.vue";
import PageSubtitle from "@/components/PageSubtitle.vue";

export default {
  name: "OrganisationSettings",
  components: {
    PageSubtitle,
    UserProfile,
    ContentDiv,
    ContentSettings,
    OrganisationGeneral,
    OrganisationMembers,
    OrganisationTheming,
    OrganisationThemingLogo,
    OrganisationThemingLight,
    OrganisationThemingDark,
    PageTitle,
    PageTitleDivider,
    TeamManagement,
  },
  data: () => ({
    items: [
      {
        title: "General",
        subtitle: "Organisation Information",
        description:
            "Use this tab to update your organisation's information",
        icon: "mdi-information",
        component: OrganisationGeneral,
      },
      {
        title: "Members",
        subtitle: "Member Management",
        description:
            "Use this tab to update your organisation's members and their permissions",
        icon: "mdi-account-group",
        component: OrganisationMembers,
      },
      {
        title: "Teams",
        subtitle: "Team Management",
        description:
            "Use this tab to manage your organisation's teams and their members",
        icon: "mdi-account-group",
        component: TeamManagement,
      },
      {
        title: "Theming",
        icon: "mdi-palette",
        component: "",
        themeSubItems: [
          {
            title: "Logo",
            subtitle: "Custom Logo",
            description:
                "Use this tab to update your organisation's logo assets",
            icon: "mdi-palette",
            component: OrganisationThemingLogo,
          },
          {
            title: "Dark Colours",
            subtitle: "Dark Colours",
            description:
                "Use this tab to update your organisation's colours when in dark mode",
            icon: "mdi-palette",
            component: OrganisationThemingDark,
          },
          {
            title: "Light Colours",
            subtitle: "Light Colours",
            description:
                "Use this tab to update your organisation's colours when in light mode",
            icon: "mdi-palette",
            component: OrganisationThemingLight,
          },
        ],
      },
    ],
  }),
  methods: {},
  async created() {
    this.$store.dispatch("getAllPermissions");
  },
  computed: {
    ...mapState(["organisationStore"]),
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  letter-spacing: 0;
  text-transform: none;
  font-weight: 400;
}

.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.active-border {
  border: 1px solid grey;
}
</style>
