import {axiosApi} from "src/modules/axios/axiosApi.js";
import {
    STSClient,
    GetCallerIdentityCommand
} from "@aws-sdk/client-sts";
import axios from "axios";

export const AWS_REGION = "af-south-1";

export let AWS_ACCESS_KEY_ID;
export let AWS_SECRET_ACCESS_KEY;
export let AWS_SESSION_TOKEN;
export let clientID;

export function setAWSCredentials(credentials) {
    AWS_ACCESS_KEY_ID = credentials["AccessKeyId"];
    AWS_SECRET_ACCESS_KEY = credentials["SecretAccessKey"];
    AWS_SESSION_TOKEN = credentials["SessionToken"];
}

export async function checkAccessTokens(axiosInstance) {
    const api = axiosInstance || axiosApi;
    if (!(AWS_ACCESS_KEY_ID&&AWS_SECRET_ACCESS_KEY)) {
        const resp = await api.get("/api/streams/auth/");
        setAWSCredentials(resp.data);
    }
    if (AWS_REGION == null) {
        throw ReferenceError("Region not set.")
    }
    if (clientID == null) {
        throw ReferenceError("Client ID not set.")
    }
    return true
}

export async function validateCredentials() {
    const client = new STSClient({
        region: AWS_REGION,
        credentials: {
            accessKeyId: AWS_ACCESS_KEY_ID,
            secretAccessKey: AWS_SECRET_ACCESS_KEY,
            sessionToken: AWS_SESSION_TOKEN
        }
    });
    const command = new GetCallerIdentityCommand({});
    return await client.send(command);
}

export function setClientID(id) {
    clientID = id;
}