import Vuetify from '@/plugins/vuetify'
import {DefaultColours} from "@/store/modules/organisationStore/state";
import {undefinedOrValue} from "@/store/helpers";

export default {
	newOrganisationStore(state, {
		companyID,
		companyName,
		darkPrimaryColour,
		darkSecondaryColour,
		lightPrimaryColour,
		lightSecondaryColour,
		logo,
		icon,
		memberPermissions,
		allPermissions
	}) {
		state.companyID = companyID;
		state.companyName = companyName;
		state.darkPrimaryColour = darkPrimaryColour;
		state.darkSecondaryColour = darkSecondaryColour;
		state.lightPrimaryColour = lightPrimaryColour;
		state.lightSecondaryColour = lightSecondaryColour;
		state.memberPermissions = memberPermissions;
		state.allPermissions = allPermissions;
		state.logo = undefinedOrValue(logo) || new URL("@/assets/logo.svg", import.meta.url).href;
		state.icon = undefinedOrValue(icon) || new URL("@/assets/logo-icon.svg", import.meta.url).href;
		localStorage.setItem('companyID', JSON.stringify(companyID));
		localStorage.setItem('companyName', JSON.stringify(companyName));
		localStorage.setItem('darkPrimaryColour', JSON.stringify(darkPrimaryColour));
		localStorage.setItem('darkSecondaryColour', JSON.stringify(darkSecondaryColour));
		localStorage.setItem('lightPrimaryColour', JSON.stringify(lightPrimaryColour));
		localStorage.setItem('lightSecondaryColour', JSON.stringify(lightSecondaryColour));
		localStorage.setItem('companyLogo', state.logo);
		localStorage.setItem('companyIcon', state.icon);
		localStorage.setItem('memberPermissions', JSON.stringify(memberPermissions));
		localStorage.setItem('allPermissions', JSON.stringify(allPermissions));
		setVuetifyThemeColours(darkPrimaryColour, darkSecondaryColour, lightPrimaryColour, lightSecondaryColour);
	},
	setOrganisationDetail(state, {
		companyID,
		companyName,
		darkPrimaryColour,
		darkSecondaryColour,
		lightPrimaryColour,
		lightSecondaryColour
	}) {
		state.companyID = companyID;
		state.companyName = companyName;
		state.darkPrimaryColour = darkPrimaryColour;
		state.darkSecondaryColour = darkSecondaryColour;
		state.lightPrimaryColour = lightPrimaryColour;
		state.lightSecondaryColour = lightSecondaryColour;
		localStorage.setItem('companyID', companyID);
		localStorage.setItem('companyName', companyName);
		localStorage.setItem('darkPrimaryColour', darkPrimaryColour);
		localStorage.setItem('darkSecondaryColour', darkSecondaryColour);
		localStorage.setItem('lightPrimaryColour', lightPrimaryColour);
		localStorage.setItem('lightSecondaryColour', lightSecondaryColour);
		setVuetifyThemeColours(darkPrimaryColour, darkSecondaryColour, lightPrimaryColour, lightSecondaryColour);
	},
	setOrganisationID(state, id) {
		if (id === "undefined") id = null;
		state.companyID = id
		localStorage.setItem('companyID', id)
	},
	setOrganisationPermissions(state, allPermissions) {
		state.allPermissions = allPermissions
		localStorage.setItem('allPermissions', JSON.stringify(allPermissions))
	},
	setMemberPermissions(state, memberPermissions) {
		state.memberPermissions = memberPermissions
		localStorage.setItem('memberPermissions', JSON.stringify(memberPermissions))
	},
	setCompanyTheme(state, {darkPrimaryColour, darkSecondaryColour, lightPrimaryColour, lightSecondaryColour}) {
		state.darkPrimaryColour = darkPrimaryColour;
		state.darkSecondaryColour = darkSecondaryColour;
		state.lightPrimaryColour = lightPrimaryColour;
		state.lightSecondaryColour = lightSecondaryColour;
		localStorage.setItem('darkPrimaryColour', darkPrimaryColour);
		localStorage.setItem('darkSecondaryColour', darkSecondaryColour);
		localStorage.setItem('lightPrimaryColour', lightPrimaryColour);
		localStorage.setItem('lightSecondaryColour', darkSecondaryColour);
		setVuetifyThemeColours(darkPrimaryColour, darkSecondaryColour, lightPrimaryColour, lightSecondaryColour);
	},
	setCompanyAssets(state, {logo, icon}) {
		state.logo = undefinedOrValue(logo) || new URL("@/assets/logo.svg", import.meta.url).href
		state.icon = undefinedOrValue(icon) || new URL("@/assets/logo-icon.svg", import.meta.url).href
		localStorage.setItem('companyLogo', state.logo)
		localStorage.setItem('companyIcon', state.icon)
	}
}

function setVuetifyThemeColours(darkPrimary, darkSecondary, lightPrimary, lightSecondary) {
	Vuetify.framework.theme.themes.dark.primary = undefinedOrValue(darkPrimary) || DefaultColours.darkPrimary;
	Vuetify.framework.theme.themes.dark.secondary = undefinedOrValue(darkSecondary) || DefaultColours.darkSecondary;
	Vuetify.framework.theme.themes.light.primary = undefinedOrValue(lightPrimary) || DefaultColours.lightPrimary;
	Vuetify.framework.theme.themes.light.secondary = undefinedOrValue(lightSecondary) || DefaultColours.lightSecondary;
}