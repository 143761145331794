<template>
  <v-container>
    <canvas
        class="video-element mx-auto mb-4 pa-2"
        id="video-canvas"
        @click="changeState()"
    >
    </canvas>
  </v-container>
</template>

<script>
/**
 * TODO Add video controls
 * @link https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement
 */
export default {
  name: "StreamPlaybackCanvas",
  props: {
    source: {
      require: true,
      type: String
    },
    title: {
      require: true,
      type: String
    }
  },
  data() {
    return {
      video: null,
      canvas: null,
      canvasContext: null,
      callbackID: null,
      titleBox: {}
    }
  },
  watch: {
    source: {
      handler(value) {
        if (value) {
          this.createVideoProxy();
        }
      }
    }
  },
  methods: {
    createVideoProxy() {
      this.video = document.createElement('video');
      this.video.src = this.source;

      this.video.addEventListener('loadeddata', () => {
        this.canvas.width = this.video.videoWidth;
        this.canvas.height = this.video.videoHeight;
      }, false);
      this.video.addEventListener("play", () => {
        this.draw()
      }, false);
      this.video.addEventListener("pause", () => {
        window.cancelAnimationFrame(this.callbackID);
        this.callbackID = undefined;
      }, false);
      this.video.addEventListener("ended", () => {
        window.cancelAnimationFrame(this.callbackID);
        this.callbackID = undefined;
      }, false);
      this.video.play();
    },
    draw() {
      this.callbackID = window.requestAnimationFrame(this.draw);
      this.canvasContext.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight);

      this.canvasContext.fillStyle = "rgba(150, 150, 150, 0.8)";
      this.canvasContext.fillRect(this.titleBox.x, this.titleBox.y, this.titleBox.width, this.titleBox.height);
      this.canvasContext.fillStyle = "#FFFFFF";
      this.canvasContext.font = "48px serif";
      this.canvasContext.textBaseline = "top";
      this.canvasContext.fillText(
          this.title,
          this.titleBox.x + this.titleBox.width/2 - this.titleBox.textWidth/2,
          this.titleBox.y + this.titleBox.height/2 - this.titleBox.textHeight/2
      );
    },
    changeState() {
      if (this.video.paused) {
        this.video.play();
      } else {
        this.video.pause();
      }
    }
  },
  mounted() {
    this.canvas = document.getElementById('video-canvas');
    this.canvasContext = this.canvas.getContext('2d');

    this.canvasContext.font = "48px serif";

    const textMetrics = this.canvasContext.measureText(this.title);
    this.titleBox = {
      x: 10,
      y: 10,
      width: (textMetrics.actualBoundingBoxLeft + textMetrics.actualBoundingBoxRight)*1.2,
      height: (textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent)*1.2,
      textWidth: textMetrics.width,
      textHeight: (textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent)
    }
  }
}
</script>

<style scoped lang="scss">

.video-element {
  width: 100%;
  height: 100%;
  min-height: 50%;
}

</style>