<template>
  <h2 class="text--center page-title"><slot></slot></h2>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.page-title {
  font-size: 1.05em;
}
</style>