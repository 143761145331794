<template>
  <v-btn
    v-bind:small="size === 'small'"
    v-bind:large="size === 'large'"
    v-on="$listeners"
    elevation="0"
    outlined
    :color="color"
    @click="$emit('custom-click')"
    :disabled="disabled"
  >
    <span><slot></slot></span>
    <v-icon v-if="icon" v-bind:right="!!this.$slots.default">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>
  
<script>
export default {
  name: "ButtonPrimaryAction",
  props: {
    size: {
      type: String,
      default: "medium",
      validator: (value) => ["small", "medium", "large"].includes(value),
    },
    icon: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    on: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
  },
};
</script>