<template>
  <v-scroll-x-transition group hide-on-leave>
    <v-chip
      v-for="(selection, i) in activeStreamStore.viewers"
      :key="i"
      close
      class="ma-1"
      dark
      small
      @click:close="removeSelection(selection)"
      :style="{
        backgroundColor: backgroundColor,
        color: contentColor,
      }"
    >
      <v-icon left small>mdi-account</v-icon>
      {{ selection }}
    </v-chip>
  </v-scroll-x-transition>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "StreamViewers",
  methods: {
    removeSelection(email) {
      this.$store.dispatch("removeStreamViewer", {
        remove: [email],
      });
    },
  },
  computed: {
    ...mapState(["activeStreamStore"]),
    backgroundColor() {
      return this.$vuetify.theme.currentTheme.primary;
    },
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
</style>