<template>
  <v-container>
    <v-row>
      <WebRTCConnect
          :connected="connected"
          :enabled="!connection_state_wait"
          @connectClicked="connect"
          @disconnectClicked="disconnect"
      ></WebRTCConnect>
    </v-row>
    <v-row>
      <v-col :lg="12" :md="12" :sm="12" class="justify-center" cols="12">
        <video
            v-show="connected"
            id="remote_video_feed"
            autoplay
            class="video_feed"
            controls
            playsinline
        >
          Remote feed
        </video>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {Stream} from "src/modules/webrtc/stream.mjs";
import WebRTCConnect from "../buttons/WebRTCConnect.vue";

export default {
  name: "ViewerUnauthorised",
  components: {
    WebRTCConnect,
  },
  props: {
    viewerID: {
      require: true,
      type: String
    },
    streamUUID: {
      require: true,
      type: String
    },
    axiosInstance: {
      require: true,
      type: Function
    }
  },
  data() {
    return {
      connected: false,
      connection_state_wait: false,
    };
  },
  methods: {
    connect() {
      this.connection_state_wait = true;
      this.Stream.setCustomAxiosInstance(this.axiosInstance);
      document
          .getElementById("remote_video_feed")
          .addEventListener("play", () => {
            this.connection_state_wait = false;
          });
      setTimeout(() => {
        this.connection_state_wait = false;
      }, 1000);
      this.connected = true;
      this.Stream.start();
    },
    disconnect() {
      this.connected = false;
      this.Stream.stop();
    },
  },
  mounted() {
    if (this.Stream == null) {
      this.Stream = new Stream(
          document.getElementById("remote_video_feed"),
          "VIEWER",
          this.viewerID
      );
      this.Stream.setChannelName(this.streamUUID);
    }
  }
};
</script>

<style lang="scss" scoped>
.video_feed {
  width: 100%;
  margin: auto;
  border-radius: 5px;
}

.v-toolbar__title {
  font-size: 1rem;
}

.w-50 {
  width: 50%;
}
</style>