<template>
  <v-card class="fill-height pa-0 ma-0 transparent" width="100%" elevation="0">
    <v-card-title class="mb-1 pa-0 card-stream-individual-title"
      >Add Viewers Individually</v-card-title
    >
    <v-divider />
    <v-card-text class="px-3 py-0">
      <StreamAddIndividualsAutocomplete />
    </v-card-text>
    <template v-if="!withoutViewers">
      <v-card-text>
        <v-row>
          <StreamViewers />
        </v-row>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import StreamAddIndividualsAutocomplete from "@/components/streams/StreamAddIndividualsAutocomplete.vue";
import StreamViewers from "@/components/streams/StreamViewers.vue";

export default {
  name: "StreamAddIndividualsCard",
  components: { StreamViewers, StreamAddIndividualsAutocomplete },
  props: {
    withoutViewers: {
      type: Boolean,
      require: false,
      default: false
    }
  }
};
</script>

<style scoped>
.card-stream-individual-title {
  font-size: 1rem;
}
</style>