<template>
  <v-card flat min-height="230">
    <v-toolbar dense flat>
      <v-toolbar-title class="card-stream-title">
        <slot name="title" />
      </v-toolbar-title>
      <v-spacer />
      <v-icon color="secondary"
        >mdi-numeric-{{ `${index}` }}-box-outline</v-icon
      >
    </v-toolbar>

    <v-col class="d-flex flex-column align-center">
      <slot name="content" />
    </v-col>

    <template v-if="hasActions">
      <v-divider></v-divider>
      <v-card-actions>
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
export default {
  name: "CardStreamStartStep",
  props: {
    index: {
      required: true,
      type: Number,
    },
    hasActions: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.card-stream-title {
  font-size: 1rem;
}
</style>