export default {
	updateAddressStore(state, {countries}) {
		state.countries = countries || {};
		sessionStorage.setItem('countries', JSON.stringify(state.countries));
	},
	updateCountryStates(state, {country, states}) {
		state.countries[country]['states'] = states || {};
		sessionStorage.setItem('countries', JSON.stringify(state.countries));
	},
	updateStateCities(state, {country, countryState, cities}) {
		state.countries[country]['states'][countryState]['cities'] = cities || [];
		sessionStorage.setItem('countries', JSON.stringify(state.countries));
	}
}