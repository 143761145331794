import {
    SignalingClient,
    Role
} from "amazon-kinesis-video-streams-webrtc";

import {
    AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY,
    AWS_REGION, AWS_SESSION_TOKEN
} from "../../getAccessKeys.mjs";
import {
    createSignalingChannel,
    deleteSignalingChannel,
    describeSignalingChannel,
    getChanelEndpoints,
    getSystemClockOffset
} from "../../webrtc/signalingChannel.mjs";
import {
    getICEServerConfig
} from "../iceServer.mjs";
import {
    SessionStorageItems,
    AWSErrors,
    CustomErrors
} from "src/modules/globalConstants.mjs";
import {
    ChannelARNError
} from "src/modules/errors.mjs"


const master = {
    signalingClient: null,
    peerConnectionByClientId: {},
    dataChannelByClientId: {},
    localStream: null,
    localView: null,
    remoteAudio: null,
    remoteStreams: [],
    peerConnectionStatsInterval: null,
    mediaRecorder: null,
    mediaRecorderStream: null
};

let allMediaStreams = [];

// let priorityClientID = undefined; // This should be updated from backed when implemented.

export async function startMaster(localView, onStatsReport, onRemoteDataMessage, channelName) {
    master.localView = localView;
    master.onStatsReport = onStatsReport;
    master.onRemoteDataMessage = onRemoteDataMessage;

    // Get signaling channel ARN
    let channelARN;
    try {
        const describeSignalingChannelResponse = await describeSignalingChannel(channelName);
        channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    } catch (e) {
        if (e.name === AWSErrors.ResourceNotFound) {
            console.log("Attempting to create signalling channel");
            channelARN = (await createSignalingChannel(channelName)).ChannelInfo.ChannelARN;
        } else {
            throw e;
        }
    }
    console.log('[MASTER] Channel ARN: ', channelARN);
    if (channelARN === undefined) {
        throw ChannelARNError("Channel ARN is undefined");
    }
    sessionStorage.setItem(`MASTER_${SessionStorageItems.ChannelARN}`, channelARN);

    // Get signaling channel endpoints
    let getSignalingChannelEndpointResponse;
    try {
        getSignalingChannelEndpointResponse = await getChanelEndpoints(channelARN, Role.MASTER);
    } catch (e) {
        console.error(e);
        await deleteSignalingChannel();
        startMaster(localView, onStatsReport, onRemoteDataMessage).then();
        return;
    }
    if (getSignalingChannelEndpointResponse === undefined) {
        await deleteSignalingChannel();
        startMaster(localView, onStatsReport, onRemoteDataMessage).then();
        return;
    }
    const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
        (endpoints, endpoint) => {
            endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
            return endpoints;
        }, {});
    console.log('[MASTER] Endpoints: ', endpointsByProtocol);

    // Create WebRTC Signaling Client
    master.signalingClient = new SignalingClient({
        channelARN,
        channelEndpoint: endpointsByProtocol.WSS,
        role: Role.MASTER,
        region: AWS_REGION,
        credentials: {
            accessKeyId: AWS_ACCESS_KEY_ID,
            secretAccessKey: AWS_SECRET_ACCESS_KEY,
            sessionToken: AWS_SESSION_TOKEN
        },
        systemClockOffset: getSystemClockOffset(),
    });

    // Get ICE server configuration
    const iceServers = [];
    try {
        const getIceServerConfigResponse = await getICEServerConfig(endpointsByProtocol.HTTPS);
        iceServers.push({
            "urls": `stun:stun.kinesisvideo.${AWS_REGION}.amazonaws.com:443`
        });
        getIceServerConfigResponse.IceServerList.forEach(iceServer =>
            iceServers.push({
                urls: iceServer.Uris,
                username: iceServer.Username,
                credential: iceServer.Password,
            }),
        );
        console.log('[MASTER] ICE servers: ', iceServers);
    } catch (e) {
        switch (e.name) {
            case AWSErrors.ResourceInUse:
                await deleteSignalingChannel();
                startMaster(localView, onStatsReport, onRemoteDataMessage).then();
                return;
            case CustomErrors.ActiveChannel:
                startMaster(localView, onStatsReport, onRemoteDataMessage).then();
                return;
            default:
                console.log("Error at ICE Server config.")
                throw e;
        }
    }

    // RTCPeerConnection configuration
    const configuration = {
        iceServers,
        iceTransportPolicy: 'relay', //'all',
        sdpSemantics:'unified-plan'
    };

    const resolution = { width: { ideal: 1920 }, height: { ideal: 1080 } };
    const constraints = {
        video: resolution,
        audio: false,
    };
    await setUserMedia(constraints);

    master.signalingClient.on('open', async () => {
        console.log('[MASTER] Connected to signaling service');
    });

    master.signalingClient.on('sdpOffer', async (offer, remoteClientId) => {
        console.log('[MASTER] Received SDP offer from client: ' + remoteClientId);

        // Create a new peer connection using the offer from the given client
        const peerConnection = new RTCPeerConnection(configuration);
        master.peerConnectionByClientId[remoteClientId] = peerConnection;

        master.dataChannelByClientId[remoteClientId] = peerConnection.createDataChannel('kvsDataChannel');
        peerConnection.ondatachannel = event => {
            event.channel.onmessage = onRemoteDataMessage;
        };

        // Poll for connection stats
        master.peerConnectionStatsInterval = setInterval(() => peerConnection.getStats().then(onStatsReport), 1000);

        // Send any ICE candidates to the other peer
        peerConnection.addEventListener('icecandidate', ({ candidate }) => {
            if (candidate) {
                console.log('[MASTER] Generated ICE candidate for client: ' + remoteClientId);

                // When trickle ICE is enabled, send the ICE candidates as they are generated.
                console.log('[MASTER] Sending ICE candidate to client: ' + remoteClientId);
                master.signalingClient.sendIceCandidate(candidate, remoteClientId);
            } else {
                console.log('[MASTER] All ICE candidates have been generated for client: ' + remoteClientId);
            }
        });

        // As remote tracks are received, add them to the remote view
        // Currently, only audio
        peerConnection.addEventListener('track', event => {
            console.log('[MASTER] Received remote track from client: ' + remoteClientId);
            console.log(event);
            master.remoteStreams.push(event.streams[0]);
            if (master.remoteAudio) {
                if (!(master.remoteAudio.srcObject instanceof MediaStream)) {
                    master.remoteAudio.srcObject = event.streams[0];
                    master.remoteAudio.muted = true;
                    master.remoteAudio.play();
                    master.remoteAudio.muted = false;
                } else {
                    event.streams[0].getAudioTracks().forEach(track => {
                        master.remoteAudio.srcObject.addTrack(track);
                    })
                }
            }
        });

        // respond to connection changes from peers
        peerConnection.addEventListener("connectionstatechange", () => {
            switch (peerConnection.connectionState) {
                case "connected":
                    console.log(`[MASTER] Peer connection from ${remoteClientId} is now connected.`);
                    break;
                case "disconnected":
                    console.log(`[MASTER] Peer connection from ${remoteClientId} has been disconnected.`);
                    break;
                case "closed":
                    console.log(`[MASTER] Peer connection from ${remoteClientId} closed. Removing feed.`);
                    break;
                default:
                    /*
                        TODO:
                            Add a loader to the video element in this state.
                    */
                    console.log(`[MASTER] Peer connection from ${remoteClientId} has an unknown connection state.`);
                    break;
            }
        }, false);

        // If there's no video/audio, master.localStream will be null. So, we should skip adding the tracks from it.
        if (master.localStream != null) {
            console.log("[MASTER] Adding tracks.")
            master.localStream.getTracks().forEach(track => peerConnection.addTrack(track, master.localStream));
        }
        await peerConnection.setRemoteDescription(offer);

        // Create an SDP answer to send back to the client
        console.log('[MASTER] Creating SDP answer for client: ' + remoteClientId);
        peerConnection.addTransceiver('video', {direction: "sendonly"});
        peerConnection.addTransceiver('audio', {direction: "sendrecv"});
        await peerConnection.setLocalDescription(await peerConnection.createAnswer());

        // When trickle ICE is enabled, send the answer now and then send ICE candidates as they are generated,
        // otherwise wait on the ICE candidates.
        console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId);
        master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
        console.log('[MASTER] Generating ICE candidates for client: ' + remoteClientId);
    });

    master.signalingClient.on('iceCandidate', (candidate, remoteClientId) => {
        console.log('[MASTER] Received ICE candidate from client: ' + remoteClientId);

        // Add the ICE candidate received from the client to the peer connection
        const peerConnection = master.peerConnectionByClientId[remoteClientId];
        peerConnection.addIceCandidate(candidate);
    });

    master.signalingClient.on('close', () => {
        console.log('[MASTER] Disconnected from signaling channel');
    });

    master.signalingClient.on('error', (e) => {
        console.error(`[MASTER] Signaling client error: \n${e}`);
    });
    
    try {
        console.log('[MASTER] Starting master connection');
        master.signalingClient.open();
    } catch (error) {
        console.log(error);
    }
}

export async function stopMaster() {
    console.log('[MASTER] Stopping master connection');

    if (master.signalingClient) {
        master.signalingClient.close();
        master.signalingClient = null;
    }

    Object.keys(master.peerConnectionByClientId).forEach(clientId => {
        master.peerConnectionByClientId[clientId].close();
    });
    master.peerConnectionByClientId = {};

    if (master.localStream) {
        master.localStream.getTracks().forEach(track => track.stop());
        master.localStream = null;
    }

    if (master.remoteStreams) {
        master.remoteStreams.forEach(remoteStream => {
            if (remoteStream instanceof MediaStream) {
                remoteStream.getTracks().forEach(track => track.stop());
            }
        });
        master.remoteStreams = [];
    }

    if (master.remoteAudio) {
        master.remoteAudio = null;
    }

    clearInterval(master.peerConnectionStatsInterval);
    master.peerConnectionStatsInterval = null;

    if (master.localView) {
        master.localView.srcObject = null;
        master.localView = null;
    }

    if (master.dataChannelByClientId) {
        master.dataChannelByClientId = {};
    }

    if (master.mediaRecorderStream) {
        await master.mediaRecorderStream.getTracks().forEach(track => track.stop());
        master.mediaRecorderStream = null;
    }

    if (master.mediaRecorder) {
        if (master.mediaRecorder.state !== "inactive"){
            master.mediaRecorder.stop();
        }
        master.mediaRecorder = null;
    }

    await deleteSignalingChannel();

    allMediaStreams.forEach((stream) => {
        stream.getTracks().forEach(track => track.stop());
    });
    allMediaStreams = [];

    sessionStorage.removeItem(`MASTER_${SessionStorageItems.ChannelARN}`);

    console.log(master);
}

export function sendMasterMessage(message) {
    Object.keys(master.dataChannelByClientId).forEach(clientId => {
        try {
            master.dataChannelByClientId[clientId].send(message);
        } catch (e) {
            console.error('[MASTER] Send DataChannel: ', e.toString());
        }
    });
}

export function reloadLocalView() {
    try {
        master.localView.srcObject = master.localStream;
    } catch (error) {
        console.log(`[MASTER] Error reloading local view: ${error}`);
    }
}

export function setRemoteAudio(element) {
    if (!(element instanceof HTMLAudioElement)) {
        throw TypeError("Element must be of type HTMLAudioElement")
    }
    master.remoteAudio = element;
    master.remoteStreams.forEach(track => {
        master.remoteAudio.srcObject.addTrack(track)
    });
}

export function getMediaRecorder() {
    return master.mediaRecorder;
}

export async function setUserMedia(constraints) {
    /**
     * Gets the tracks from the selected source in the selected resolution.
     * @param {object} constraints Object defining getUserMedia constraints
     * @returns {boolean} success Indicates whether the method succeeded
     *
     * @todo:
     *  Update stream when source changes during recording.
     */
    if (master.localView == null) {
        return false
    }
    try {
        master.localStream = await navigator.mediaDevices.getUserMedia(constraints);
        master.localView.srcObject = master.localStream;
        allMediaStreams.push(master.localStream);
    } catch (e) {
        console.error('[MASTER] Could not find webcam');
        return false
    }
    if (master.localStream) {
        if (master.mediaRecorder == null) {
            master.mediaRecorderStream = master.localStream.clone();
            let mimeTypeOpt = undefined;
            if (MediaRecorder.isTypeSupported('video/mp4')) {
                mimeTypeOpt = 'video/mp4';
            } else if (MediaRecorder.isTypeSupported('video/webm')) {
                mimeTypeOpt = 'video/webm';
            } else if (MediaRecorder.isTypeSupported('video/ogg')) {
                mimeTypeOpt = 'video/ogg';
            }
            master.mediaRecorder = new MediaRecorder(master.mediaRecorderStream, {
                mimeType: mimeTypeOpt
            });
        }
        console.log("[MASTER] Reloading tracks.");
        const [track] = master.localStream.getTracks();
        for (const pc of Object.values(master.peerConnectionByClientId)) {
            pc.getSenders().forEach((sender) => {
                if ((sender.track) && (sender.track.kind === track.kind)) {
                    sender.replaceTrack(track);
                    console.log('Track replaced')
                } else {
                    try {
                        if (pc.connectionState === 'open') {
                            pc.removeTrack(sender);
                        }
                        pc.addTrack(track);
                        console.log('Track added');
                    } catch (e) {
                        if (e.name !== 'InvalidAccessError') {
                            throw e;
                        }
                    }
                }
            });
        }
    }

    return true
}