<template>
  <v-container>
    <v-row>
      <StreamPlaybackCanvas :source="videoSource" :title="userStore.email"/>
    </v-row>
    <v-row>
      <StreamDownloadButton :stream="stream" />
    </v-row>
  </v-container>
</template>

<script>
import StreamDownloadButton from "src/components/streams/StreamDownloadButton.vue";
import { axiosApi } from "src/modules/axios/axiosApi";
import StreamPlaybackCanvas from "./StreamPlaybackCanvas.vue";
import {mapState} from "vuex";

export default {
  name: "StreamRecording",
  components: {StreamPlaybackCanvas, StreamDownloadButton },
  props: {
    stream: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      videoSource: null,
    };
  },
  async created() {
    const resp = axiosApi.get(`api/streams/${this.stream["uuid"]}/recording/`);
    this.videoSource = (await resp).data["recording"];
  },
  computed: {
    ...mapState(['userStore'])
  }
};
</script>

<style scoped>
</style>