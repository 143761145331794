import axios from 'axios'
//axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('accessToken')}`
import { APIBaseURL } from "src/modules/globalConstants.mjs";

const axiosApi = axios.create({
    baseURL: APIBaseURL,
    timeout: 10000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

const axiosUnsafeApi = axios.create({
    baseURL: APIBaseURL,
    timeout: 10000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

const axiosCountriesAPI = axios.create({
    baseURL: ' https://api.countrystatecity.in/v1/',
    timeout: 10000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSCAPI-KEY': 'UHQ4T0l6elF5Q0tzelpaSXNGUGZ4Z1d2YWJzQlU3OHhSYTJtTWdpVg=='
    }
});

export { axiosApi, axiosUnsafeApi, axiosCountriesAPI }