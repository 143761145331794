<template>
  <ContentDiv>
    <v-row class="justify-center">
      <v-col cols="6">
        <DialogTeamManagement @createdTeam="getTeams()" />
        <!--        <v-btn class="float-end" color="primary">Add team</v-btn>-->
      </v-col>
    </v-row>
    <!--    <div class="d-flex flex-wrap align-baseline">-->
    <div class="pa-4">
      <template v-for="team in teams">
        <TeamCard
          :key="team.id"
          :team_id="Number(team.id)"
          @updatedTeams="getTeams()"
        />
      </template>
    </div>
  </ContentDiv>
</template>

<script>
import ContentDiv from "@/components/ContentDiv.vue";
import TeamCard from "@/components/teams/TeamCard.vue";
import { mapState } from "vuex";
import DialogTeamManagement from "@/components/dialogs/DialogTeamManagement.vue";

export default {
  name: "TeamManagement",
  components: { DialogTeamManagement, ContentDiv, TeamCard },
  data() {
    return {
      teams: [],
    };
  },
  watch: {
    organisationID: {
      handler() {
        this.getTeams();
      },
    },
  },
  methods: {
    async getTeams() {
      await this.$store.dispatch("getAllTeams");
      this.teams = this.teamStore.teams;
    },
  },
  created() {
    this.getTeams();
  },
  computed: {
    ...mapState(["teamStore", "organisationStore"]),
    organisationID() {
      return this.organisationStore.companyID;
    },
  },
};
</script>

<style scoped lang="sass">
</style>