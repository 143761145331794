<template>
  <ContentDiv>
    <PageTitle>Settings</PageTitle>
    <p>
      Configure various settings relating to your Organisation(s) and Team Members
    </p>
    <PageTitleDivider/>
    <template v-if="
          organisationStore.companyID === 'undefined' ||
          organisationStore.companyID == null"
    >
      <PageSubtitle class="my-5">Create an organisation</PageSubtitle>
      <OrganisationGeneral class="my-10"/>
    </template>
    <OrganisationSettings v-else class="my-10"/>
  </ContentDiv>
</template>

<script>
import ContentDiv from "@/components/ContentDiv.vue";
import OrganisationGeneral from "@/components/organistions/OrganisationGeneral.vue";
import PageTitle from "@/components/PageTitle.vue";
import PageTitleDivider from "@/components/dividers/PageTitleDivider.vue";
import {mapState} from "vuex";
import PageSubtitle from "@/components/PageSubtitle.vue";
import OrganisationSettings from "@/components/organistions/OrganisationSettings.vue";

export default {
  name: "ViewSettingsOrganisation",
  components: {
    OrganisationSettings,
    PageSubtitle,
    ContentDiv,
    OrganisationGeneral,
    PageTitle,
    PageTitleDivider,
  },
  methods: {},
  async created() {
    this.$store.dispatch("getAllPermissions");
  },
  computed: {
    ...mapState(["organisationStore"]),
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  letter-spacing: 0;
  text-transform: none;
  font-weight: 400;
}

.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.active-border {
  border: 1px solid grey;
}
</style>
