<template>
  <v-list dense nav class="text-left">
    <v-list-item-group
      color="navigationDrawerLinks"
      active-class="primary--text"
    >
      <v-list-item v-for="item in items" :key="item.title" link :to="item.path" :disabled="item.disabled">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "LinksNavigation",
  data: () => ({
    items: [
      {
        title: "Manage your Profile",
        icon: "mdi-account-edit",
        path: "/settings-user",
        disabled: false
      },
      {
        title: "Manage your Billing",
        icon: "mdi-credit-card-multiple",
        path: "/billing",
        disabled: true
      },
      {
        title: "Manage your Organisations",
        icon: "mdi-office-building",
        path: "/settings-organisation",
        disabled: false
      },
    ],
  }),
};
</script>
