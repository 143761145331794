<template>
  <v-btn icon>
    <v-badge
      :value="messages.length"
      :content="messages.length"
      overlap
      color="red"
    >
      <v-icon>mdi-bell</v-icon>
    </v-badge>
  </v-btn>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "IconNotificationBell",
  computed: {
    ...mapState({
      messages: (state) => state.websocketStore.messages,
    }),
  },
};
</script>





