import adapter from 'webrtc-adapter';

console.log(adapter.browserDetails.browser)

import {
    SignalingClient,
    Role
} from "amazon-kinesis-video-streams-webrtc";
import { 
    ResourceInUseException 
} from "@aws-sdk/client-kinesis-video";

import {
    AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY,
    AWS_REGION,
    clientID, AWS_SESSION_TOKEN
} from "../../getAccessKeys.mjs";
import {
    createSignalingChannel,
    deleteSignalingChannel,
    describeSignalingChannel,
    getChanelEndpoints,
    getSystemClockOffset
} from "../signalingChannel.mjs";
import { 
    getICEServerConfig
} from "../iceServer.mjs";
import { 
    SessionStorageItems
 } from "../../globalConstants.mjs";

const viewer = {
    signalingClient: null,
    dataChannelByClientId: {},
    localStream: new MediaStream(),
    remoteStreams: [],
    remoteView: null, // make this an array to allow more remote views
    peerConnectionStatsInterval: null
};

let allMediaStreams = [];

export async function startViewer(remoteView, onStatsReport, onRemoteDataMessage, channelName) {
    viewer.remoteView = remoteView;

    // Get signaling channel ARN
    let channelARN;
    try {
        const describeSignalingChannelResponse = await describeSignalingChannel(channelName);
        channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
        console.log('[VIEWER] Channel ARN: ', channelARN);
    } catch (error) {
        console.log("Attempting to create signalling channel. \nDescription failed: \n", error)
        channelARN = await createSignalingChannel(channelName);
    }
    sessionStorage.setItem(`VIEWER_${SessionStorageItems.ChannelARN}`, channelARN);

    // Get signaling channel endpoints
    const getSignalingChannelEndpointResponse = await getChanelEndpoints(channelARN, Role.VIEWER);
    if (getSignalingChannelEndpointResponse == null) {
        await deleteSignalingChannel();
        sessionStorage.removeItem(`VIEWER_${SessionStorageItems.ChannelARN}`);
        startViewer(remoteView, onStatsReport, onRemoteDataMessage).then();
        return;
    }
    const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
        return endpoints;
    }, {});
    console.log('[VIEWER] Endpoints: ', endpointsByProtocol);

    // Get ICE server configuration
    const iceServers = [];
    try {
        const getIceServerConfigResponse = await getICEServerConfig(endpointsByProtocol.HTTPS);
        iceServers.push({ urls: `stun:stun.kinesisvideo.${AWS_REGION}.amazonaws.com:443` });
        getIceServerConfigResponse.IceServerList.forEach(iceServer =>
            iceServers.push({
                urls: iceServer.Uris,
                username: iceServer.Username,
                credential: iceServer.Password,
            }),
        );
        console.log('[VIEWER] ICE servers: ', iceServers);
    } catch (e) {
        if (e instanceof ResourceInUseException) {
            await deleteSignalingChannel();
            sessionStorage.removeItem(`VIEWER_${SessionStorageItems.ChannelARN}`);
            startViewer(remoteView, onStatsReport, onRemoteDataMessage).then();
            return;
        } else {
            throw e;
        }
    }

    // Create WebRTC Signaling Client
    viewer.signalingClient = new SignalingClient({
        channelARN,
        channelEndpoint: endpointsByProtocol.WSS,
        clientId: clientID,
        role: Role.VIEWER,
        region: AWS_REGION,
        credentials: {
            accessKeyId: AWS_ACCESS_KEY_ID,
            secretAccessKey: AWS_SECRET_ACCESS_KEY,
            sessionToken: AWS_SESSION_TOKEN
        },
        systemClockOffset: getSystemClockOffset(),
    });

    const configuration = {
        iceServers,
        iceTransportPolicy: 'relay', //'all',
        sdpSemantics:'unified-plan'
    };
    viewer.peerConnection = new RTCPeerConnection(configuration);
    viewer.dataChannel = viewer.peerConnection.createDataChannel('kvsDataChannel');
    viewer.peerConnection.ondatachannel = event => {
        event.channel.onmessage = onRemoteDataMessage;
    };

    // Poll for connection stats
    viewer.peerConnectionStatsInterval = setInterval(() => viewer.peerConnection.getStats().then(onStatsReport), 1000);

    viewer.signalingClient.on('open', async () => {
        console.log('[VIEWER] Connected to signaling service');

        // Create an SDP offer to send to the viewer
        console.log('[VIEWER] Creating SDP offer');
        viewer.localStream = new MediaStream()
        viewer.peerConnection.addTransceiver('video', {direction: "recvonly"});
        viewer.peerConnection.addTransceiver('audio', {
            direction: "sendrecv",
            streams: [viewer.localStream]
        });
        await viewer.peerConnection.setLocalDescription(await viewer.peerConnection.createOffer());

        console.log('[VIEWER] Sending SDP offer');
        viewer.signalingClient.sendSdpOffer(viewer.peerConnection.localDescription);
        console.log('[VIEWER] Generating ICE candidates');
    });

    viewer.signalingClient.on('sdpAnswer', async answer => {
        // Add the SDP answer to the peer connection
        console.log('[VIEWER] Received SDP answer');
        if (viewer.peerConnection.connectionState === 'stable') {
            console.log("This connection is already established.");
            return
        }
        await viewer.peerConnection.setRemoteDescription(answer);
    });

    viewer.signalingClient.on('iceCandidate', candidate => {
        // Add the ICE candidate received from the VIEWER to the peer connection
        console.log('[VIEWER] Received ICE candidate');
        viewer.peerConnection.addIceCandidate(candidate);
    });

    viewer.signalingClient.on('close', () => {
        console.log('[VIEWER] Disconnected from signaling channel');
    });

    viewer.signalingClient.on('error', error => {
        console.error('[VIEWER] Signaling client error: ', error);
    });

    // Send any ICE candidates to the other peer
    viewer.peerConnection.addEventListener('icecandidate', ({ candidate }) => {
        if (candidate) {
            console.log('[VIEWER] Generated ICE candidate');

            // When trickle ICE is enabled, send the ICE candidates as they are generated.
            console.log('[VIEWER] Sending ICE candidate');
            viewer.signalingClient.sendIceCandidate(candidate);
        } else {
            console.log('[VIEWER] All ICE candidates have been generated');
        }
    }, false);

    // As remote tracks are received, add them to the remote view
    viewer.peerConnection.addEventListener('track', event => {
        console.log('[VIEWER] Received remote track');
        console.log(event);
        viewer.remoteStream = event.streams[0];
        viewer.remoteView.srcObject = viewer.remoteStream;
    });

    viewer.peerConnection.addEventListener('connectionstatechange', async (event) => {
        switch(viewer.peerConnection.connectionState) {
            case "new":
                console.log("[VIEWER] New peer connection");
                break;
            case "connected":
                console.log("[VIEWER] Peer connection connected");
                break;
            case "disconnected":
                console.log(`[VIEWER] Peer connection disconnected with event: \n${event.toString()}`);
                break;
            case "closed":
                console.log("[VIEWER] Peer connection closed");
                break;
            case "failed":
                console.log("[VIEWER] Peer connection failed. Reattempting...");
                for (let i = 0 ; i < 5 ; i++) {
                    try {
                        if (viewer.remoteStream) {
                            viewer.remoteStream.getTracks().forEach(track => track.stop());
                            viewer.remoteStream = null;
                            viewer.remoteView.srcObject = null;
                        }
                        if (viewer.peerConnectionStatsInterval) {
                            clearInterval(viewer.peerConnectionStatsInterval);
                            viewer.peerConnectionStatsInterval = null;
                        }
                        console.log(`Attempt ${i}...`);
                        sessionStorage.removeItem(`VIEWER_${SessionStorageItems.ChannelARN}`);
                        await startViewer(remoteView, onStatsReport, onRemoteDataMessage);
                        return;
                    } catch(error) {
                        console.log(`Attempt ${i} failed with error: \n${error}`);
                    }
                }
                break;
            default:
                console.log("[VIEWER] Peer connection has an unknown state.");
                break;
        }
    });

    console.log('[VIEWER] Starting viewer connection');
    viewer.signalingClient.open();
}

export async function setUserMedia(constraints) {
    if (!(constraints.audio || constraints.video)) {
        viewer.localStream = null;
    } else {
        try {
            viewer.localStream = await navigator.mediaDevices.getUserMedia(constraints);
            allMediaStreams.push(viewer.localStream);
        } catch (e) {
            console.error('[VIEWER] Could not find user media');
            return false
        }
    }

    if (viewer.localStream) {
        console.log("[VIEWER] Reloading tracks.");
        console.log(viewer.localStream);
        const [track] = viewer.localStream.getTracks();
        viewer.peerConnection.getSenders().forEach((sender) => {
            if ((sender.track) && (sender.track.kind === track.kind)) {
                sender.replaceTrack(track);
                console.log('Track replaced')
            } else {
                try {
                    if (viewer.peerConnection.connectionState === 'open') {
                        viewer.peerConnection.removeTrack(sender);
                    }
                    viewer.peerConnection.addTrack(track, viewer.localStream);
                    renegotiate();
                } catch (e) {
                    if (e.name !== 'InvalidAccessError') {
                        throw e;
                    }
                }
            }
        });
    } else {
        viewer.peerConnection.getSenders().forEach((sender) => {
            viewer.peerConnection.removeTrack(sender);
            renegotiate();
        });
    }

    return true
}

async function renegotiate() {
    console.log("[Viewer] Renegotiating");
    await viewer.peerConnection.setLocalDescription(await viewer.peerConnection.createOffer());
    viewer.signalingClient.sendSdpOffer(viewer.peerConnection.localDescription);
}

export async function stopViewer() {
    console.log('[VIEWER] Stopping viewer connection');

    if (viewer.signalingClient) {
        viewer.signalingClient.close();
        viewer.signalingClient = null;
    }

    if (viewer.localStream) {
        viewer.localStream.getTracks().forEach(track => track.stop());
        viewer.localStream = null;
    }

    if (viewer.remoteStreams) {
        viewer.remoteStreams.forEach(remoteStream => remoteStream.getTracks().forEach(track => track.stop()));
        viewer.remoteStreams = [];
    }

    if (allMediaStreams) {
        allMediaStreams.forEach(stream => stream.getTracks().forEach(track => track.stop()));
        allMediaStreams = [];
    }

    /*
    This is a workaround, but at least it works.
    */
    for (let index = 0; index <= viewer.peerConnectionStatsInterval*2; index++) {
        clearInterval(index);
    }
    viewer.peerConnectionStatsInterval = null;

    if (viewer.remoteView) {
        viewer.remoteView.srcObject = null;
    }

    if (viewer.dataChannelByClientId) {
        viewer.dataChannelByClientId = {};
    }

    sessionStorage.removeItem(`VIEWER_${SessionStorageItems.ChannelARN}`);
}

export function sendViewerMessage(message) {
    Object.keys(viewer.dataChannelByClientId).forEach(clientId => {
        try {
            viewer.dataChannelByClientId[clientId].send(message);
        } catch (e) {
            console.error('[VIEWER] Send DataChannel: ', e.toString());
        }
    });
}

export function reloadRemoteView() {
    try {
        viewer.remoteView.srcObject = viewer.remoteStreams[0];
    } catch (error) {
        console.log(`[VIEWER] Error reloading remote view: ${error}`);
    }
}