<template>
  <v-autocomplete
      v-model="selected"
      @change="selectedCity"
      :items="cities"
      label="City"
      dense outlined
      :disabled="(state == null) || (state === '')" :loading="isLoading"
  />
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AutocompleteCities",
  props: ['modelValue', 'country', 'state'],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  data() {
    return {
      selected: null,
      cities: [],
      isLoading: false
    }
  },
  methods: {
    async getAllCities() {
      if (!((this.country)&&(this.state))) return;
      this.isLoading = true;
      this.selected = null;
      let cities = this.$store.getters.getStateCities(this.country, this.state) || [];
      if (cities.length <= 0) {
        await this.$store.dispatch('fetchStateCities', {
          country: this.country,
          countryState: this.state
        });
        cities = this.$store.getters.getStateCities(this.country, this.state);
      }
      this.cities = cities;
      this.isLoading = false;
      this.selected = this.userStore.city;
    },
    selectedCity() {
      this.$emit('update:modelValue', this.selected);
    }
  },
  watch: {
    state: {
      handler(value) {
        if (value) {
          this.getAllCities();
        }
      }
    },
    country: {
      handler(value) {
        if (value) {
          this.getAllCities();
        }
      }
    },
    countryStateStoreObject: {
      async handler(value) {
        if (value) {
          await this.getAllCities();
        }
      }
    }
  },
  created() {
    this.getAllCities();
    this.selected = this.modelValue;
  },
  computed:{
    ...mapState(['addressStore', 'userStore']),
    countryStateStoreObject() {
      try {
        return this.addressStore.countries[this.country].states[this.state];
      } catch (e) {
        if (e instanceof TypeError) {
          return null;
        }
        throw e;
      }
    }
  }
}
</script>

<style scoped>

</style>