<template>
  <v-card v-if="show" :class="'video-overlay ma-0 rounded-0 ' + ((show) ? 'expanded' : 'collapsed')">
    <v-divider />
    <v-card-title>
      {{ activeStreamStore.name }}
      <v-spacer />
      <v-btn icon @click="show = !show">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-card-subtitle class="text-start ma-1 pa-0">Viewers</v-card-subtitle>
      <StreamViewers />
    </v-card-text>
    <v-divider />
    <v-card-text>
      <StreamAddIndividualsCard class="mx-auto pa-2 d-block" without-viewers />
      <StreamTeamSelection class="mx-auto pa-2 d-block" />
    </v-card-text>
    <v-divider />
    <v-card-text
      v-if="
        activeStreamStore.unknownViewers &&
        activeStreamStore.unknownViewers.length > 0
      "
    >
      <v-card-subtitle class="text-start ma-1 pa-0"
        >Unknown viewers</v-card-subtitle
      >
      <v-list dense class="transparent">
        <v-list-item
          v-for="viewer in activeStreamStore.unknownViewers"
          :key="viewer"
        >
          {{ viewer }}
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider />
    <v-card-text
      v-if="
        activeStreamStore.invalidViewers &&
        activeStreamStore.invalidViewers.length > 0
      "
    >
      <v-card-subtitle class="text-start ma-1 pa-0"
        >Invalid viewers</v-card-subtitle
      >
      <v-list dense class="transparent">
        <v-list-item
          v-for="viewer in activeStreamStore.invalidViewers"
          :key="viewer"
        >
          {{ viewer }}
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>

  <v-btn v-else icon @click="show = !show" class="rounded-0">
    <v-icon>mdi-menu</v-icon>
  </v-btn>
</template>

<script>
import StreamViewers from "src/components/streams/StreamViewers.vue";
import { mapState } from "vuex";
import StreamAddIndividualsCard from "src/components/streams/StreamAddIndividualsCard.vue";
import StreamTeamSelection from "src/components/streams/StreamTeamSelection.vue";

export default {
  name: "DrawerMasterVideo",
  components: { StreamTeamSelection, StreamAddIndividualsCard, StreamViewers },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    ...mapState(["activeStreamStore"]),
  },
};
</script>

<style scoped lang="scss">
.video-overlay {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #fff;
  background-color: rgba(50, 50, 50, 0.8);
  max-width: 30%;

  &.collapsed {
    width: fit-content;
    height: fit-content;
  }
  &.expanded {
    width: 30%;
    min-height: 100%;
  }
}
</style>