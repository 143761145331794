import {undefinedOrValue} from "@/store/helpers";

export default {
    username: undefinedOrValue(sessionStorage.getItem('username')),
    email: undefinedOrValue(sessionStorage.getItem('email')),
    firstName: undefinedOrValue(sessionStorage.getItem('firstName')),
    lastName: undefinedOrValue(sessionStorage.getItem('lastName')),
    uuid: undefinedOrValue(sessionStorage.getItem('uuid')),
    dob: undefinedOrValue(sessionStorage.getItem('dob')),
    country: undefinedOrValue(sessionStorage.getItem('country')),
    state: undefinedOrValue(sessionStorage.getItem('state')),
    city: undefinedOrValue(sessionStorage.getItem('city')),
    zip: undefinedOrValue(sessionStorage.getItem('zip')),
    address: undefinedOrValue(sessionStorage.getItem('address')),
    darkMode: undefinedOrValue(sessionStorage.getItem('darkMode')),
    emailVerified: undefinedOrValue(sessionStorage.getItem('emailVerified'))
}