<template>
  <ContentDiv>
    <PageTitle>Join Live Stream</PageTitle>
    <p>To join a live video stream simply choose a stream from the dropdown once it has been shared with you.</p>
    <PageTitleDivider/>
    <VideoFeedDiv>Feed</VideoFeedDiv>
  </ContentDiv>
</template>

<script>
import ContentDiv from "../components/ContentDiv.vue";
import PageTitle from "../components/PageTitle.vue";
import VideoFeedDiv from "../components/Viewer/Viewer.vue";
import PageTitleDivider from "../components/dividers/PageTitleDivider.vue";

export default {
  name: "JoinStreamView",
  components: {
    ContentDiv,
    PageTitle,
    VideoFeedDiv,
    PageTitleDivider
  },
};

</script>

<style scoped lang="scss">
</style>