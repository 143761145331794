<template>
  <h1 class="text--center page-title"><slot></slot></h1>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.page-title {
  font-size: 1.35em;
}
</style>