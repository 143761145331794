import {
    KinesisVideoClient,
    AccessDeniedException
} from "@aws-sdk/client-kinesis-video";

import {
    AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY,
    AWS_REGION,
    checkAccessTokens, AWS_SESSION_TOKEN
} from "../getAccessKeys.mjs";

let kinesisVideoClient = null;

function createClient() {
    try {
        kinesisVideoClient = new KinesisVideoClient({
            region: AWS_REGION,
            credentials: {
                accessKeyId: AWS_ACCESS_KEY_ID,
                secretAccessKey: AWS_SECRET_ACCESS_KEY,
                sessionToken: AWS_SESSION_TOKEN
            },
        });
    } catch (error) {
        if (error instanceof AccessDeniedException) {
            checkAccessTokens();
        } else {
            throw error;
        }
    }
}

export function getClient() {
    if (kinesisVideoClient == null) {
        createClient();
    }
    return kinesisVideoClient;
}