<template>
  <v-list dense nav class="text-left">
    <v-list-item-group
      color="navigationDrawerLinks"
      active-class="secondary--text text--accent-4"
    >
      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "LinksLogout",

  methods: {
    logout() {
      this.$store.dispatch("userLogout");
    },
  },
};
</script>
