<template>
  <v-autocomplete
      v-model="selected"
      @change="selectedCountry"
      :items="countries"
      label="Country"
      item-text="name" item-value="name"
      :loading="isLoading"
      dense outlined
  >
    <template v-slot:selection="{item}">
      <v-icon left>
        {{`em-flag-${item.iso2.toLowerCase()}`}}
      </v-icon>
      {{ item.name }}
    </template>

    <template v-slot:item="{item}" >
      <v-icon left>
        {{`em-flag-${item.iso2.toLowerCase()}`}}
      </v-icon>
      {{item.name}}
    </template>
  </v-autocomplete>
</template>

<script>

export default {
  name: "AutocompleteCountry",
  props: ['modelValue', 'default'],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  data() {
    return {
      selected: null,
      countries: [],
      isLoading: false
    }
  },
  methods: {
    async getAllCountries() {
      this.isLoading = true;
      let countries = this.$store.getters.getAllCountriesWithISO2();
      if (countries.length < 193) {
        await this.$store.dispatch('fetchAllCountries');
        countries = this.$store.getters.getAllCountriesWithISO2();
      }
      this.countries = countries;
      this.isLoading = false;
    },
    selectedCountry() {
      this.$emit('update:modelValue', this.selected);
    }
  },
  async created() {
    await this.getAllCountries();
    if (this.modelValue) {
      this.selected = this.modelValue;
    }
    else {
      this.selected = this.default;
    }
    this.$emit('update:modelValue', this.selected);
  }
}
</script>

<style scoped>

</style>