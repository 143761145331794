<template>
  <v-navigation-drawer
    app
    :mini-variant.sync="globalComponentStore.menuMiniState"
  >
    <slot name="start"></slot>
    <v-divider></v-divider>
    <slot name="center"></slot>
    <v-divider class="secondary"></v-divider>
    <slot name="end"></slot>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DrawerNavigationDesktop",
  computed: {
    ...mapState(["globalComponentStore"]),
  },
};
</script>

<style scoped lang="scss">
#logo-desktop {
  height: 56px;
  padding: 0px;
  justify-content: center;
}
</style>