export const resolutions = [
	{
		width: 640,
		height: 480,
		to_string: "Standard Definition (480p)",
	},
	{
		width: 1280,
		height: 720,
		to_string: "High Definition (720p)",
	},
	{
		width: 1920,
		height: 1080,
		to_string: "Full High Definition (1080p)",
	},
	{
		width: 2560,
		height: 1440,
		to_string: "Quad High Definition (1440p)",
	},
	{
		width: 3840,
		height: 2160,
		to_string: "Ultra High Definition (4K/2160p)",
	},
];

export const frameRates = [15, 24, 30];