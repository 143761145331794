<template>
  <div class="main">
    <div class="flex">
      <!-- Map Display here -->
      <div class="map-holder">
        <div id="map"></div>
      </div>
    </div>
  </div>
</template>
<script>

//import axios from "axios";
import mapboxgl from "mapbox-gl";
//import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

export default {
  data() {
    return {
      loading: false,
      location: "Cape Town",
      access_token: "pk.eyJ1Ijoic3RldmVua3JvZyIsImEiOiJja3lseDBhbHkwdWZ6MnZ1ZjJqeHdhdGJ2In0.T_jEKokb37vY0Z3yUGg0_Q",
      center: [18.4289, -33.9351],
      map: {},
    };
  },

  mounted() {
    this.createMap()
  },

  methods: {
    async createMap() {
      try {
        mapboxgl.accessToken = this.access_token;
        let dark = this.$vuetify.theme.dark
        let secondary = this.$vuetify.theme.themes.dark.secondary

        this.map = new mapboxgl.Map({
          container: "map",
          style: dark ? "mapbox://styles/stevenkrog/cl90pes1i001k14oacjsv0su5" : "mapbox://styles/mapbox/light-v10",
          center: this.center,
          zoom: 11,
        });

        // disable map rotation using right click + drag
        this.map.dragRotate.disable();

        // disable map rotation using touch rotation gesture
        this.map.touchZoomRotate.disableRotation();

        this.map.on("load", async () => {
          this.map.setPaintProperty('aeroway', 'line-color', secondary);

          //Draws a line
          /**this.map.addSource("route", {
            "type": "geojson",
            "data": {
              'type': 'Feature',
              'properties': {},
              'geometry': {
                'type': 'LineString',
                'coordinates': [
                  [18.4289, -33.9351],
                  [18.4389, -33.9451],
                  [18.50, -33.9451],
                  [18.4389, -33.9451],
                ]
              }
            }
          })**/

          this.map.addLayer({
            'id': 'route',
            'type': 'line',
            'source': 'route',
            'layout': {
              'line-join': 'round',
              'line-cap': 'round'
            },
            'paint': {
              'line-dasharray': [1, 3],
              'line-color': secondary,
              'line-width': 2
            }
          });
        })

      } catch (err) {
        console.log("map error", err);
      }
    },
  },
}
</script>

<style scoped>
#map {
  height: 100vh;
}

.main {
  width: 100%;
}
</style>
