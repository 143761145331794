export default {
    computed: {
        contentColor() {
            return this.calculateContentColor(this.backgroundColor);
        },
    },
    methods: {
        calculateContentColor(backgroundColor) {
            if (!backgroundColor) return null;

            const bgColor = parseInt(backgroundColor.substring(1), 16);
            const r = (bgColor >> 16) & 255;
            const g = (bgColor >> 8) & 255;
            const b = bgColor & 255;
            const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

            return luminance > 0.5 ? "#2a2a2a" : "#ffffff";
        },
    },
};
