<template>
  <v-btn @click="sendMessage('Hi')">Message</v-btn>
</template>
  
<script>
export default {
  name: "WebSocketButton",
  methods: {
    sendMessage(message) {
      const payload = {
        message: message,
      };
      this.$socket.send(JSON.stringify(payload));
    },
  },
};
</script>