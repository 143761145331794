var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{staticStyle:{"border-radius":"26px !important"},attrs:{"bottom":"","min-width":"280px","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-avatar',{style:({
            backgroundColor: _vm.backgroundColor,
            color: _vm.contentColor,
          }),attrs:{"size":"36"}},[_c('span',[_vm._v(_vm._s(_vm.userStore.email.charAt(0)))])])],1)]}}])},[_c('v-list',{staticClass:"pa-0",attrs:{"id":"drawer-profile","dense":"","nav":""}},[_c('v-list-item-content',[_c('div',{staticClass:"mx-auto text-center"},[_c('v-avatar',{staticClass:"ma-3",style:({
              backgroundColor: _vm.backgroundColor,
              color: _vm.contentColor,
            }),attrs:{"size":"36"}},[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.userStore ? _vm.userStore.email.charAt(0) : ""))])]),_c('h3',[_vm._v(_vm._s(_vm.userStore.firstName ? " " + _vm.userStore.firstName : ""))]),_c('p',{staticClass:"text-caption ma-0"},[_vm._v(" "+_vm._s(_vm.userStore ? _vm.userStore.email : "")+" ")]),_vm._t("start"),_c('v-divider'),_vm._t("center"),_c('v-divider',{staticClass:"secondary"}),_vm._t("end")],2)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }