<template>
  <div class="w-100">
    <v-row>
      <v-col :lg="3" :md="12" :sm="12" class="d-flex flex-column" cols="12">
        <CardStreamStartStep :index="1">
          <template v-slot:title>Create Stream</template>
          <template v-slot:content>
            <StreamName />
          </template>
        </CardStreamStartStep>
      </v-col>

      <v-col :lg="6" :md="12" :sm="12" cols="12">
        <CardStreamStartStep :index="2" has-actions>
          <template v-slot:title>Add Viewers</template>
          <template v-slot:content>
            <v-layout>
              <v-flex>
                <v-card-text>
                  <StreamTeamSelection class="ma-0 pa-0" />
                </v-card-text>
              </v-flex>
              <v-divider vertical></v-divider>
              <v-flex md6 xs12>
                <v-card-text>
                  <StreamAddIndividualsCard class="ma-0 pa-0" />
                </v-card-text>
              </v-flex>
            </v-layout>
          </template>
          <template v-slot:actions>
            <v-btn
              small
              outlined
              class="ma-4"
              @click="$store.dispatch('setStreamViewers', { viewers: [] })"
            >
              Reset<v-icon color="warning" right dark> mdi-reload </v-icon>
            </v-btn>
          </template>
        </CardStreamStartStep>
      </v-col>

      <v-col :lg="3" :md="12" :sm="12" cols="12">
        <CardStreamStartStep :index="3">
          <template v-slot:title>Start Stream!</template>
          <template v-slot:content>
            <slot></slot>
          </template>
        </CardStreamStartStep>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardStreamStartStep from "@/components/cards/CardStreamStartStep.vue";
import StreamName from "@/components/streams/StreamName.vue";
import StreamTeamSelection from "@/components/streams/StreamTeamSelection.vue";
import StreamAddIndividualsCard from "@/components/streams/StreamAddIndividualsCard.vue";

export default {
  name: "StreamAddViewersCard",
  components: {
    StreamAddIndividualsCard,
    StreamTeamSelection,
    StreamName,
    CardStreamStartStep,
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
</style>
