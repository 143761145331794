<template>
  <v-combobox
    v-model="selected"
    :items="possibleEmails.filter((e) => !viewersActiveStore.includes(e))"
    prepend-inner-icon="mdi-email"
    clear-icon="mdi-close"
    clearable
    dense
    flat
    hide-details
    multiple
    class="my-2 w-100"
    :disabled="!$store.getters.canAddViewers"
    @change="selectionChanged"
    no-data-text="No members available for autocomplete"
  />
</template>

<script>
import { axiosApi } from "@/modules/axios/axiosApi";
import { mapState } from "vuex";

export default {
  name: "StreamAddIndividualsAutocomplete",
  data() {
    return {
      possibleEmails: [],
      selected: [],
    };
  },
  watch: {
    companyIDOrganisationStore: {
      handler() {
        this.getAllMemberEmails();
      },
    },
  },
  methods: {
    async getAllMemberEmails() {
      this.loading = true;
      const resp = await axiosApi.get("/api/user/organisation/members/", {
        params: {
          id: this.organisationStore.companyID,
        },
      });
      this.possibleEmails = resp.data.map((obj) => obj.email).flat();
      this.loading = false;
    },
    selectionChanged(e) {
      this.$store.dispatch("addStreamViewers", {
        viewers: e,
      });
      this.selected = [];
    },
  },
  created() {
    this.getAllMemberEmails();
  },
  computed: {
    ...mapState(["activeStreamStore", "organisationStore"]),
    companyIDOrganisationStore() {
      return this.organisationStore.companyID;
    },
    viewersActiveStore() {
      return this.activeStreamStore.viewers;
    },
  },
};
</script>

<style scoped>
</style>
