export const AWSErrors = {
    "AccessDenied": "AccessDeniedException",
    "ResourceNotFound": "ResourceNotFoundException",
    "ResourceInUse": "ResourceInUseException",
    "UnrecognizedClient": "UnrecognizedClientException"
}

export const CustomErrors = {
    "ActiveChannel": "ActiveChannelError",
    "ChannelARN": "ChannelARNError"
}

export const SessionStorageItems = {
    "ChannelARN": "channelARN",
    "ChannelUUID": "channelUUID"
};

export const APIBaseURL = process.env.VUE_APP_BASE_AXIOS_URL ? process.env.VUE_APP_BASE_AXIOS_URL : "http://localhost:8000"
export const WSBaseURL = process.env.VUE_APP_BASE_WS_URL ? process.env.VUE_APP_BASE_WS_URL : "ws://localhost:8000/ws/"

