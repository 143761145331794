<template>
  <v-card v-if="subtitle" class="d-flex flex-column align-center">
    <v-card-title>{{ subtitle }}</v-card-title>
    <v-card-subtitle>{{ description }}</v-card-subtitle>
    <slot name="start"></slot>
    <slot name="center"></slot>
    <slot name="end"></slot>
  </v-card>
</template>

<script>
export default {
  name: "ContentSettings",
  props: { subtitle: String, description: String },
};
</script>
