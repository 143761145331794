<template>
  <v-dialog v-model="showDialog" max-width="600px">
    <template v-slot:activator="{ on }">
      <ButtonPrimaryAction
        v-if="!editIcon"
        size="small"
        icon="mdi-plus"
        v-on="on"
        >Add Member</ButtonPrimaryAction
      >
      <v-icon v-else class="me-2" small v-on="on"> mdi-pencil </v-icon>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ dialogTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                :ref="'emailTextField'"
                v-model="email"
                :readonly="editIcon"
                :rules="emailRules"
                label="Email"
                prepend-inner-icon="mdi-email"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="memberPermissionsTable"
                :items-per-page="
                  Object.keys(organisationStore.allPermissions).length
                "
                hide-default-footer
                dense
              >
                <template v-slot:header>
                  <thead>
                    <tr>
                      <th></th>
                      <th
                        :colspan="maxPermissionLevel + 1"
                        class="table-head-group text-center"
                      >
                        Level
                      </th>
                    </tr>
                  </thead>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template
                  v-for="n in maxPermissionLevel + 1"
                  v-slot:[`item.${listPermissionLevel[n-1].toLowerCase()}`]="{
                    item,
                  }"
                >
                  <v-simple-checkbox
                    :key="n"
                    v-model="
                      item[`${listPermissionLevel[n - 1].toLowerCase()}`]
                    "
                    color="secondary"
                    @click="
                      ensureSinglePermissionLevel(
                        item,
                        listPermissionLevel[n - 1]
                      )
                    "
                  ></v-simple-checkbox>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-menu offset-y open-on-hover top>
          <template v-slot:activator="{ on }">
            <ButtonSecondaryAction
              class="mx-3 mb-3"
              size="small"
              v-on="on"
              @click="resetMemberPermissions"
              icon="mdi-reload"
              >Clear</ButtonSecondaryAction
            >
          </template>
          <v-list>
            <v-list-item v-if="memberDefaultPermissions != null"> </v-list-item>
            <v-list-item v-if="memberDefaultPermissions != null">
              <ButtonSecondaryAction
                class="mx-3 mb-3"
                size="small"
                @click="resetMemberPermissions"
                icon="mdi-clear"
                >Reset Permissions</ButtonSecondaryAction
              >
            </v-list-item>
            <v-list-item>
              <ButtonSecondaryAction
                class="mx-3 mb-3"
                size="small"
                @click="clearMemberPermissions"
                icon="mdi-clear"
                >Clear</ButtonSecondaryAction
              >
            </v-list-item>
            <v-list-item>
              <ButtonSecondaryAction
                class="mx-3 mb-3"
                size="small"
                @click="clearAll"
                icon="mdi-clear"
                >Clear All</ButtonSecondaryAction
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <ButtonPrimaryAction
          size="small"
          class="mx-2 mb-3"
          color="red"
          v-on="on"
          @click="cancel"
          icon="mdi-close"
          >Close</ButtonPrimaryAction
        >
        <ButtonPrimaryAction
          size="small"
          class="mx-2 mb-3"
          v-on="on"
          @click="saveMember"
          icon="mdi-content-save"
          >Save</ButtonPrimaryAction
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import { axiosApi } from "src/modules/auth/axiosApi";
import { axiosApi } from "../../modules/axios/axiosApi";
import { mapState } from "vuex";
import ButtonPrimaryAction from "../../components/buttons/ButtonPrimaryAction.vue";
import ButtonSecondaryAction from "../../components/buttons/ButtonSecondaryAction.vue";

export default {
  name: "MemberCUDialog",
  components: { ButtonPrimaryAction, ButtonSecondaryAction },
  props: {
    memberEmail: {
      type: String,
      required: false,
      default: null,
    },
    // TODO user memberPermissions in organisationStore
    memberDefaultPermissions: {
      type: Object,
      required: false,
      default: null,
    },
    editIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPendingMember: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    showDialog: false,
    buttonText: "",
    dialogTitle: "",
    email: "",
    emailRules: [
      (v) => !!v || "Email is required",
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail must be valid",
    ],
    maxPermissionLevel: 2,
    listPermissionLevel: ["None", "View", "Edit"],
    headers: [],
    memberPermissionsTable: [],
    memberPermissions: {},
  }),
  methods: {
    async saveMember() {
      this.updateMemberPermission();
      const payload = {
        email: this.email,
      };
      const permissionDict = {};
      Object.entries(this.memberPermissions).forEach((entry) => {
        const [key, value] = entry;
        if (value !== 0) {
          permissionDict[key] = value === 2;
        }
      });
      payload["permissions"] = permissionDict;
      if (this.isPendingMember) {
        axiosApi
          .post(
            "/api/user/organisation/pending-members/",
            {
              members: [payload],
            },
            {
              params: { id: this.organisationStore.companyID },
            }
          )
          .then((response) => {
            this.$emit("member-updated", response.data[0]);
            this.showDialog = false;
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        let endpoint =
          this.memberEmail == null
            ? "/api/user/organisation/members/"
            : "/api/user/organisation/permissions/";

        endpoint += `?id=${this.organisationStore.companyID}`;
        axiosApi
          .put(endpoint, {
            members: [payload],
          })
          .then((response) => {
            this.$emit("member-updated", response.data[0]);
            this.showDialog = false;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    cancel() {
      this.showDialog = false;
    },
    updateMemberPermission() {
      const displayNameKeyNameDict = {};
      for (const [name, value] of Object.entries(
        this.organisationStore.allPermissions
      )) {
        displayNameKeyNameDict[value["display_name"]] = name;
      }
      this.memberPermissionsTable.forEach((entry) => {
        for (let index = 0; index < this.listPermissionLevel.length; index++) {
          const level = this.listPermissionLevel[index].toLowerCase();
          if (entry[level]) {
            this.memberPermissions[displayNameKeyNameDict[entry.name]] = index;
            break;
          }
        }
      });
    },
    updateMemberPermissionTable() {
      this.memberPermissionsTable = [];
      for (const [name, value] of Object.entries(
        this.organisationStore.allPermissions
      )) {
        const entry = { name: value["display_name"] };
        for (let index = 0; index < this.listPermissionLevel.length; index++) {
          const level = this.listPermissionLevel[index].toLowerCase();
          entry[level] =
            Number(this.memberPermissions[name]) === index || false;
        }
        this.memberPermissionsTable.push(entry);
      }
    },
    resetMemberPermissions() {
      for (const permission in this.organisationStore.allPermissions) {
        this.memberPermissions[permission] = Number(
          this.memberDefaultPermissions[permission]
        );
      }
      this.updateMemberPermissionTable();
    },
    clearMemberPermissions() {
      for (const permission in this.organisationStore.allPermissions) {
        this.memberPermissions[permission] = 0;
      }
      this.updateMemberPermissionTable();
    },
    clearAll() {
      this.clearMemberPermissions();
      if (this.dialogTitle === "New member") {
        this.email = "";
        this.$refs.emailTextField.focus();
      }
    },
    setHeaders() {
      this.headers = [
        {
          text: "Permission",
          value: "name",
          align: "left",
        },
      ];
      this.listPermissionLevel.forEach((level) => {
        this.headers.push({
          text: level,
          value: level.toLowerCase(),
          align: "center",
        });
      });
    },
    ensureSinglePermissionLevel(item, level) {
      for (const l of this.listPermissionLevel) {
        item[l.toLowerCase()] = level === l;
      }
    },
  },
  created() {
    this.setHeaders();
    this.clearMemberPermissions();
    this.updateMemberPermissionTable();
    if (this.memberEmail == null) {
      this.dialogTitle = "New member";
      this.buttonText = "Add member";
    } else {
      this.email = this.memberEmail;
      this.dialogTitle = "Edit member";
      this.buttonText = "Edit";
      if (this.memberDefaultPermissions != null) {
        this.resetMemberPermissions();
      }
    }
  },
  computed: {
    ...mapState(["organisationStore"]),
  },
};
</script>