<template>
  <div>
    <v-menu
      bottom
      min-width="280px"
      offset-y
      style="border-radius: 26px !important"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-avatar
            :style="{
              backgroundColor: backgroundColor,
              color: contentColor,
            }"
            size="36"
          >
            <span>{{ userStore.email.charAt(0) }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-list id="drawer-profile" dense nav class="pa-0">
        <v-list-item-content>
          <div class="mx-auto text-center">
            <v-avatar
              :style="{
                backgroundColor: backgroundColor,
                color: contentColor,
              }"
              size="36"
              class="ma-3"
            >
              <span class="text-uppercase">{{
                userStore ? userStore.email.charAt(0) : ""
              }}</span>
            </v-avatar>
            <h3>{{ userStore.firstName ? " " + userStore.firstName : "" }}</h3>
            <p class="text-caption ma-0">
              {{ userStore ? userStore.email : "" }}
            </p>
            <slot name="start"></slot>
            <v-divider></v-divider>
            <slot name="center"></slot>
            <v-divider class="secondary"></v-divider>
            <slot name="end"></slot>
          </div>
        </v-list-item-content>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DrawerProfile",
  computed: {
    ...mapState(["userStore"]),
    backgroundColor() {
      return this.$vuetify.theme.currentTheme.primary;
    },
  },
};
</script>

<style scoped>
</style>
