<template>
  <v-card flat style="width: 50%" class="rounded-0 py-10">
    <S3Upload label="Icon" dest="icon" />
    <S3Upload label="Logo" dest="logo" />
    <v-card-actions class="justify-center">
      <ButtonSecondaryAction
        :color="warning"
        size="small"
        @custom-click="reset"
        icon="mdi-reload"
        >Reset</ButtonSecondaryAction
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import S3Upload from "@/components/S3Upload.vue";
import { axiosApi } from "@/modules/axios/axiosApi";
import { mapState } from "vuex";
import ButtonSecondaryAction from "../buttons/ButtonSecondaryAction.vue";

export default {
  name: "OrganisationThemeingLogo",
  components: { S3Upload, ButtonSecondaryAction },
  methods: {
    async reset() {
      const body = {
        icon: "",
        logo: "",
      };
      await axiosApi.put("/api/user/organisation/assets/", body, {
        params: {
          id: this.organisationStore.companyID,
        },
      });
      await this.$store.dispatch("getCompanyAssets");
    },
  },
  computed: {
    ...mapState(["organisationStore"]),
  },
};
</script>

<style>
</style>