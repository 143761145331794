<template>
    <v-app-bar-nav-icon
        color="secondary"
        @click.stop="updateMenuMiniState"
        v-if="!$vuetify.breakpoint.mobile"
    ></v-app-bar-nav-icon>
</template>

<script>
import { mapState } from "vuex";

export default {
    name:"IconDesktopNav",
    computed: {
        ...mapState([
            'globalComponentStore',
        ]),
    },
    methods: {
        updateMenuMiniState() {
        this.$store
            .dispatch("menuMiniStateChange", {
            value: !this.globalComponentStore.menuMiniState
            })
        },
  },
}
</script>
