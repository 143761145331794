<template>
  <ContentDiv>
    <PageTitle>Settings</PageTitle>
    <p>
      Configure various settings relating to your profile
    </p>
    <PageTitleDivider />
    <UserProfile class="my-10"/>
  </ContentDiv>
</template>

<script>
import ContentDiv from "@/components/ContentDiv.vue";
import PageTitle from "@/components/PageTitle.vue";
import PageTitleDivider from "@/components/dividers/PageTitleDivider.vue";
import UserProfile from "@/components/users/UserProfile.vue";

export default {
  name: "ViewSettingsUser",
  components: {
    UserProfile,
    ContentDiv,
    PageTitle,
    PageTitleDivider,
  },
};
</script>

<style scoped lang="scss">
</style>
