import { sessionStorageKeys } from "@/store/modules/activeStreamStore/state";

export default {
    setActiveStreamStore(state, { arn, connected, uuid, name, viewers, invalidViewers, unknownViewers }) {
        state.arn = arn;
        state.connected = connected;
        state.uuid = uuid;
        state.name = name;
        state.viewers = viewers;
        state.invalidViewers = invalidViewers;
        state.unknownViewers = unknownViewers;
        setSessionStorage('arn', state.arn);
        setSessionStorage('connected', state.connected);
        setSessionStorage('uuid', state.uuid);
        setSessionStorage('name', state.name);
        setSessionStorage('viewers', state.viewers);
        setSessionStorage('invalidViewers', state.invalidViewers);
        setSessionStorage('unknownViewers', state.unknownViewers);
    },
    setStreamName(state, { name }) {
        state.name = name;
        setSessionStorage('name', state.name);
    },
    setStreamUUID(state, { uuid }) {
        state.uuid = uuid;
        setSessionStorage('uuid', state.uuid);
    },
    setViewers(state, { viewers }) {
        state.viewers = viewers;
        setSessionStorage('viewers', state.viewers);
    },
    setInvalidUnknownViewers(state, { invalid, unknown }) {
        state.invalidViewers = invalid;
        state.unknownViewers = unknown;
        setSessionStorage('invalidViewers', state.invalidViewers);
        setSessionStorage('unknownViewers', state.unknownViewers);
    },
    setStreamConnected(state, { connected }) {
        state.connected = connected;
        setSessionStorage('connected', state.connected);
    },
    resetStreamViewers(state) {
        state.viewers = [];
        sessionStorage.removeItem("activeStreamStoreViewers");
    },
}

function setSessionStorage(field, value) {
    if (typeof value !== 'string') {
        value = JSON.stringify(value);
    }
    sessionStorage.setItem(sessionStorageKeys[field], value);
}