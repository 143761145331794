import { render, staticRenderFns } from "./TeamAddMemberPanel.vue?vue&type=template&id=1fe208a9&scoped=true&"
import script from "./TeamAddMemberPanel.vue?vue&type=script&lang=js&"
export * from "./TeamAddMemberPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fe208a9",
  null
  
)

export default component.exports