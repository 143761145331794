export class ActiveChannelError extends Error {
    constructor(message = "", ...args) {
        super(message);
        this.name = "ActiveChannelError"
        this.message = message;
    }
}

export class ChannelARNError extends Error {
    constructor(message = "", ...args) {
        super(message);
        this.name = "ChannelARNError"
        this.message = message;
    }
}
