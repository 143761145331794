/*
* Fetches data from the DB and updates the store.
* */

import {axiosApi} from 'src/modules/axios/axiosApi.js';
import {isIdDefined} from "./helpers";


export default {
	getMemberPermissions(context) {
		return new Promise((resolve, reject) => {
			axiosApi.get('/api/user/organisation/permissions/', {
				params: {id: context.state.companyID}
			}).then(response => {
				context.commit('setMemberPermissions', response.data);
				resolve();
			}).catch(err => {
				reject(err);
			})
		})
	},
	getAllPermissions(context) {
		return new Promise((resolve, reject) => {
			axiosApi.get('/api/organisations/permissions/', {}).then(response => {
				context.commit('setOrganisationPermissions', response.data['permissions']);
				resolve();
			}).catch(err => {
				reject(err);
			})
		});
	},
	async getCompanyInfo(context) {
		if (!isIdDefined(context.state.companyID)) throw ReferenceError("ID not set");
		const resp = await axiosApi
			.get("/api/user/organisation/details/", {
				params: {
					id: context.state.companyID
				},
			})
			.catch((error) => {
				console.error(error);
			});
		if (resp.status === 200) {
			context.commit('setOrganisationDetail', {
				companyID: resp.data['id'],
				companyName: resp.data['name'],
				darkPrimaryColour: resp.data['dark_primary_colour'],
				darkSecondaryColour: resp.data['dark_secondary_colour'],
				lightPrimaryColour: resp.data['light_primary_colour'],
				lightSecondaryColour: resp.data['light_secondary_colour'],
			})
		}
	},
	async getCompanyTheme(context) {
		if (!isIdDefined(context.state.companyID)) throw ReferenceError("ID not set");
		const resp = await axiosApi.get('/api/user/organisation/details/', {
			params: {
				id: context.state.companyID
			}
		});
		context.commit('setCompanyTheme', {
			darkPrimaryColour: resp.data['dark_primary_colour'],
			darkSecondaryColour: resp.data['dark_secondary_colour'],
			lightPrimaryColour: resp.data['light_primary_colour'],
			lightSecondaryColour: resp.data['light_secondary_colour'],
		});
	},
	async getCompanyAssets(context) {
		if (!isIdDefined(context.state.companyID)) throw ReferenceError("ID not set");
		const resp = await axiosApi.get('/api/user/organisation/assets/', {
			params: {
				id: context.state.companyID
			}
		});
		context.commit('setCompanyAssets', {
			logo: resp.data.logo,
			icon: resp.data.icon
		});
	},
}