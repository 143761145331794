<template>
  <v-btn
    v-bind:small="size === 'small'"
    v-bind:large="size === 'large'"
    v-on="$listeners"
    elevation="0"
    outlined
    @custom-click="$emit('click')"
    :disabled="disabled"
  >
    <slot color="white"></slot>
    <v-icon
      :color="color"
      v-if="icon"
      v-bind:right="this.$slots.default ? true : false"
      >{{ icon }}</v-icon
    >
  </v-btn>
</template>
  
  <script>
export default {
  name: "ButtonSecondaryAction",
  props: {
    size: {
      type: String,
      default: "medium",
      validator: (value) => ["small", "medium", "large"].includes(value),
    },
    icon: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    on: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "warning",
    },
  },
};
</script>