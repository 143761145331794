import Vue from 'vue'
import Vuex from 'vuex'
import activeStreamStore from './modules/activeStreamStore'
import authStore from './modules/authStore'
import addressStore from './modules/addressStore'
import userStore from './modules/userStore'
import organisationStore from 'src/store/modules/organisationStore'
import streamStore from 'src/store/modules/streamStore'
import teamStore from 'src/store/modules/teamStore'
import globalComponentStore from './modules/globalComponentStore'
import websocketStore from 'src/store/modules/websocketStore'


Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        activeStreamStore: activeStreamStore,
        authStore: authStore,
        addressStore: addressStore,
        userStore: userStore,
        organisationStore: organisationStore,
        streamStore: streamStore,
        teamStore: teamStore,
        globalComponentStore: globalComponentStore,
        websocketStore: websocketStore,
    }
});
