import {axiosApi} from "@/modules/axios/axiosApi";
import {LocalDatabase} from "@/modules/localDB/model.mjs";

// Initialise
export const localDB = new LocalDatabase("IrisLocal", 1);
// Available globally
window.$localDB = localDB;

window.addEventListener('uploadComplete', async (event) => {
    const uuid = event.detail;
    await axiosApi.post(`api/streams/${uuid}/recording/`);
});

window.addEventListener('resumeUploads',  () => {
    initDB().then();
});

export async function initDB() {
    if (localDB.isReady()){
        await localDB.initialiseUploadProgressStore();
        const availableStreams = await localDB.getAllStreams();
        availableStreams.forEach(stream => {
            startUpload(stream);
        });
    } else {
        window.addEventListener('indexDBSuccess', async () => {
            // Start uploads if available immediately
            await localDB.initialiseUploadProgressStore();
            const availableStreams = await localDB.getAllStreams();
            availableStreams.forEach(stream => {
                startUpload(stream);
            });
        })
    }
}

export async function startUpload(uuid) {
    let packet = await localDB.getNextVideoPacket(uuid);
    if (!packet) return;
    let startResp;
    switch (await localDB.hasUploadStarted(uuid)) {
        case true:
            break;
        case false:
            startResp = await axiosApi.post(`api/streams/${uuid}/recording/`, {
                mimetype: packet['snippet']['type'] || "webm"
            });
            if (startResp.status === 201) {
                localDB.setUploadStarted(uuid);
            }
            break;
        default:
            return;
    }

    const form = new FormData()
    while (packet && !localDB.isUploadPaused()) {
        form.set('packet', packet['snippet']);
        await axiosApi.put(`api/streams/${uuid}/recording/`, form);
        packet = await localDB.getNextVideoPacket(uuid);
    }
}
