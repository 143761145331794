<template>
  <v-btn
      small elevation="0" color="error"
      class="ma-auto"
      @click="submit"
  >
    <v-icon class="mr-1">mdi-shield-alert</v-icon>
    Verify email address
  </v-btn>
</template>

<script>
import {axiosApi} from "@/modules/axios/axiosApi";

export default {
  name: "ButtonVerifyEmailAddress",
  methods: {
    async submit() {
      const resp = await axiosApi.post('api/mail_ses/action/', {
        action: "verify_email"
      });
      console.log(resp);
    }
  }
}
</script>

<style scoped>

</style>