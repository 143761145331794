import { axiosApi } from "src/modules/axios/axiosApi";

export default {
    async getAllStreams(context) {
        const companyID = context.rootState.organisationStore.companyID;
        let params = undefined;
        if (companyID) {
            params = {
                id: companyID,
            };
        }
        const resp = await axiosApi.get("api/streams/", {
            params: params,
        });
        const streamsRsp = resp.data;
        const streams = [];
        streamsRsp.forEach((stream) => {
            streams.push(createStreamObjectFromResponse(stream));
        });
        context.commit('setAllStreams', { streams: streams });
    },
    async fetchStream(context, {uuid}) {
        let params = {
            uuid: uuid
        };
        const companyID = context.rootState.organisationStore.companyID;
        if (companyID) {
            params['id'] = companyID;
        }
        const resp = await axiosApi.get("api/streams/live/", {
            params: params,
        });
        const stream = createStreamObjectFromResponse(resp.data);

        context.commit('setStream', { stream: stream })
    },
}

function createStreamObjectFromResponse(response) {
    const stream = response;
    const started = new Date(stream["started_at"]);
    const ended = new Date(stream["ended_at"]);
    let duration = ended - started; // in milliseconds
    stream["started_at"] = started.toLocaleString("en-ZA");
    stream["ended_at"] = ended.toLocaleString("en-ZA");
    stream["duration"] = duration;
    return stream;
}