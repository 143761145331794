import {axiosCountriesAPI} from "@/modules/axios/axiosApi";

export default {
	async fetchAllCountries(context) {
		const countries = {};
		const resp = await axiosCountriesAPI.get('countries');
		resp.data.forEach(country => {
			countries[country['name']] = {
				iso2: country['iso2'],
				states: {}
			}
		});
		const sortedCountries = Object.keys(countries).sort()
			.reduce((obj, key) => {
				obj[key] = countries[key];
				return obj;
			}, {});
		context.commit('updateAddressStore', {countries: sortedCountries});
	},
	async fetchCountryStates(context, {country}) {
		if ((!country) || (!context.state.countries[country])) return;
		const states = {};
		const iso2 = context.state.countries[country].iso2;
		const resp = await axiosCountriesAPI.get(`countries/${iso2}/states`);
		resp.data.forEach(state => {
			states[state['name']] = {
				iso2: state['iso2'],
				cities: []
			}
		});
		const sortedStates = Object.keys(states).sort()
			.reduce((obj, key) => {
				obj[key] = states[key];
				return obj;
			}, {});
		context.commit('updateCountryStates', {
			country: country,
			states: sortedStates
		})
	},
	async fetchStateCities(context, {country, countryState}) {
		if ((!country) || (!context.state.countries[country])) return;
		if ((!countryState) || (!context.state.countries[country]['states'][countryState])) return;
		const cities = [];
		const ciso2 = context.state.countries[country].iso2;
		const siso2 = context.state.countries[country]['states'][countryState].iso2;
		const resp = await axiosCountriesAPI.get(`countries/${ciso2}/states/${siso2}/cities`);
		resp.data.forEach(city => {
			cities.push(city['name']);
		});
		const sortedCities = cities.sort();
		context.commit('updateStateCities', {
			country: country,
			countryState: countryState,
			cities: sortedCities
		})
	}
}