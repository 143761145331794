<template>
  <v-card class="ma-auto pa-10" max-width="344">
    <v-text-field
        label="One Time Pin" autofocus clearable
        persistent-hint class="w-100"
        autocomplete="one-time-code" inputmode="numeric"
        v-model="otp"
        :messages="(timer.expired)? 'The OTP has expired.' : `The OTP is still valid for ${timer.minutes} minutes and ${timer.seconds} seconds.`"
        @focus="otpErrorMessageShow = false"
    />
    <v-btn
        class="ma-2"
        @click="submit()"
    >
      Send
      <v-icon class="ml-2">mdi-send</v-icon>
    </v-btn>
    <v-alert v-if="otpErrorMessageShow" dense type="error">
      {{ otpErrorMessage }}
    </v-alert>
  </v-card>
</template>

<script>
export default {
  name: "AuthOTP",
  props: {
    otpErrorMessage: {
      require: true
    },
    modelValue: {
      require: true,
      type: String
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  data() {
    return {
      timer: null,
      otp: null,
      otpErrorMessageShow: false
    }
  },
  watch: {
    otpErrorMessage(value) {
      this.otpErrorMessageShow = value != null;
    }
  },
  methods: {
    submit() {
      this.$emit('update:modelValue', this.otp);
      this.$emit('submit');
    },
    startTimer() {
      this.timer = {
        minutes: 5,
        seconds: 0,
        expired: false
      }
      const timerInterval = setInterval(() => {
        if (this.timer.seconds <= 0) {
          this.timer.seconds = 60;
          this.timer.minutes -= 1;
          if (this.timer.minutes < 0) {
            this.timer = {
              minutes: 0,
              seconds: 0,
              expired: true
            }
            clearInterval(timerInterval);
            this.$emit("otpExpired")
          }
        }
        this.timer.seconds -= 1;
      }, 1000)
    },
    /**
     * @link https://web-otp.glitch.me
     * @return {Promise<void>} Sets a component level variable
     */
    async otpAutofill() {
      const signal = new AbortController();
      setTimeout(() => {
        signal.abort();
      }, 5 * 60 * 1000); // abort after 5 minutes
      if ('OTPCredential' in window) {
        try {
          if (navigator.credentials) {
            try {
              await navigator.credentials
                  .get({
                    abort: signal,
                    otp: {
                      transport: ['sms']
                    }
                  })
                  .then(content => {
                    if (content && content.code) {
                      this.otp = content.code;
                    }
                  })
            } catch (e) {
              console.error(e);
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  },
  created() {
    this.otpAutofill();
    this.startTimer();
  }
}
</script>

<style scoped lang="scss">
w-100 {
  width: 100%;
}
</style>