<template>
  <v-autocomplete
      v-model="selected"
      @change="selectedState"
      :items="states"
      label="State/Province"
      item-text="name" item-value="name"
      dense outlined
      :disabled="(country == null) || (country === '')" :loading="isLoading"
  />
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AutocompleteStates",
  props: ['modelValue', 'country'],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  data() {
    return {
      selected: null,
      states: [],
      isLoading: false
    }
  },
  methods: {
    async getAllStates() {
      if (!this.country) return;
      this.isLoading = true;
      this.selected = null;
      let states = this.$store.getters.getCountryStates(this.country) || [];
      if (states.length <= 0) {
        await this.$store.dispatch('fetchCountryStates', {
          country: this.country
        });
        states = this.$store.getters.getCountryStates(this.country);
      }
      this.states = states;
      this.isLoading = false;
      this.selected = this.userStore.state;
    },
    selectedState() {
      this.$emit('update:modelValue', this.selected);
    }
  },
  watch: {
    country: {
      async handler(value) {
        if (value) {
          await this.getAllStates();
        }
      }
    },
    countryStoreObject: {
      async handler(value) {
        if (value) {
          await this.getAllStates();
        }
      }
    }
  },
  created() {
    this.getAllStates();
    this.selected = this.modelValue;
  },
  computed:{
    ...mapState(['addressStore', 'userStore']),
    countryStoreObject() {
      try {
        return this.addressStore.countries[this.country].states
      } catch (e) {
        if (e instanceof TypeError) {
          return null;
        }
        throw e;
      }
    }
  }
}
</script>

<style scoped>

</style>