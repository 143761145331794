import { 
    KinesisVideoSignalingClient,
    GetIceServerConfigCommand
 } from "@aws-sdk/client-kinesis-video-signaling";

import {
    AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY,
    AWS_REGION,
    checkAccessTokens, AWS_SESSION_TOKEN
} from "src/modules/getAccessKeys.mjs";
import {
    ActiveChannelError
} from "src/modules/errors.mjs";
import { 
    SessionStorageItems,
    AWSErrors
 } from "../globalConstants.mjs";

let kinesisVideoSignalingClient = null;

function createClient(endpointURL) {
    try {
        kinesisVideoSignalingClient = new KinesisVideoSignalingClient({
            region: AWS_REGION,
            credentials: {
                accessKeyId: AWS_ACCESS_KEY_ID,
                secretAccessKey: AWS_SECRET_ACCESS_KEY,
                sessionToken: AWS_SESSION_TOKEN
            },
            endpoint: endpointURL
        });
    } catch (error) {
        if (error.name === AWSErrors.AccessDenied) {
            checkAccessTokens().then();
        } else {
            throw error;
        }
    }
}

function checkClientCreated(endpointURL){
    if (kinesisVideoSignalingClient == null) {
        createClient(endpointURL);
    }
}

export async function getICEServerConfig(endpointURL) {
    checkClientCreated(endpointURL);
    const channelARN = sessionStorage.getItem(`MASTER_${SessionStorageItems.ChannelARN}`) || sessionStorage.getItem(`VIEWER_${SessionStorageItems.ChannelARN}`);
    if (channelARN == null) {
        throw new ActiveChannelError("No active channel at getICEServerConfig.");
    }
    return await kinesisVideoSignalingClient.send(
        new GetIceServerConfigCommand({
            ChannelARN: channelARN,
        })
    );
}