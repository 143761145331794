<template>
  <v-dialog v-model="showDialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        text
        small
        class="text-center text-capitalize"
        @click="formSetup"
      >
        Reset password
      </v-btn>
    </template>
    <v-card class="d-flex flex-column align-center">
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title
            class="mt-5 text-h5 font-weight-bold mb-1 text-center"
          >
            Reset Password
          </v-list-item-title>
          <v-list-item-subtitle class="mb-1 text-center"
            >Enter your email below to reset your password</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-form v-model="valid">
        <v-text-field
          outlined
          dense
          ref="emailTextField"
          :rules="emailRules"
          @focus="formSetup"
          v-model="email"
          label="Email"
          required
          prepend-inner-icon="mdi-email"
        />
      </v-form>
      <v-btn
        elevation="0"
        color="primary"
        class="mb-5 text-capitalize"
        @click="submit"
        :disabled="!valid"
      >
        Send
      </v-btn>
      <v-alert outlined v-if="incorrectEmail" dense type="error">
        The email address provided is not associated with any account. Please
        ensure the email provided address is correct.
      </v-alert>
      <v-alert outlined v-if="tooManyReqs" dense type="error">
        You have already made this request. If the email does not arrive in 5
        minutes, please try again.
      </v-alert>
      <v-alert
        v-if="submittedRequest && !(incorrectEmail || tooManyReqs)"
        dense
        outlined
        type="success"
      >
        An email should arrive in your inbox shortly.
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import { axiosUnsafeApi } from "@/modules/axios/axiosApi";

export default {
  name: "DialogForgotPassword",
  props: {
    emailPreset: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    showDialog: false,
    valid: false,
    email: null,
    emailRules: [
      (v) => !!v || "Email is required",
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail must be valid",
    ],
    incorrectEmail: false,
    tooManyReqs: false,
    submittedRequest: false,
  }),
  methods: {
    cancel() {
      this.showDialog = false;
    },
    submit() {
      axiosUnsafeApi
        .put("api/users/forgot-password/", {
          email: this.email,
        })
        .catch((err) => {
          this.incorrectEmail = err.response && err.response.status === 400;
          this.tooManyReqs = err.response && err.response.status === 403;
          this.submittedRequest = false;
          if (!(this.incorrectEmail || this.tooManyReqs)) throw err;
        })
        .then((resp) => {
          console.log(resp);
          this.submittedRequest = true;
        });
    },
    formSetup() {
      this.email = this.emailPreset;
      this.incorrectEmail = false;
      this.tooManyReqs = false;
      this.submittedRequest = false;
    },
  },
};
</script>