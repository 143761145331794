<template>
    <v-app-bar-nav-icon
        color="appBarIcon"
        v-on:click="updateMenuMobileState"
        v-if="$vuetify.breakpoint.mobile"
    ></v-app-bar-nav-icon>
</template>

<script>
import { mapState } from "vuex";

export default {
    name:"IconMobileNav",
    computed: {
        ...mapState([
            'menuMobileState',
        ]),
    },
    methods: {
        updateMenuMobileState() {
        this.$store
            .dispatch("menuMobileStateChange", {
            value: true
            })
        },
  },
}
</script>
