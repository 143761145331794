var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(!_vm.editIcon)?_c('ButtonPrimaryAction',_vm._g({attrs:{"size":"small","icon":"mdi-plus"}},on),[_vm._v("Add Member")]):_c('v-icon',_vm._g({staticClass:"me-2",attrs:{"small":""}},on),[_vm._v(" mdi-pencil ")])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.dialogTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{ref:'emailTextField',attrs:{"readonly":_vm.editIcon,"rules":_vm.emailRules,"label":"Email","prepend-inner-icon":"mdi-email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.memberPermissionsTable,"items-per-page":Object.keys(_vm.organisationStore.allPermissions).length,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"table-head-group text-center",attrs:{"colspan":_vm.maxPermissionLevel + 1}},[_vm._v(" Level ")])])])]},proxy:true},_vm._l((_vm.maxPermissionLevel + 1),function(n){return {key:`item.${_vm.listPermissionLevel[n-1].toLowerCase()}`,fn:function({
                  item,
                }){return [_c('v-simple-checkbox',{key:n,attrs:{"color":"secondary"},on:{"click":function($event){return _vm.ensureSinglePermissionLevel(
                      item,
                      _vm.listPermissionLevel[n - 1]
                    )}},model:{value:(
                    item[`${_vm.listPermissionLevel[n - 1].toLowerCase()}`]
                  ),callback:function ($$v) {_vm.$set(item, `${_vm.listPermissionLevel[n - 1].toLowerCase()}`, $$v)},expression:"\n                    item[`${listPermissionLevel[n - 1].toLowerCase()}`]\n                  "}})]}}})],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('ButtonSecondaryAction',_vm._g({staticClass:"mx-3 mb-3",attrs:{"size":"small","icon":"mdi-reload"},on:{"click":_vm.resetMemberPermissions}},on),[_vm._v("Clear")])]}}])},[_c('v-list',[(_vm.memberDefaultPermissions != null)?_c('v-list-item'):_vm._e(),(_vm.memberDefaultPermissions != null)?_c('v-list-item',[_c('ButtonSecondaryAction',{staticClass:"mx-3 mb-3",attrs:{"size":"small","icon":"mdi-clear"},on:{"click":_vm.resetMemberPermissions}},[_vm._v("Reset Permissions")])],1):_vm._e(),_c('v-list-item',[_c('ButtonSecondaryAction',{staticClass:"mx-3 mb-3",attrs:{"size":"small","icon":"mdi-clear"},on:{"click":_vm.clearMemberPermissions}},[_vm._v("Clear")])],1),_c('v-list-item',[_c('ButtonSecondaryAction',{staticClass:"mx-3 mb-3",attrs:{"size":"small","icon":"mdi-clear"},on:{"click":_vm.clearAll}},[_vm._v("Clear All")])],1)],1)],1),_c('v-spacer'),_c('ButtonPrimaryAction',_vm._g({staticClass:"mx-2 mb-3",attrs:{"size":"small","color":"red","icon":"mdi-close"},on:{"click":_vm.cancel}},_vm.on),[_vm._v("Close")]),_c('ButtonPrimaryAction',_vm._g({staticClass:"mx-2 mb-3",attrs:{"size":"small","icon":"mdi-content-save"},on:{"click":_vm.saveMember}},_vm.on),[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }