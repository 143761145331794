import {
    CreateSignalingChannelCommand,
    DeleteSignalingChannelCommand,
    DescribeSignalingChannelCommand,
    GetSignalingChannelEndpointCommand
} from "@aws-sdk/client-kinesis-video";


import {checkAccessTokens, validateCredentials} from "../getAccessKeys.mjs";
import {getClient} from "../webrtc/kinesisVideoClient.mjs";
import {AWSErrors, SessionStorageItems} from "../globalConstants.mjs";

let kinesisVideoClient = null;

function checkClientCreated(){
    if (kinesisVideoClient == null) {
        kinesisVideoClient = getClient();
    }
}

export async function createSignalingChannel(channelName) {
    try {
        await kinesisVideoClient.send(
            new CreateSignalingChannelCommand({
                ChannelName: channelName
            })
        );
    } catch (e) {
        console.log(e);
    }

    return await describeSignalingChannel(channelName);
}

export async function deleteSignalingChannel() {
    checkClientCreated();
    try {
        const channelARN = window.sessionStorage.getItem(`MASTER_${SessionStorageItems.ChannelARN}`);
        if (channelARN == null) {
            window.sessionStorage.getItem(`VIEWER_${SessionStorageItems.ChannelARN}`);
        }

        await kinesisVideoClient.send(
            new DeleteSignalingChannelCommand({
                ChannelARN: channelARN
            })
        );
        console.log('[DELETE_SINGLING_CHANNEL] Channel deleted.');
    } catch (error) {
        console.log('[DELETE_SINGLING_CHANNEL] Channel not deleted:');
        console.error(error);
    }  
    window.sessionStorage.removeItem(`VIEWER_${SessionStorageItems.ChannelARN}`);
    window.sessionStorage.removeItem(`MASTER_${SessionStorageItems.ChannelARN}`);
}

export async function describeSignalingChannel(channelName) {
    checkClientCreated();
    try {
        return await kinesisVideoClient.send(
            new DescribeSignalingChannelCommand({
                ChannelName: channelName,
            })
        );
    } catch (e) {
        switch (e.name) {
            case AWSErrors.AccessDenied:
                if (!await checkAccessTokens()) {
                    throw e
                }
                break;
            case AWSErrors.UnrecognizedClient:
                console.log("UnrecognizedClient");
                await validateCredentials();
                break;
            default:
                throw e;
        }
    }
}

export async function getChanelEndpoints(channelARN, role) {
    checkClientCreated();
    try {
        return await kinesisVideoClient.send(
            new GetSignalingChannelEndpointCommand({
                ChannelARN: channelARN,
                SingleMasterChannelEndpointConfiguration: {
                    Protocols: ['WSS', 'HTTPS'],
                    Role: role,
                },
            })
        );
    } catch (e) {
        switch (e.name) {
            case AWSErrors.AccessDenied:
                if (!checkAccessTokens()) {
                    throw e
                }
                break;
            case AWSErrors.UnrecognizedClient:
                console.log("UnrecognizedClient");
                break;
            default:
                throw e;
        }
    }
}

export function getSystemClockOffset() {
    checkClientCreated();
    return kinesisVideoClient.config.systemClockOffset;
}