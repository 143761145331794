<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row dark>
      <v-col cols="6" :lg="3" :md="6" :sm="6">
        <div class="colour_picker" id="dark-primary-picker">
          <label for="darkPrimaryColorPicker">Dark Primary color</label>
          <v-color-picker
            dark
            v-model="darkPrimaryColour"
            name="darkPrimaryColorPicker"
          >
          </v-color-picker>
        </div>
      </v-col>
      <v-col cols="6" :lg="3" :md="6" :sm="6">
        <div class="colour_picker" id="dark-secondary-picker">
          <label for="darkSecondaryColorPicker">Dark Secondary color</label>
          <v-color-picker
            dark
            v-model="darkSecondaryColour"
            name="darkSecondaryColorPicker"
          >
          </v-color-picker>
        </div>
      </v-col>
      <v-col cols="6" :lg="3" :md="6" :sm="6">
        <div class="colour_picker" id="light-primary-picker">
          <label for="lightPrimaryColorPicker">Light Primary color</label>
          <v-color-picker
            light
            v-model="lightPrimaryColour"
            name="lightPrimaryColorPicker"
          >
          </v-color-picker>
        </div>
      </v-col>
      <v-col cols="6" :lg="3" :md="6" :sm="6">
        <div class="colour_picker" id="light-secondary-picker">
          <label for="lightSecondaryColorPicker">Light Secondary color</label>
          <v-color-picker
            light
            v-model="lightSecondaryColour"
            name="lightSecondaryColorPicker"
          >
          </v-color-picker>
        </div>
      </v-col>
    </v-row>
    <v-btn class="mr-4" @click="submit"> submit </v-btn>
  </v-form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "OrganisationTheming",
  data() {
    return {
      valid: true,
      darkPrimaryColour: "",
      darkSecondaryColour: "",
      lightPrimaryColour: "",
      lightSecondaryColour: "",
      new_company: false,
      colorPermissions: {
        viewable: false,
        editable: false,
      },
    };
  },
  methods: {
    submit() {
      this.$store.commit("setCompanyTheme", {
        darkPrimaryColour: this.darkPrimaryColour.slice(0, 7),
        darkSecondaryColour: this.darkSecondaryColour.slice(0, 7),
        lightPrimaryColour: this.lightPrimaryColour.slice(0, 7),
        lightSecondaryColour: this.lightSecondaryColour.slice(0, 7),
      });
    },
  },
  computed: {
    ...mapState(["organisationStore"]),
  },
};
</script>

<style>
.colour_btn {
  padding: 20px;
  margin: 20px auto;
}
</style>