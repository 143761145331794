<template>
  <v-form id="form" ref="form" v-model="valid" lazy-validation>
    <v-text-field
      dense
      outlined
      v-model="name"
      :rules="nameRules"
      :counter="128"
      label="Company Name"
      required
    />
    <v-row>
      <v-col cols="12" :lg="6" :md="12" :sm="12">
        <v-textarea
          dense
          outlined
          :counter="128"
          label="Physical Address"
          required
        />
      </v-col>
      <v-col cols="12" :lg="6" :md="12" :sm="12">
        <v-textarea
          dense
          outlined
          :counter="128"
          label="Billing Address"
          required
        />
      </v-col>
    </v-row>
    <ButtonPrimaryAction
      class="mt-5"
      size="small"
      @custom-click="submit"
      icon="mdi-content-save"
      >Save</ButtonPrimaryAction
    >
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import ButtonPrimaryAction from "../buttons/ButtonPrimaryAction.vue";

export default {
  name: "OrganisationGeneral",
  components: { ButtonPrimaryAction },
  data() {
    return {
      valid: true,
      new_company: false,
      name: "",
      nameRules: [
        (v) => !!v.trim() || "Name is required",
        (v) =>
          (v.trim() && v.trim().length >= 2) ||
          "Minimum length is 2 characters",
        (v) =>
          (v.trim() && v.trim().length <= 128) ||
          "Maximum length is 64 characters",
      ],
      namePermissions: {
        viewable: true,
        editable: false,
      },
    };
  },
  watch: {
    storeName(value) {
      this.name = value;
    },
  },
  methods: {
    async validate() {
      return await this.$refs.form.validate();
    },
    reset() {
      this.name = this.storeName || "";
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async submit() {
      const formValid = await this.validate();
      if (!formValid) {
        window.scrollTo(0, 0);
        return;
      }
      let body = {
        name: this.name,
      };
      if (!this.new_company) {
        body["id"] = this.organisationStore.companyID;
      }
      await this.$store.dispatch("setCompanyDetails", body);
    },
    async getPermissions() {
      await this.$store.dispatch("getMemberPermissions");
      this.setPermissions(this.organisationStore.memberPermissions);
    },
    setPermissions(permissions) {
      const permissionKeyPairs = [["name", [this.namePermissions]]];
      permissionKeyPairs.forEach(([permission, data]) => {
        if (permission in Object.keys(permissions)) {
          // eslint-disable-next-line no-unused-vars
          data = {
            viewable: true,
            editable: permissions.get(permission),
          };
        }
      });
    },
    async getCompanyInfo() {
      await this.$store.dispatch("getCompanyInfo");
      this.reset();
    },
  },
  mounted() {
    this.reset();
    const inputs = document.getElementsByTagName("input");
    Array.from(inputs).forEach((i) => {
      i.addEventListener("focus", () => {
        this.resetValidation();
      });
      i.addEventListener("change", () => {
        try {
          this.name = this.name.trim();
        } catch (error) {
          // Ignore non text elements
        }
      });
    });
  },
  created() {
    this.new_company = !(
      this.organisationStore &&
      this.organisationStore.companyID &&
      !isNaN(this.organisationStore.companyID)
    );
    if (!this.new_company) {
      this.getCompanyInfo();
      this.getPermissions();
    } else {
      this.setPermissions({ superuser: true });
    }
  },
  computed: {
    ...mapState(["organisationStore"]),
    storeName() {
      return this.organisationStore.companyName;
    },
  },
};
</script>

<style scoped lang="scss">
#form {
  width: 85%;
  margin: auto;
}
</style>