export default {
	getAllCountries: (state) => () => {
		return Object.keys(state.countries);
	},
	getAllCountriesWithISO2: (state) => () => {
		const countries = Object.keys(state.countries);
		let obj = [];
		countries.forEach(country => {
			obj.push({
				name: country,
				iso2: state.countries[country].iso2
			})
		})
		return obj;
	},
	getCountryStates: (state) => (country) => {
		try {
			return Object.keys(state.countries[country]['states']);
		} catch (e) {
			if (e instanceof TypeError) {
				return null;
			}
			throw e;
		}

	},
	getStateCities: (state) => (country, countryState) => {
		try {
			return state.countries[country]['states'][countryState]['cities'];
		} catch (e) {
			if (e instanceof TypeError) {
				return null;
			}
			throw e;
		}
	}
}