<template>
  <div>
    <ButtonPrimaryAction
      v-if="!connected"
      size="large"
      :disabled="!enabled"
      @custom-click="connect"
      id="connect_webrtc"
      icon="mdi-play-circle"
    ></ButtonPrimaryAction>
    <ButtonPrimaryAction
      v-else
      size="large"
      :disabled="!enabled"
      @custom-click="disconnect"
      color="red"
      id="disconnect_webrtc"
      icon="mdi-stop-circle"
    ></ButtonPrimaryAction>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ButtonPrimaryAction from "./ButtonPrimaryAction.vue";

export default {
  name: "WebRTCConnect",
  components: { ButtonPrimaryAction },
  props: {
    connected: {
      type: Boolean,
      required: true,
      default: false,
    },
    enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    connect() {
      this.$store.dispatch("startedStream");
      this.$emit("connectClicked");
    },
    disconnect() {
      this.$store.dispatch("endedStream");
      this.$emit("disconnectClicked");
    },
  },
  computed: {
    ...mapState(["activeStreamStore"]),
    backgroundColor() {
      return this.$vuetify.theme.currentTheme.primary;
    },
  },
};
</script>

