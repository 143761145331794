<template>
  <v-container>
    <v-row dark>
      <v-col cols="6" :lg="6" :md="6" :sm="6">
        <div class="colour_picker" id="dark-primary-picker">
          <label for="darkPrimaryColorPicker">Dark Primary color</label>
          <v-color-picker
            dark
            v-model="darkPrimaryColour"
            name="darkPrimaryColorPicker"
            class="mx-auto"
          >
          </v-color-picker>
        </div>
      </v-col>
      <v-col cols="6" :lg="6" :md="6" :sm="6">
        <div class="colour_picker" id="dark-secondary-picker">
          <label for="darkSecondaryColorPicker">Dark Secondary color</label>
          <v-color-picker
            dark
            v-model="darkSecondaryColour"
            name="darkSecondaryColorPicker"
            class="mx-auto"
          >
          </v-color-picker>
        </div>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <ButtonSecondaryAction
        class="mx-3 mb-3"
        size="small"
        @custom-click="reset"
        icon="mdi-reload"
        >Reset</ButtonSecondaryAction
      >
      <ButtonPrimaryAction
        class="mx-3 mb-3"
        size="small"
        @custom-click="submit"
        icon="mdi-content-save"
        >Save</ButtonPrimaryAction
      >
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { DefaultColours } from "@/store/modules/organisationStore/state";
import ButtonPrimaryAction from "../buttons/ButtonPrimaryAction.vue";
import ButtonSecondaryAction from "../buttons/ButtonSecondaryAction.vue";
/*
 * TODO Load default values.
 * TODO One components, prop for light/dark
 * */

export default {
  name: "OrganisationThemingDark",
  components: { ButtonPrimaryAction, ButtonSecondaryAction },
  data() {
    return {
      darkPrimaryColour: "",
      darkSecondaryColour: "",
      new_company: false,
      colorPermissions: {
        viewable: false,
        editable: false,
      },
    };
  },
  watch: {
    storeDarkPrimaryColour(value) {
      this.darkPrimaryColour = value;
    },
    storeDarkSecondaryColour(value) {
      this.darkPrimaryColour = value;
    },
  },
  methods: {
    submit() {
      this.$store.dispatch("setCompanyDetails", {
        dark_primary_colour: this.darkPrimaryColour.slice(0, 7),
        dark_secondary_colour: this.darkSecondaryColour.slice(0, 7),
      });
    },
    reset() {
      this.$store.dispatch("setCompanyDetails", {
        dark_primary_colour: DefaultColours.darkPrimary,
        dark_secondary_colour: DefaultColours.darkSecondary,
      });
    },
  },
  created() {
    this.darkPrimaryColour = this.storeDarkPrimaryColour;
    this.darkSecondaryColour = this.storeDarkSecondaryColour;
  },
  computed: {
    ...mapState(["organisationStore"]),
    storeDarkPrimaryColour() {
      return this.organisationStore.darkPrimaryColour;
    },
    storeDarkSecondaryColour() {
      return this.organisationStore.darkSecondaryColour;
    },
  },
};
</script>