<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-img
          :src="
            previewImageURL == null ? organisationStore.logo : previewImageURL
          "
          contain
          max-height="50px"
        />
      </v-col>
      <v-col cols="6">
        <v-file-input
          ref="fileInput"
          v-model="files"
          :label="label"
          clearable
          dense
          outlined
          show-size
          truncate-length="50"
          @change="previewImageURLUpdate"
        />
      </v-col>
      <v-col cols="2">
        <ButtonPrimaryAction
          :color="primary"
          size="small"
          @click="submit"
          icon="mdi-upload"
          >upload</ButtonPrimaryAction
        >
      </v-col>
    </v-row>
  </div>
</template>

<script lang="js">
import {axiosApi, axiosUnsafeApi} from "@/modules/axios/axiosApi.js"
import {mapState} from "vuex";
import ButtonPrimaryAction from "../components/buttons/ButtonPrimaryAction.vue";

export default {
  name: "S3Upload",
  components: {ButtonPrimaryAction},
  props: {
    "dest": {
      type: String,
      required: true
    },
    "label": {
      type: String,
      required: true
    },
    "maxFileSize": {
      type: Number,
      require: false,
      default: 20 * (10 ^ 6) // 20 MB
    },
    "allowedTypes": {
      type: Array,
      required: false,
      default() {
        return ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml']
      }
    }
  },
  data() {
    return {
      fileTypes: this.allowedTypes,
      files: null,
      previewImageURL: null
    }
  },
  watch: {
    destValueInStore: {
      handler(value) {
        console.log(`image updated: ${value}`)
        this.previewImageURL = value;
      }
    }
  },
  methods: {
    previewImageURLUpdate() {
      if (this.files) {
        this.previewImageURL = URL.createObjectURL(this.files);
      }
    },
    submit() {
      if (!this.files) {
        return
      }
      if (this.files instanceof File) {
        this.uploadFile(this.files)
      } else if (this.files instanceof FileList) {
        throw TypeError("Only single file uploads are allowed");
        // this.files.forEach(file => this.uploadFile(file))
      }
    },
    async uploadFile(file) {
      const resp = (await axiosApi.post("/api/s3/prepare/", {
        "dest": this.dest,
        "filetype": file.type,
        "filename": file.name
      })).data;
      const url = resp["url"];
      const fields = resp["fields"];
      const formData = new FormData();
      for (let [key, value] of Object.entries(fields)) {
        value = String(value)
        formData.append(key, value);
      }
      formData.append("file", file);
      const s3Resp = await axiosUnsafeApi.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (s3Resp.status <= 300) {
        /*
        * TODO: Make this relevant to avatars. Emit bucket key to parent and upload to model from there.
        */
        const body = {}
        body[this.dest] = fields["key"]
        await axiosApi.put("/api/user/organisation/assets/", body, {
          params: {
            id: this.organisationStore.companyID
          }
        });
        await this.$store.dispatch('getCompanyAssets')
      }
    }
  },
  computed: {
    ...mapState(["organisationStore"]),
    destValueInStore() {
      return this.organisationStore[this.dest];
    }
  },
  created() {
    if ((this.organisationStore) && (this.organisationStore[this.dest])) {
      this.previewImageURL = this.destValueInStore;
    }
  }
}
</script>