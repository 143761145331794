import {axiosApi, axiosUnsafeApi} from 'src/modules/axios/axiosApi';
import {setClientID} from "src/modules/getAccessKeys.mjs";

export default {
	getUserProfile(context) {
		return new Promise((resolve, reject) => {
			axiosApi.get('/api/users/profile/', {}).then(response => {
				context.commit('updateUserStore', userProfileResponseToUpdateUserStoreObject(response));
				setClientID(response.data['uuid']);
				resolve();
			}).catch(err => {
				reject(err);
			})
		});
	},
	userCreate(context, userCredentials) {
		return new Promise((resolve, reject) => {
			axiosUnsafeApi.post('/api/users/register/', {
				username: userCredentials.username,
				email: userCredentials.email,
				password: userCredentials.password
			}).then((response) => {
				context.commit('updateAuthStore', {
					access: response.data.access,
					refresh: response.data.refresh,
					isAuthenticated: true
				});
				resolve();
			}).catch((err) => {
				reject(err);
			})
		});
	},
	updateUser: function (context, {
		email,
		firstName,
		lastName,
		dob,
		country,
		countryState,
		city,
		zip,
		address,
		darkMode
	}) {
		return new Promise((resolve, reject) => {
			axiosApi.post('/api/users/profile/', {
				email: email,
				username: email,
				first_name: firstName,
				last_name: lastName,
				dob: dob ? dob : undefined,
				country: country ? country : undefined,
				state: countryState ? countryState : undefined,
				city: city ? city : undefined,
				zip: zip ? zip : undefined,
				address: address ? address : undefined,
				dark_mode: darkMode
			}).then(response => {
				context.commit('updateUserStore', userProfileResponseToUpdateUserStoreObject(response));
				resolve();
			}).catch(err => {
				reject(err);
			});
		})
	},
	setDarkMode(context, darkMode) {
		context.commit('updateDarkMode', darkMode)
	}
}

function userProfileResponseToUpdateUserStoreObject(response) {
	return {
		username: response.data['username'],
		email: response.data['email'],
		firstName: response.data['first_name'],
		lastName: response.data['last_name'],
		uuid: response.data['uuid'],
		dob: response.data['dob'],
		country: response.data['country'],
		countryState: response.data['state'],
		city: response.data['city'],
		zip: response.data['zip'],
		address: response.data['address'],
		darkMode: response.data['dark_mode'],
		emailVerified: response.data['email_verified']
	}
}