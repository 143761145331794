<template>
    <v-icon
        v-if="(stream['recording'] !== '') && ([false, null].includes(uploading))"
        color="success"
    >
      mdi-check
    </v-icon>

    <v-icon
        v-else-if="(uploading === null) && (stream['recording'] === '')"
        color="error"
    >
      mdi-window-close
    </v-icon>

    <v-progress-circular
        v-else-if="uploading === true"
        :rotate="-90"
        :indeterminate="uploadProgress === 100"
        :value="uploadProgress"
        color="secondary"
    >
      {{ uploadProgress }}
    </v-progress-circular>

    <v-icon v-else>
      mdi-help
    </v-icon>
</template>

<script>
import {localDB} from "src/modules/localDB";

export default {
  name: "StreamUploadProgress",
  props: {
    uuid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      stream: null,
      uploading: null,
      uploadProgress: null
    }
  },
  watch: {
    streamInStore: {
      handler(value) {
        this.stream = value;
      }
    }
  },
  methods: {
    async setUploading() {
      if (localDB.isUploadProgressStoreReady() === false) return;
      this.uploading = await localDB.hasUploadStarted(this.uuid);
      if (this.uploading === true) {
        await this.startUploading();
      } else if (this.stream.recording === "") {
        window.addEventListener('streamUploadStarted', (e) => {
          if (e.detail.uuid === this.uuid) {
            this.setUploading();
          }
        });
        await this.$store.dispatch('fetchStream', {
          uuid: this.uuid
        });
        this.stream = this.streamInStore;
      }
    },
    startUploading() {
      const uploadingInterval = setInterval(async () => {
        this.uploadProgress = await localDB.uploadProgress(this.uuid);
        if (this.uploadProgress === 100) {
          clearInterval(uploadingInterval);
          // TODO Replace with websocket connection
          const uploadedInterval = setInterval(async () => {
            await this.$store.dispatch('fetchStream', {
              uuid: this.uuid
            });
            this.stream = this.$store.getters.getStream(this.uuid);
            if (this.stream['recording'] !== '') {
              clearInterval(uploadedInterval);
              this.uploading = null;
            }
          }, 1000);
        }
      },150);
    },
    initialise() {
      if (localDB.isUploadProgressStoreReady()) {
        this.setUploading();
      } else {
        window.addEventListener('uploadProgressStoreReadySuccess', () => {
          this.setUploading();
        })
      }
    }
  },
  mounted() {
    this.initialise();
  },
  created() {
    this.stream = this.streamInStore;
    this.initialise();
  },
  computed: {
    streamInStore() {
      return this.$store.getters.getStream(this.uuid);
    }
  }
}
</script>

<style scoped>

</style>