<template>
    <v-system-bar class="secondary black--text" app height="20">
         <slot name="center"></slot>
    </v-system-bar>
</template>

<script>
export default {
    name:"SystemBar"
}
</script>

