<template>
  <v-text-field
    v-model="streamName"
    :rules="rules"
    class="ma-2"
    dense
    flat
    outlined
    clearable
    clear-icon="mdi-close"
    hide-details
    label="Stream Name"
  />
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "StreamName",
  data() {
    return {
      streamName: null,
      rules: [(v) => (!!v && !!v.trim()) || "Stream name is required"],
    };
  },
  watch: {
    streamName() {
      this.nameChanged();
    },
  },
  methods: {
    nameChanged() {
      this.$store.dispatch("setStreamName", {
        name: this.streamName,
      });
    },
  },
  created() {
    if (this.activeStreamStore) {
      this.streamName = this.activeStreamStore.name;
    }
  },
  computed: {
    ...mapState(["activeStreamStore"]),
  },
};
</script>

<style scoped>
</style>