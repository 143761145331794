<template>
  <v-icon class="ma-2" color="primary" @click="changeTheme">
    mdi-theme-light-dark
  </v-icon>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "IconTheme",
  methods: {
    changeTheme: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$store.dispatch('setDarkMode', this.$vuetify.theme.dark);
    }
  },
  computed: {
    ...mapState(['userStore']),
  }
}
</script>
