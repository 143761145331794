<template>
  <div class="d-flex login-view secondary">
    <v-card
      elevation="24"
      class="pa-5 mx-auto my-auto d-flex flex-column"
      max-width="344"
    >
      <LogoContainer style="height: 88px" ImgOrIcon="icon" />
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5 font-weight-bold mb-1 text-center">
            Login
          </v-list-item-title>
          <v-list-item-subtitle class="mb-1 text-center"
            >Please provide your login credentials to access
            Iris</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="text-center"
          style="width: 100%"
        >
          <v-text-field
            @focus="resetIncorrectAuth"
            v-model="username"
            :rules="usernameRules"
            label="Email"
            required
            outlined
            dense
            prepend-inner-icon="mdi-email"
          ></v-text-field>

          <v-text-field
            @focus="resetIncorrectAuth"
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passwordRules"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            @click:append="show1 = !show1"
            outlined
            dense
            prepend-inner-icon="mdi-key"
          ></v-text-field>

          <v-btn
            :disabled="!valid"
            elevation="0"
            color="primary"
            class="mb-4 text-capitalize"
            @click="login"
          >
            Login
          </v-btn>
          <v-alert v-if="incorrectAuth" dense type="error">
            Invalid credentials
          </v-alert>

          <v-row>
            <v-col cols="6">
              <v-btn
                text
                small
                class="text-center text-capitalize"
                @click="createAccount"
              >
                Create Account
              </v-btn>
            </v-col>
            <v-col cols="6">
              <dialog-forgot-password :email-preset="username" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import DialogForgotPassword from "@/components/dialogs/DialogForgotPassword.vue";
import LogoContainer from "@/components/logos/LogoContainer.vue";
import {initDB} from "@/modules/localDB";

export default {
  name: "LoginView",
  components: { DialogForgotPassword, LogoContainer },
  data: () => ({
    show1: false,
    valid: true,
    passwordRules: [(v) => !!v || "Password is required"],
    usernameRules: [(v) => !!v || "Email is required"],
    username: "",
    password: "",
    incorrectAuth: false,
    correctAuth: false,
  }),

  metaInfo: {
    titleTemplate: "%s | Login",
  },

  methods: {
    login() {
      this.$store
        .dispatch("userLogin", {
          username: this.username,
          password: this.password,
        })
        .then(async () => {
          await this.getUserProfile();
          initDB();
          this.$router.push({ name: "JoinStreamView" }).then();
        })
        .catch((err) => {
          this.incorrectAuth = true;
          console.log(err);
        });
    },
    resetIncorrectAuth() {
      this.incorrectAuth = false;
    },
    async getUserProfile() {
      this.$store.dispatch("getAllPermissions").then();
      if (
        this.organisationStore &&
        this.organisationStore.companyID !== "undefined"
      ) {
        await this.$store.dispatch("getCompanyTheme");
      }
      await this.$store.dispatch("getUserProfile");
    },
    createAccount() {
      this.$router.push({ name: "CreateUserView" });
    },
    forgotPassword() {},
  },
};
</script>

<style scoped lang="scss">
.login-view {
  width: 100%;
}

.logo > img {
  height: 88px;
}
</style>