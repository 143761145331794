<template>
  <v-dialog v-model="showDialog" max-width="45%">
    <template v-slot:activator="{ on }">
      <ButtonPrimaryAction
        size="small"
        class="mx-2 mb-3"
        color="primary"
        v-on="on"
        @click="reset"
        icon="mdi-content-save"
        >{{ team_id ? "Manage team" : "Add team" }}</ButtonPrimaryAction
      >
    </template>

    <v-card width="100%">
      <v-card-title>
        <span class="text-h5">Manage team</span>
      </v-card-title>

      <v-card-text>
        <v-row justify="space-between">
          <v-card-subtitle>
            <v-text-field v-model="teamName" label="Team name" />
          </v-card-subtitle>
          <v-spacer />
          <v-btn
            small
            outlined
            color="primary"
            @click="addMembersPanel = !addMembersPanel"
            >{{ addMembersPanel ? "Done" : "Add members"
            }}<v-icon>{{
              addMembersPanel ? "mdi-check" : "mdi-plus"
            }}</v-icon></v-btn
          >
        </v-row>
        <v-row v-if="addMembersPanel" class="ma-3">
          <TeamAddMemberPanel :team_id="team_id" @memberAdded="memberAdded" />
        </v-row>
        <v-row align="center" justify="center" width="100%">
          <v-data-table
            :headers="headers"
            :items="members"
            hide-default-footer
            width="100%"
            dense
          >
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.role="{ item }">
              <SelectTeamRole
                :email="item['email']"
                :role="item['role']"
                @memberUpdated="memberEdited"
              />
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="memberDeleted(item)"> mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <ButtonPrimaryAction
          size="small"
          color="red"
          @click="cancel"
          icon="mdi-close"
          >Close</ButtonPrimaryAction
        >
        <v-spacer /><v-divider vertical /><v-spacer />
        <ButtonPrimaryAction
          size="small"
          color="primary"
          @click="save"
          :disabled="!(teamName && teamName.length >= 2)"
          icon="mdi-content-save"
          >Save</ButtonPrimaryAction
        >
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import SelectTeamRole from "@/components/selects/SelectTeamRole.vue";
import TeamAddMemberPanel from "@/components/teams/TeamAddMemberPanel.vue";
import ButtonPrimaryAction from "../buttons/ButtonPrimaryAction.vue";

export default {
  name: "DialogTeamManagement",
  components: { TeamAddMemberPanel, SelectTeamRole, ButtonPrimaryAction },
  props: {
    team_id: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      showDialog: false,
      headers: [
        {
          text: "Name",
          value: "name",
          align: "left",
        },
        {
          text: "Email",
          value: "email",
          align: "left",
        },
        {
          text: "Role",
          value: "role",
          align: "right",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "right",
        },
      ],
      teamName: null,
      members: [],
      addMembersPanel: false,
      newMembers: [],
      editedMembers: [],
      deletedMembers: [],
    };
  },
  methods: {
    async getTeamMembers() {
      this.loadingData = true;
      this.members = this.$store.getters.getTeamMembers(this.team_id);
      if (!this.members) {
        await this.$store.dispatch("fetchTeamMembers", this.team_id);
        this.members = this.$store.getters.getTeamMembers(this.team_id);
      }
      this.loadingData = false;
    },
    cancel() {
      this.showDialog = false;
    },
    async save() {
      const team = {
        id: this.team_id,
        name: this.teamName,
      };
      if (this.team_id) {
        if (this.teamName !== this.team.name) {
          await this.$store.dispatch("cuTeam", team);
        }
        if (this.deletedMembers.length > 0) {
          await this.$store.dispatch("removeTeamMembers", {
            team_id: this.team_id,
            members: this.deletedMembers,
          });
        }
        if (this.editedMembers.length > 0) {
          await this.$store.dispatch("editTeamMembers", {
            team_id: this.team_id,
            members: this.editedMembers,
          });
        }
        if (this.newMembers.length > 0) {
          await this.$store.dispatch("addTeamMembers", {
            team_id: this.team_id,
            members: this.newMembers,
          });
        }
        this.$emit("updatingTeam");
      } else {
        team["members"] = this.newMembers;
        await this.$store.dispatch("cuTeam", team);
        this.$emit("createdTeam");
      }
      this.showDialog = false;
    },
    reset() {
      /*
       * TODO: The name loads incorrectly when the dialog is called after an edit.
       * */
      if (this.team_id) {
        this.teamName = this.team.name;
      }
    },
    memberDeleted(member) {
      this.deletedMembers.push(member["email"]);
      const idx = this.members.findIndex((x) => x.email === member.email);
      this.members.splice(idx, 1);
    },
    memberEdited(member) {
      console.log(member);
      this.editedMembers.push(member);
      console.log();
      const idx = this.members.findIndex((x) => x.email === member.email);
      this.members[idx]["role"] = this.$store.getters.getRoleNameFromNumber(
        member.role
      );
    },
    memberAdded(member) {
      this.newMembers.push(member);
      this.members.push({
        name: "",
        email: member.email,
        role: this.$store.getters.getRoleNameFromNumber(member.role),
      });
    },
  },
  watch: {
    team(value) {
      if (value) {
        this.teamName = value.name;
        this.getTeamMembers();
      }
    },
  },
  mounted() {
    if (this.team_id) {
      this.teamName = this.team.name;
      this.getTeamMembers();
    }
  },
  computed: {
    ...mapState(["teamStore"]),
    team() {
      return this.$store.getters.getTeam(this.team_id);
    },
  },
};
</script>