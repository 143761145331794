<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-icon small v-if="permissionValue == 2" :color=editPermission.colour v-bind="attrs" v-on="on">
                {{ editPermission['icon'] }}
            </v-icon>
            <v-icon small v-else-if="permissionValue == 1" :color=viewPermission.colour v-bind="attrs" v-on="on">
                {{ viewPermission['icon'] }}
            </v-icon>
            <v-icon small v-else :color=noPermission.colour v-bind="attrs" v-on="on">
                {{ noPermission['icon'] }}
            </v-icon>
        </template>
        <span>{{ toolTip }}</span>
    </v-tooltip>
</template>
  
<script>
export default {
    name: "IconMemberPermission",
    props: {
        permissionValue: {
            type: Number,
            required: true,
            default: 0
        }
    },
    data() {
        return {
            toolTip: null,
            editPermission: {
                'icon': "mdi-application-edit-outline",
                'colour': "secondary darken-2"
            },
            viewPermission: {
                'icon': "mdi-glasses",
                'colour': "secondary darken-2"
            },
            noPermission: {
                'icon': "mdi-close-octagon-outline",
                'colour': "secondary darken-2"
            }
        }
    },
    methods: {
        changePermission() {

        },
        setToolTip() {
            const permissionNumberValue = Number(this.permissionValue)
            switch (permissionNumberValue) {
                case 1:
                    this.toolTip = "Has view permissions."
                    break;
                case 2:
                    this.toolTip = "Has edit rights."
                    break;
                default:
                    this.toolTip = "Does not have this permission."
                    break;
            }
        }
    },
    created() {
        this.setToolTip();
    },
}
</script>