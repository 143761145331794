<template>
    <v-bottom-sheet
      v-model="globalComponentStore.menuMobileState"
    >
      <v-sheet height="400px">
       <slot name="start"></slot>
       <slot name="center"></slot>
       <v-divider class="secondary"></v-divider>
       <slot name="end"></slot> 
      </v-sheet>
      
    </v-bottom-sheet>
</template>

<script>
import { mapState } from "vuex";

export default {
    name:"DrawerNavigationMobile",
    computed: {
    ...mapState([
        'globalComponentStore',
    ])
    }
}
</script>
