import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta'
import LoginView from './views/LoginView';
import CreateUserView from './views/CreateUserView';
import AboutView from './views/AboutView';
import StartStreamView from './views/StartStreamView';
import JoinStreamView from './views/JoinStreamView';
import NotFoundView from './views/NotFoundView';
import NodeMapView from './views/NodeMapView';
import ViewEmailAction from './views/ViewEmailAction.vue';
import SettingsView from './views/SettingsView';
import SupportView from './views/SupportView';
import ViewStreamHistory from './views/ViewStreamHistory.vue';
import ViewSettingsOrganisation from "./views/ViewSettingsOrganisation.vue";
import ViewSettingsUser from "./views/ViewSettingsUser.vue";
import ViewUnauthorisedViewing from "./views/ViewUnauthorisedViewing.vue";

Vue.use(VueRouter)
Vue.use(VueMeta)

export default new VueRouter({
    mode: 'history', //process.env.IS_ELECTRON === "true" ? 'hash' : 'history',
    base: process.env.VUE_BASE_URL,
    routes: [
        {
            path: '/',
            name: 'AboutView',
            component: AboutView,
            meta: {
                requiresLogin: true,
            }
        }, {
            path: '/start-stream',
            name: 'StartStreamView',
            component: StartStreamView,
            meta: {
                requiresLogin: true,
            }
        }, {
            path: '/join-stream',
            name: 'JoinStreamView',
            component: JoinStreamView,
            meta: {
                requiresLogin: true,
            }
        }, {
            path: '/login',
            name: 'LoginView',
            component: LoginView,
        }, {
            path: '*',
            name: 'Not Found',
            component: NotFoundView
        }, {
            path: '/node-map',
            name: 'NodeMapView',
            component: NodeMapView,
            meta: {
                requiresLogin: true,
            }
        }, {
            path: '/user/account/create',
            name: 'CreateUserView',
            component: CreateUserView,
        }, {
            path: '/emails/action/:uid/',
            name: 'ViewEmailAction',
            component: ViewEmailAction
        }, {
            path: '/streams',
            name: 'ViewStreamHistory',
            component: ViewStreamHistory,
            meta: {
                requiresLogin: true,
            }
        }, {
            path: '/settings',
            name: 'SettingsView',
            component: SettingsView,
            meta: {
                requiresLogin: true,
            }
        }, {
            path: '/settings-organisation/',
            name: 'ViewSettingsOrganisation',
            component: ViewSettingsOrganisation,
            meta: {
                requiresLogin: true,
            }
        }, {
            path: '/settings-user/',
            name: 'ViewSettingsUser',
            component: ViewSettingsUser,
            meta: {
                requiresLogin: true,
            }
        }, {
            path: '/support',
            name: 'SupportView',
            component: SupportView,
            meta: {
                requiresLogin: true,
            }
        }, {
            path: '/unauthorised-viewer/:uuid',
            name: 'ViewUnauthorisedViewing',
            component: ViewUnauthorisedViewing
        }
    ],
})