<template>
  <v-btn
      v-if="render"
      icon
      @click="getLink">
    <v-icon color="primary">mdi-download</v-icon>
  </v-btn>
</template>

<script>
import {axiosApi} from "@/modules/axios/axiosApi";
import {mapState} from "vuex";

function downloadURI(uri) {
  let link = document.createElement("a");
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default {
  name: "StreamDownloadButton",
  props: {
    stream: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      render: false
    }
  },
  watch: {
    streamInStore: {
      handler(value) {
        this.render = value !== '';
      }
    }
  },
  methods: {
    async getLink() {
      const resp = await axiosApi.get(`api/streams/${this.stream["uuid"]}/recording/`);
      if (resp.status === 200) {
        downloadURI(resp.data['recording']);
      }
    }
  },
  mounted() {
    this.render = this.streamInStore['recording'] !== '';
  },
  created() {
    this.render = this.streamInStore['recording'] !== '';
  },
  computed: {
    ...mapState(['streamStore']),
    streamInStore() {
      return this.$store.getters.getStream(this.stream.uuid);
    }
  }
}
</script>

<style scoped>

</style>