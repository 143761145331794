<template>
  <div class="content-div">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ContentDiv",
};
</script>

<style scoped>
.content-div {
  width: 100%;
  padding: 20px;
  text-align: center;
}
</style>