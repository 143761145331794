/*
* type City = string
*
* interface State {
*   iso2: string
*   cities: City[]
* }
*
* interface States {
*   [key: string]: State
* }
*
* interface Country {
*   iso2: string
*   states: States
* }
*
* interface Countries {
*   [key: string]: Country[]
* }
* */
export default {
	countries: [null, undefined, 'undefined', 'null'].includes(sessionStorage.getItem('countries')) ? {} : (JSON.parse(sessionStorage.getItem('countries')))
}