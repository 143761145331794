export default {
    setTeamsStore(state, { teams, roles }) {
        state.teams = teams;
        state.roles = roles;
    },
    setAllTeams(state, { teams }) {
        teams.sort();
        state.teams = teams;
    },
    setTeam(state, team) {
        const teamIdx = state.teams.findIndex(x => x.id === team.id);
        state.teams[teamIdx] = team;
    },
    setTeamMembers(state, { team_id, members }) {
        const teamIdx = state.teams.findIndex(x => Number(x.id) === Number(team_id));
        const team = state.teams[teamIdx];
        team["members"] = members;
        state.teams[teamIdx] = team;
    },
    setRoles(state, roles) {
        state.roles = roles;
    }
}