<template>
  <v-container>
    <v-row dark>
      <v-col cols="6" :lg="6" :md="6" :sm="6">
        <div class="colour_picker" id="light-primary-picker">
          <label for="lightPrimaryColorPicker">Light Primary color</label>
          <v-color-picker
            light
            v-model="lightPrimaryColour"
            name="lightPrimaryColorPicker"
            class="mx-auto"
          >
          </v-color-picker>
        </div>
      </v-col>
      <v-col cols="6" :lg="6" :md="6" :sm="6">
        <div class="colour_picker" id="light-secondary-picker">
          <label for="lightSecondaryColorPicker">Light Secondary color</label>
          <v-color-picker
            light
            v-model="lightSecondaryColour"
            name="lightSecondaryColorPicker"
            class="mx-auto"
          >
          </v-color-picker>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <ButtonSecondaryAction
        class="mx-3 mb-3"
        size="small"
        @custom-click="reset"
        icon="mdi-reload"
        >Reset</ButtonSecondaryAction
      >
      <ButtonPrimaryAction
        class="mx-3 mb-3"
        size="small"
        @custom-click="submit"
        icon="mdi-content-save"
        >Save</ButtonPrimaryAction
      >
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { DefaultColours } from "@/store/modules/organisationStore/state";
import ButtonPrimaryAction from "../buttons/ButtonPrimaryAction.vue";
import ButtonSecondaryAction from "../buttons/ButtonSecondaryAction.vue";

export default {
  name: "OrganisationThemingLight",
  components: { ButtonPrimaryAction, ButtonSecondaryAction },
  data() {
    return {
      valid: true,
      lightPrimaryColour: "",
      lightSecondaryColour: "",
      colorPermissions: {
        viewable: false,
        editable: false,
      },
    };
  },
  watch: {
    storeLightPrimaryColour(value) {
      this.lightPrimaryColour = value;
    },
    storeLightSecondaryColour(value) {
      this.lightSecondaryColour = value;
    },
  },
  methods: {
    submit() {
      this.$store.dispatch("setCompanyDetails", {
        light_primary_colour: this.lightPrimaryColour.slice(0, 7),
        light_secondary_colour: this.lightSecondaryColour.slice(0, 7),
      });
    },
    reset() {
      this.$store.dispatch("setCompanyDetails", {
        light_primary_colour: DefaultColours.lightPrimary,
        light_secondary_colour: DefaultColours.lightSecondary,
      });
    },
  },
  created() {
    this.lightPrimaryColour = this.storeLightPrimaryColour;
    this.lightSecondaryColour = this.storeLightSecondaryColour;
  },
  computed: {
    ...mapState(["organisationStore"]),
    storeLightPrimaryColour() {
      return this.organisationStore.lightPrimaryColour;
    },
    storeLightSecondaryColour() {
      return this.organisationStore.lightSecondaryColour;
    },
  },
};
</script>

<style>
.colour_btn {
  padding: 20px;
  margin: 20px auto;
}
</style>