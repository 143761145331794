<template>
  <v-container>
    <v-row class="d-flex flex-nowrap align-center">
      <v-combobox
        :disabled="!audio"
        id="audioSource"
        v-model="audioSource"
        :items="audioSources"
        :return-object="true"
        dense
        item-text="label"
        item-value="label"
        label="Audio source"
        outlined
      >
      </v-combobox>
      <v-switch
        v-model="audio"
        label="Audio"
        color="secondary"
        class="mx-2 my-0 pa-0"
      >
      </v-switch>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AudioController",
  props: {
    audioFail: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      audio: false,
      audioSource: null,
      audioSources: [],
      hasAudioPermission: false,
    };
  },
  watch: {
    audioSource: {
      async handler() {
        if (this.audio === false) {
          this.audioSource = null;
        }
        if (this.audioSource != null) {
          if (
            !("label" in this.audioSource) ||
            this.audioSource.label === "" ||
            this.audioSource.label === "No label available"
          ) {
            await this.loadAudioDevices();
            this.audioSource = this.audioSources[0];
          }
        }
        this.$emit("audioSourceChange", this.audioSource);
      },
    },
    audio: {
      async handler() {
        if (this.audio && !this.hasAudioPermission) {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });
          stream.getTracks().forEach((track) => {
            track.stop();
          });
          this.hasAudioPermission = true;
        }
        if (this.audioSources.length === 0) {
          await this.loadAudioDevices();
        }
        if (this.audio === false) {
          this.audioSource = null;
        } else if (this.audioSource == null) {
          this.audioSource = this.audioSources[0];
        }
      },
    },
    audioFail: {
      handler() {
        if (this.audioFail) {
          this.audio = false;
        }
      },
    },
  },
  methods: {
    async loadAudioDevices() {
      this.audioSources = [];
      await navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices.forEach((device) => {
          if (device.kind === "audioinput") {
            this.audioSources.push({
              id: device.deviceId,
              label: device.label || "No label available",
            });
          }
        });
      });
    },
  },
  async created() {
    await this.loadAudioDevices();
    const audioSourcesIntervalID = setInterval(() => {
      this.audioSources.filter((item) => {
        return !(
          item.id.length === 0 &&
          item.label.length === 0 &&
          typeof item != "object"
        );
      });
      if (this.audioSources.length < 1) {
        this.loadVideoDevices();
      } else {
        this.audioSource = this.audioSources[0];
        clearInterval(audioSourcesIntervalID);
      }
    }, 500);
  },
};
</script>
