<template>
  <ContentDiv>
    <PageTitle>Support</PageTitle>
    <p>To increase situational awareness, we have developed a streaming solution, ESP (extra sensory perception), to
      consume, analyse and distribute payload data in real-time.</p>
    <PageTitleDivider />
  </ContentDiv>
</template>

<script>
import ContentDiv from "../components/ContentDiv.vue";
import PageTitle from "../components/PageTitle.vue";
import PageTitleDivider from "../components/dividers/PageTitleDivider.vue"

export default {
  name: "AboutView",
  components: {
    ContentDiv,
    PageTitle,
    PageTitleDivider
  },
  mounted() {
  }
};

</script>
