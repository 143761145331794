import { axiosApi } from "@/modules/axios/axiosApi";

export default {
    async getAllTeams(context) {
        const companyID = context.rootState.organisationStore.companyID;
        const resp = await axiosApi.get('api/teams/manage/', {
            params: {
                id: companyID
            }
        });
        context.commit('setAllTeams', { teams: resp.data });
    },
    async cuTeam(context, team) {
        const companyID = context.rootState.organisationStore.companyID;
        let url = (team.id) ? `api/teams/${team.id}/manage/` : 'api/teams/manage/';
        const resp = await axiosApi.post(
            url,
            team, {
            params: {
                id: companyID
            }
        });
        context.commit('setTeam', resp.data);
        await context.dispatch('fetchTeamMembers', resp.data.id);
    },
    async fetchTeamMembers(context, team_id) {
        const companyID = context.rootState.organisationStore.companyID;
        const resp = await axiosApi.get(`api/teams/${team_id}/members/`, {
            params: {
                id: companyID
            }
        });
        let members = []
        resp.data.forEach(member => {
            let name = `${member['first_name']} ${member['last_name']}`.trim();
            if (name === "") {
                name = member['email'];
            }
            members.push({
                id: `${team_id}_${member['email']}`,
                name: name,
                email: member['email'],
                role: member['role']
            });
        });
        context.commit('setTeamMembers', {
            team_id: team_id,
            members: members
        });
    },
    async addTeamMembers(context, { team_id, members }) {
        const companyID = context.rootState.organisationStore.companyID;
        const resp = await axiosApi.put(`api/teams/${team_id}/members/`,
            { members: members },
            {
                params: { id: companyID }
            });
        console.log(resp)
        await context.dispatch('fetchTeamMembers', team_id);
    },
    async editTeamMembers(context, { team_id, members }) {
        await context.dispatch('addTeamMembers', { team_id, members });
    },
    async removeTeamMembers(context, { team_id, members }) {
        const companyID = context.rootState.organisationStore.companyID;
        const resp = await axiosApi.delete(`api/teams/${team_id}/members/`, {
            data: { members: members },
            params: { id: companyID }
        });
        console.log(resp);
        await context.dispatch('fetchTeamMembers', team_id);
    },
    async getRoles(context) {
        const resp = await axiosApi.get('api/teams/roles/');
        const objRoles = resp.data['roles'];
        const arrRoles = Array(objRoles.length);
        Object.entries(objRoles).forEach(entry => {
            const [key, value] = entry;
            arrRoles[value] = key;
        });
        context.commit('setRoles', arrRoles);
    },
    async deleteTeam(context, team_id) {
        const companyID = context.rootState.organisationStore.companyID;
        await axiosApi.delete(`api/teams/${team_id}/manage/`, {
            params: { id: companyID }
        });
    },

    //Action Gets All Teams based on organisation ID and then fetches and sets all members for all teams.
    //Once fetched and set state is then mutated. - StevenK
    async getAllTeamsAndMembers(context) {

        //Set company ID context
        const companyID = context.rootState.organisationStore.companyID;

        //get all teams based on current organisation
        await axiosApi.get('api/teams/manage/', {
            params: {
                id: companyID
            }
        }).then(t => {
            let teams = t.data
            var updatedTeams = []

            //Map the response Array to a new array that will be used to populate state variables
            teams.map(async m => {
                let resp = await axiosApi.get(`api/teams/${m.id}/members/`, {
                    params: {
                        id: companyID
                    }
                })
                updatedTeams.push(m)
                return m.members = resp.data
            })
            context.commit('setAllTeams', { teams: updatedTeams });
        })
    }

}