export default {
    install(Vue, options) {
        const { url, store } = options;

        const socket = new WebSocket(url);

        socket.onopen = () => {
            console.log('WebSocket connection opened');
        };

        socket.onerror = (error) => {
            console.error('WebSocket encountered an error', error);
        };

        socket.onmessage = (event) => {
            store.dispatch('setWebsocketMessage', JSON.parse(event.data));
        };

        socket.onclose = () => {
            console.log('WebSocket connection closed');
        };

        Vue.prototype.$socket = socket;
    },
};
