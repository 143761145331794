<template>
  <v-select
    :menu-props="{ auto: false, maxWidth: 250 }"
    style="width: 250px"
    class="pa-2 ma-3"
    label="Select organisation"
    hide-details
    minWidth="250"
    maxWidth="250"
    outlined
    dense
    v-model="selected"
    :items="organisations"
    item-text="name"
    return-object
    v-on:change="updateTheme"
  >

    <template v-slot:selection="{item}">
      {{item.name}}
    </template>

    <template v-slot:item="{item}">
      <v-list-item-content>
        <v-list-item-title v-text="item.name"/>
        <v-list-item-subtitle v-text="item.id"/>
      </v-list-item-content>
    </template>

    <template v-slot:append-item>
      <v-divider class="mb-2"></v-divider>
      <v-list-item class="" two-line @click="newOrganisation">
        <v-icon class="ma-2"> mdi-office-building </v-icon>
        <v-list-item-content max-width="10px">
          <v-list-item-title> New organisation </v-list-item-title>
          <v-list-item-subtitle> Create and Customise </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import { axiosApi } from "src/modules/axios/axiosApi";
import { mapState } from "vuex";

export default {
  name: "OrganisationSelect",
  data() {
    return {
      selected: null,
      organisations: [],
      loading: false,
    };
  },
  methods: {
    updateTheme() {
      this.$store.dispatch("setCompanyID", Number(this.selected.id) || null);
      this.$store.dispatch("getCompanyTheme");
    },
    newOrganisation() {
      this.$store.commit("newOrganisationStore", {}); // clear organisation store
      if (this.$route.name === "SettingsView") {
        this.$router.go(1);
      } else {
        this.$router.push({ name: "SettingsView" });
      }
    },
    async getUserOrganisations() {
      this.loading = true;
      const resp = await axiosApi.get("api/user/organisations/");
      this.organisations = JSON.parse(JSON.stringify(resp.data));
      if (this.organisations.length === 1) {
        this.selected = this.organisations[0];
        this.updateTheme();
      } else if (Number.isInteger(Number(this.organisationStore.companyID))) {
        this.selected =
          this.organisations.filter((organisation) => {
            return (
              Number(organisation.id) ===
              Number(this.organisationStore.companyID)
            );
          })[0] || null;
        this.updateTheme();
      }
      this.loading = false;
    },
  },
  created() {
    this.getUserOrganisations();
  },
  computed: {
    ...mapState(["organisationStore", "userStore"]),
  },
};
</script>

<style scoped lang="scss">
</style>