/*
* Updates the store and DB with new user data
* */

import { axiosApi } from 'src/modules/axios/axiosApi.js';
import { isIdDefined } from "./helpers";

export default {
    setCompanyID(context, id) {
        if (!isIdDefined(id)) id = null;
        context.commit('setOrganisationID', id);
        if (id) {
            context.dispatch('getCompanyInfo').then();
            context.dispatch('getCompanyAssets').then();
            context.dispatch('getMemberPermissions').then();
        }
    },
    async setCompanyDetails(context, details) {
        let config = {}
        if (isIdDefined(context.state.companyID)) {
            config = {
                params: { id: context.state.companyID }
            }
        }
        const resp = await axiosApi.post(
            "/api/user/organisation/details/",
            details, config
        ).catch((err) => {
            console.log(err.response)
            console.log(err)
        });
        if (resp.status === 200) {
            context.commit('setOrganisationDetail', {
                companyID: resp.data['id'],
                companyName: resp.data['name'],
                darkPrimaryColour: resp.data['dark_primary_colour'],
                darkSecondaryColour: resp.data['dark_secondary_colour'],
                lightPrimaryColour: resp.data['light_primary_colour'],
                lightSecondaryColour: resp.data['light_secondary_colour'],
            })
        }
    }
}