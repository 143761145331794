<template>
  <ContentDiv>
    <PageTitle>Settings</PageTitle>
    <p>
      Configure various settings relating to your Organisation/s, Team Members
      or your Profile
    </p>
    <PageTitleDivider />
      <template  v-if="
          organisationStore.companyID === 'undefined' ||
          organisationStore.companyID == null"
      >
        <PageSubtitle class="my-5">Create an organisation</PageSubtitle>
        <OrganisationGeneral class="my-10"/>
        <PageTitleDivider/>
        <PageSubtitle class="my-5">My Profile</PageSubtitle>
        <UserProfile class="my-10"/>
      </template>
      <v-expansion-panels
        focusable
        flat
        class="mt-5"
        v-else
      >
        <v-expansion-panel v-for="x in items" :key="x.title">
          <v-expansion-panel-header disable-icon-rotate>
            <template v-slot:actions>
              <v-icon>
                {{ x.icon }}
              </v-icon>
            </template>
            {{ x.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <component :is="x.component"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
  </ContentDiv>
</template>

<script>
import ContentDiv from "@/components/ContentDiv.vue";
import ContentSettings from "@/components/content/ContentSettings.vue";
import OrganisationGeneral from "@/components/organistions/OrganisationGeneral.vue";
import OrganisationMembers from "@/components/organistions/OrganisationMembers.vue";
import OrganisationTheming from "@/components/organistions/OrganisationTheming.vue";
import OrganisationThemingLogo from "@/components/organistions/OrganisationThemeingLogo.vue";
import OrganisationThemingLight from "@/components/organistions/OrganisationThemeingLight.vue";
import OrganisationThemingDark from "@/components/organistions/OrganisationThemeingDark.vue";
import PageTitle from "@/components/PageTitle.vue";
import PageTitleDivider from "@/components/dividers/PageTitleDivider.vue";
import { mapState } from "vuex";
import TeamManagement from "@/components/teams/TeamManagement.vue";
import UserProfile from "@/components/users/UserProfile.vue";
import PageSubtitle from "@/components/PageSubtitle.vue";
import OrganisationSettings from "@/components/organistions/OrganisationSettings.vue";
import UserSettings from "@/components/users/UserSettings.vue";

export default {
  name: "OrganisationView",
  components: {
    PageSubtitle,
    UserProfile,
    ContentDiv,
    ContentSettings,
    OrganisationGeneral,
    OrganisationMembers,
    OrganisationTheming,
    OrganisationThemingLogo,
    OrganisationThemingLight,
    OrganisationThemingDark,
    PageTitle,
    PageTitleDivider,
    TeamManagement,
  },
  data: () => ({
    items: [
      {
        title: "Manage Organisations",
        icon: "mdi-office-building-cog",
        component: OrganisationSettings
      },
      {
        title: "My Profile",
        icon: "mdi-account",
        component: UserSettings,
      },
    ],
  }),
  methods: {},
  async created() {
    this.$store.dispatch("getAllPermissions");
  },
  computed: {
    ...mapState(["organisationStore"]),
  },
};
</script>

<style scoped lang="scss">
.v-tab {
  letter-spacing: 0;
  text-transform: none;
  font-weight: 400;
}

.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.active-border {
  border: 1px solid grey;
}
</style>
