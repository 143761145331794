<template>
  <ContentSettings
      :subtitle="subtitle"
      :description="description"
      class="pb-10"
  >
    <template v-slot:center>
      <UserProfile/>
    </template>
  </ContentSettings>
</template>

<script>
import UserProfile from "@/components/users/UserProfile.vue";
import ContentSettings from "@/components/content/ContentSettings.vue";

export default {
  name: "UserSettings",
  components: {ContentSettings, UserProfile},
  data() { return {
    subtitle: "Personal Information",
    description: "Use this tab to update your personal information"
  }}
}
</script>

<style scoped>

</style>