<template>
  <div class="d-flex create-user-view secondary">
    <v-card
      elevation="24"
      class="pa-5 mx-auto my-auto d-flex flex-column"
      max-width="384"
    >
      <LogoContainer height="80px" ImgOrIcon="icon" />
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5 font-weight-bold mb-1 text-center">
            Create Account
          </v-list-item-title>
          <v-list-item-subtitle class="mb-1 text-center"
            >We're excited to see you here! <br />
            Create an account to access Iris
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="text-center"
          style="width: 100%"
        >
          <v-text-field
            @focus="resetIncorrectAuth"
            v-model="username"
            :rules="emailRules"
            label="Email"
            required
            outlined
            dense
            prepend-inner-icon="mdi-email"
          ></v-text-field>

          <v-text-field
            @focus="resetIncorrectAuth"
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show1 = !show1"
            :rules="passwordRules"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            outlined
            dense
            prepend-inner-icon="mdi-key-outline"
          ></v-text-field>

          <v-text-field
            @focus="resetIncorrectAuth"
            v-model="passwordConfirm"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show1 = !show1"
            :rules="[passwordMatching]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Confirm Password"
            outlined
            dense
            prepend-inner-icon="mdi-key"
          ></v-text-field>

          <v-btn
            :disabled="!valid"
            elevation="0"
            color="primary"
            class="mb-4 text-capitalize"
            @click="createAccount"
            @keyup.enter="keyEnter"
          >
            Create account
          </v-btn>
          <v-alert v-if="incorrectAuth" dense type="error" id="alert">
            {{ incorrectAuthMsg }}
          </v-alert>

          <div>
            <v-btn
              text
              small
              class="ma-2 text-center text-capitalize"
              @click="login"
            >
              Already have an account
            </v-btn>
          </div>
        </v-form>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import LogoContainer from "@/components/logos/LogoContainer";

export default {
  name: "CreateUserView",
  components: { LogoContainer },
  data: () => ({
    show1: false,
    valid: true,
    passwordRules: [(v) => !!v || "Password is required"],
    emailRules: [
      (v) => !!v || "Email is required",
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail must be valid",
    ],
    username: "",
    email: "",
    password: "",
    passwordConfirm: "",
    incorrectAuth: false,
    incorrectAuthMsg: "",
    correctAuth: false,
  }),

  metaInfo: {
    titleTemplate: "%s | Create user",
  },
  methods: {
    passwordMatching() {
      return this.password === this.passwordConfirm
        ? true
        : "Password does not match";
    },
    async createAccount() {
      this.$store
        .dispatch("userCreate", {
          username: this.username,
          email: this.username,
          password: this.password,
        })
        .then(async () => {
          this.getUserProfile();
          this.$router.push({ name: "JoinStreamView" }).then();
        })
        .catch((err) => {
          this.incorrectAuth = true;
          const resp = JSON.parse(err.response.request.response);
          const invalid = resp["valid"];
          if (Object.keys(invalid).length > 0) {
            this.incorrectAuthMsg = `The following fields are invalid:\n`;
            Object.values(invalid).forEach((field) => {
              this.incorrectAuthMsg += `\t \u2022 ${field}`;
            });
          }

          const non_unique = resp["unique"];
          if (Object.keys(non_unique).length > 0) {
            this.incorrectAuthMsg += "\n";
            this.incorrectAuthMsg += "The following fields are not unique:\n";
            Object.values(non_unique).forEach((field) => {
              this.incorrectAuthMsg += `\t \u2022 ${field}`;
            });
          }
        });
    },
    resetIncorrectAuth() {
      this.incorrectAuth = false;
      this.incorrectAuthMsg = "";
    },
    login() {
      this.$router.push({ name: "LoginView" });
    },
    keyEnter() {
      if (this.valid) {
        this.createAccount();
      }
    },
    getUserProfile() {
      this.$store.dispatch("getUserProfile");
    },
  },
};
</script>

<style scoped lang="scss">
.create-user-view {
  width: 100%;
}

#alert {
  white-space: break-spaces;
  text-align: left;
}
</style>