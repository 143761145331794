<template>
  <v-card class="d-flex flex-column">
    <v-toolbar color="secondary" dense flat>
      <v-toolbar-title>Stream</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closeDialog')">
        <v-icon> mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-row class="d-flex flex-nowrap flex-row ma-0">
      <v-col
        v-if="streamObj['recording'] !== ''"
        class="pa-0"
        cols="12"
        lg="7"
        md="12"
        sm="12"
      >
        <StreamRecording :stream="streamObj" />
      </v-col>
      <v-col class="overflow-y-visible">
        <v-card-title>
          {{ streamObj["name"] }}
        </v-card-title>
        <v-card-subtitle>
          {{ streamObj["owner"] }}
        </v-card-subtitle>
        <v-card-text>
          <v-divider />
          <v-card-subtitle>Details</v-card-subtitle>
          <v-text-field
            :value="streamObj['started_at']"
            label="Started"
            dense
            rounded
            readonly
          />
          <v-text-field
            :value="streamObj['ended_at']"
            label="Ended"
            dense
            rounded
            readonly
          />
          <v-text-field
            :value="numberToHHMMSSMS(streamObj['duration'])"
            label="Duration (hh:mm:ss:ms)"
            dense
            rounded
            readonly
          />
          <v-divider />
          <template v-if="streamObj['viewers'].length > 0">
            <v-card-subtitle>Viewers</v-card-subtitle>
            <v-list dense>
              <v-list-item v-for="viewer in streamObj['viewers']" :key="viewer">
                {{ viewer }}
              </v-list-item>
            </v-list>
          </template>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import StreamRecording from "@/components/streams/StreamRecording.vue";

export default {
  name: "StreamCard",
  components: { StreamRecording },
  props: {
    stream: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      streamObj: this.stream,
    };
  },
  watch: {
    streamInStore: {
      handler(value) {
        this.streamObj = value;
      },
    },
  },
  methods: {
    numberToHHMMSSMS(value) {
      const hours = Math.floor(value / 3600000);
      value -= hours * 3600000;
      const minutes = Math.floor(value / 60000);
      value -= minutes * 60000;
      const seconds = Math.floor(value / 1000);
      value -= seconds * 1000;
      const milliseconds = value;

      return (
        `${hours.toString().padStart(2, "0")}:` +
        `${minutes.toString().padStart(2, "0")}:` +
        `${seconds.toString().padStart(2, "0")}:` +
        `${milliseconds.toString().padStart(3, "0")}`
      );
    },
  },
  mounted() {
    this.streamObj = this.streamInStore;
  },
  created() {
    this.streamObj = this.stream;
  },
  computed: {
    streamInStore() {
      return this.$store.getters.getStream(this.stream.uuid);
    },
  },
};
</script>

<style scoped>
</style>