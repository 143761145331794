export default {
    getTeam: (state) => (id) => {
        return (state.teams) ? state.teams.find(x => x.id === id) : null;
    },
    getTeamName: (state) => (id) => {
        return (state.teams) ? state.teams.find(x => x.id === id)['name'] : null;
    },
    getTeamMembers: (state) => (id) => {
        if (!state.teams) return null;
        const team = state.teams.find(x => x.id === id);
        try {
            return team["members"]
        } catch (e) {
            if (e.name === "TypeError") {
                return undefined
            }
            throw e;
        }
    },
    getRoleNumberFromName: (state) => (name) => {
        return state.roles.findIndex(x => x.toLowerCase() === name.toLowerCase());
    },
    getRoleNameFromNumber: (state) => (number) => {
        return state.roles[number];
    }
}