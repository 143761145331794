import { axiosApi } from "@/modules/axios/axiosApi";

export default {
	resetActiveStreamStore(context) {
		context.commit('setActiveStreamStore', {
			arn: "",
			connected: false,
			uuid: "",
			name: "",
			viewers: [],
			invalidViewers: [],
			unknownViewers: []
		});
	},
	setStreamName(context, { name }) {
		if (name) name = name.trim();
		context.commit('setStreamName', {
			name: name
		});
	},
	setStreamUUID(context, { uuid }) {
		context.commit('setStreamUUID', {
			uuid: uuid
		});
	},
	async setStreamViewers(context, { viewers }) {
		let uniqueViewers = [...new Set(viewers)];

		// Manage viewers on the backend if stream is active (connected)
		if (context.state.connected === true) {
			const companyID = context.rootState.organisationStore.companyID;

			const addViewers = uniqueViewers.filter(v => !context.state.viewers.includes(v));
			if (addViewers.length > 0) {
				const respAdd = await axiosApi.post('api/streams/live/viewers/', {
					viewers: addViewers,
					uuid: context.state.uuid
				}, {
					params: {
						action: 'add',
						id: companyID
					}
				});
				const invalid = [... new Set(respAdd.data['invalid_users'].concat(context.state.invalidViewers))];
				const unknown = [... new Set(respAdd.data['unknown_users'].concat(context.state.unknownViewers))];
				context.commit('setInvalidUnknownViewers', {
					invalid: invalid,
					unknown: unknown
				});
			}

			const removeViewers = context.state.viewers.filter(v => !uniqueViewers.includes(v));
			if (removeViewers.length > 0) {
				const respRemove = await axiosApi.post('api/streams/live/viewers/', {
					viewers: removeViewers,
					uuid: context.state.uuid
				}, {
					params: {
						action: 'remove',
						id: companyID
					}
				});
				const invalid = [... new Set(respRemove.data['invalid_users'].concat(context.state.invalidViewers))];
				const unknown = [... new Set(respRemove.data['unknown_users'].concat(context.state.unknownViewers))];
				context.commit('setInvalidUnknownViewers', {
					invalid: invalid,
					unknown: unknown
				});
			}

			if (context.state.invalidViewers.length > 0) {
				uniqueViewers = uniqueViewers.filter(v => !context.state.invalidViewers.includes(v))
			}
			// if (context.state.unknownViewers.length > 0) {
			// 	uniqueViewers = uniqueViewers.filter(v => !context.state.unknownViewers.includes(v))
			// }
		}

		context.commit('setViewers', {
			viewers: uniqueViewers
		});
	},
	startedStream(context) {
		context.commit('setStreamConnected', { connected: true });
	},
	endedStream(context) {
		context.commit('setStreamConnected', { connected: false });
	},
	resetStreamViewers(context) {
		context.commit('resetStreamViewers');
	},
	/**
	 * Add viewers to the active stream.
	 * @param context Vuex store context
	 * @param viewers Array of the viewers' emails whom viewers should be added.
	 */
	addStreamViewers(context, { viewers }) {
		context.dispatch('setStreamViewers', { viewers: context.state.viewers.concat(viewers) }).then();
	},
	/**
	 * Remove viewers from the active stream
	 * @param context Vuex store context
	 * @param remove Array of the viewers' emails whom viewers should be removed.
	 */
	removeStreamViewer(context, { remove }) {
		let viewers = context.state.viewers.filter(v => !remove.includes(v));
		context.dispatch('setStreamViewers', { viewers: viewers }).then();
	}
}