<template>
  <v-text-field
      v-model="email"
      label="Email"
      @change="emailChanged"
      dense outlined type="email" :counter="64"
      :rules="emailRules" required
  >
    <template v-slot:append-outer>
      <template v-if="userStore.emailVerified">
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon
                v-bind="attrs"
                v-on="on"
                color="success"
            >
              mdi-shield-check
            </v-icon>
          </template>
          <span>
            Your email address has been verified.
          </span>
        </v-tooltip>
      </template>
      <ButtonVerifyEmailAddress v-else/>
    </template>
  </v-text-field>
</template>

<script>
import {mapState} from "vuex";
import ButtonVerifyEmailAddress from "@/components/buttons/ButtonVerifyEmailAddress.vue";

export default {
  name: "TextFieldEmail",
  components: {ButtonVerifyEmailAddress},
  props: ['modelValue'],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  data() {
    return {
      email: '',
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
            !v ||
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid",
      ],
    }
  },
  methods:{
    emailChanged(){
      this.$emit('update:modelValue', this.email);
    }
  },
  created() {
    this.email = this.modelValue;
  },
  computed: {
    ...mapState(['userStore'])
  }
}
</script>

<style scoped>

</style>