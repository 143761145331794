<template>
  <ContentDiv>
    <PageTitle>Streams</PageTitle>
    <p>
      View your
      {{ organisationStore.companyID ? "organisation's " : "" }}streams,
      access recordings, and check upload status of your recorded streams.
    </p>
    <PageTitleDivider />
    <v-data-table
      class="mt-5"
      :headers="headers"
      :items="streams"
      :items-per-page="15"
      :loading="loadingData"
      dense
      loading-text="Loading streams... Please wait"
      sort-by="started_at"
      :sort-desc="true"
    >
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:header.duration="{ header }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="grey lighten-1"> mdi-information</v-icon>
            </v-btn>
            {{ header.text }}
          </template>
          <span>hh:mm:ss:ms</span>
        </v-tooltip>
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.duration="{ item }">
        {{ numberToHHMMSSMS(item.duration) }}
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.recording="{ item }">
        <StreamUploadProgress :uuid="item.uuid"/>
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.actions="{ item }">
        <StreamDownloadButton
            v-if="$store.getters.getStream(item.uuid)['recording'] !== ''"
            :stream="item"
        />
        <v-dialog
          v-model="dialog[item.uuid]"
          width="60%"
          class="overflow-hidden"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="primary">mdi-eye-settings-outline</v-icon>
            </v-btn>
          </template>
          <StreamCard
              :stream="item"
              @closeDialog="closeDialog(item.uuid)"
          />
        </v-dialog>
      </template>
    </v-data-table>
  </ContentDiv>
</template>

<script>
import ContentDiv from "@/components/ContentDiv.vue";
import PageTitle from "@/components/PageTitle.vue";
import PageTitleDivider from "@/components/dividers/PageTitleDivider.vue";
import {initDB} from "@/modules/localDB";
import StreamCard from "@/components/streams/StreamCard.vue";
import StreamDownloadButton from "@/components/streams/StreamDownloadButton.vue";
import StreamUploadProgress from "@/components/streams/StreamUploadProgress.vue";
import {mapState} from "vuex";

export default {
  name: "ViewStreamHistory",
  components: {
    StreamUploadProgress,
    StreamDownloadButton,
    StreamCard,
    PageTitle,
    PageTitleDivider,
    ContentDiv,
  },
  data() {
    return {
      headers: [
        {
          text: "Title",
          value: "name",
          align: "left",
        },
        {
          text: "Owner",
          value: "owner",
          align: "left",
        },
        {
          text: "Started",
          value: "started_at",
          align: "center",
        },
        {
          text: "Ended",
          value: "ended_at",
          align: "center",
        },
        {
          text: "Duration",
          value: "duration",
          align: "right",
        },
        {
          text: "Recorded",
          value: "recording",
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          align: "right",
          sortable: false,
        },
      ],
      streams: [],
      loadingData: false,
      dialog: {},
    };
  },
  watch: {
    streamsInStore: {
      handler(value) {
        this.streams = value;
      }
    },
    organisationIdInStore: {
      handler() {
        this.getStreams();
      }
    }
  },
  methods: {
    async getStreams() {
      this.loadingData = true;
      await this.$store.dispatch('getAllStreams');
      this.loadingData = false;
    },
    async fetchStream(uuid) {
      this.loadingData = true;
      await this.$store.dispatch('fetchStream', {
        uuid: uuid
      });
      this.loadingData = false;
    },
    numberToHHMMSSMS(value) {
      const hours = Math.floor(value / 3600000);
      value -= hours * 3600000;
      const minutes = Math.floor(value / 60000);
      value -= minutes * 60000;
      const seconds = Math.floor(value / 1000);
      value -= seconds * 1000;
      const milliseconds = value;

      return (
        `${hours.toString().padStart(2, "0")}:` +
        `${minutes.toString().padStart(2, "0")}:` +
        `${seconds.toString().padStart(2, "0")}:` +
        `${milliseconds.toString().padStart(3, "0")}`
      );
    },
    closeDialog(uuid) {
      this.dialog[uuid] = false;
    },
  },
  created() {
    this.streams = this.streamsInStore;
    initDB();
    this.getStreams();
  },
  computed: {
    ...mapState(['streamStore', 'organisationStore']),
    streamsInStore() {
      return this.streamStore.streams;
    },
    organisationIdInStore() {
      return this.organisationStore.companyID;
    }
  },
};
</script>

<style scoped>
</style>