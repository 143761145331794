<template>
  <v-img
    id="logo-img"
    :src="organisationStore.logo"
    :lazy-src="organisationStore.logo"
    height="50%"
    contain
  />
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LogoImg",
  created() {
    if (this.organisationStore.companyID) {
      this.$store.dispatch("getCompanyAssets");
    }
  },
  computed: {
    ...mapState(["organisationStore"]),
  },
};
</script>

<style scoped lang="scss">
#logo-img {
  flex: 0 0 auto;
  justify-content: center;
}
</style>
