<template>
    <ContentDiv>
     <PageTitle class="secondary--text">Error</PageTitle>
     <p>Page Not Found</p>
    </ContentDiv>
</template>

<script>
import ContentDiv from "../components/ContentDiv.vue";
import PageTitle from "../components/PageTitle.vue"

export default {
    name: "NotFoundView",
    components:{ContentDiv, PageTitle},
}
</script>
