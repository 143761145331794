<template>
  <v-container class="fill-height justify-center align-center">
    <canvas
        class="video-element mx-auto mb-4 pa-2"
        id="video-canvas"
    />
  </v-container>
</template>

<script>
export default {
  name: "ViewerUnauthorisedRecording",
  props: {
    source: {
      require: true,
      type: String
    },
    title: {
      require: true,
      type: String
    }
  },
  data() {
    return {
      video: null,
      canvas: null,
      canvasContext: null,
      callbackID: null,
      titleBox: {}
    }
  },
  methods: {
    draw() {
      this.canvas.width = this.video.videoWidth;
      this.canvas.height = this.video.videoHeight;
      this.callbackID = window.requestAnimationFrame(this.draw);
      this.canvasContext.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight);

      this.canvasContext.fillStyle = "rgba(150, 150, 150, 0.8)";
      this.canvasContext.fillRect(this.titleBox.x, this.titleBox.y, this.titleBox.width, this.titleBox.height);
      this.canvasContext.fillStyle = "#FFFFFF";
      this.canvasContext.fillText(this.title, this.titleBox.x, this.titleBox.y+this.titleBox.height);
    }
  },
  mounted() {
    this.canvas = document.getElementById('video-canvas');
    this.canvasContext = this.canvas.getContext('2d');

    this.canvasContext.font = "48px serif";

    const textMetrics = this.canvasContext.measureText(this.title);
    this.titleBox = {
      x: 10,
      y: 10,
      width: (textMetrics.actualBoundingBoxLeft + textMetrics.actualBoundingBoxRight)*1.1,
      height: (textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent)*1.1
    }

    this.video = document.createElement('video');
    this.video.src = this.source;

    this.video.addEventListener('loadeddata', () => {
      // this.draw()
    }, false);
    this.video.addEventListener("play", () => {
      this.draw()
    }, false);
    this.video.addEventListener("pause", () => {
      window.cancelAnimationFrame(this.callbackID);
      this.callbackID = undefined;
    }, false);
    this.video.addEventListener("ended", () => {
      window.cancelAnimationFrame(this.callbackID);
      this.callbackID = undefined;
    }, false);
    this.video.play();
  }
}
</script>

<style scoped>

.video-element {
  width: auto;
  height: 80vh;
  min-height: 50%;
}

</style>