<template>
  <v-select
      label="Member role"
      v-model="selected"
      :items="allRoles"
      @change="publish"
  />
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "SelectTeamRole",
  props: {
    email: {
      type: String,
      required: true
    },
    role: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: this.role,
      allRoles: []
    }
  },
  methods: {
    publish() {
      const roleNumber = this.$store.getters.getRoleNumberFromName(this.selected);
      this.$emit("memberUpdated", {
        email: this.email,
        role: roleNumber
      })
    }
  },
  watch: {
    rolesStore(value) {
      this.allRoles = value;
    }
  },
  mounted() {
    this.allRoles = this.rolesStore;
    if ((this.allRoles == null) || (this.allRoles.length === 0)) {
      this.$store.dispatch('getRoles');
    }
  },
  computed: {
    ...mapState(['teamStore']),
    rolesStore() {
      return this.teamStore.roles;
    }
  }
}
</script>

<style scoped>

</style>