<template>
  <v-container class="ma-0" fluid>
    <StreamAddViewersCard v-if="!connected">
      <template v-slot:default>
        <WebRTCConnect
          :connected="connected"
          :enabled="
            !connection_state_wait &&
            activeStreamStore.name != null &&
            activeStreamStore.name !== ''
          "
          @connectClicked="connectHandler"
          @disconnectClicked="disconnectCleanUp"
        ></WebRTCConnect>
      </template>
    </StreamAddViewersCard>
    <WebRTCConnect
      v-else
      :connected="connected"
      :enabled="!connection_state_wait"
      @connectClicked="connectHandler"
      @disconnectClicked="disconnectCleanUp"
    ></WebRTCConnect>

    <v-row class="text-center">
      <v-col :lg="12" :md="12" :sm="12" cols="12"></v-col>
      <!--  <ConnectionStats v-if="connected" :bitrate="bitrate" :jitter="jitter"></ConnectionStats> -->
      <v-col class="justify-center" cols="12" :lg="12" :md="12" :sm="12">
        <v-row>
          <div class="video-container" v-show="connected">
            <video
              class="video_feed rounded-s"
              id="native_video_feed"
              autoplay
              playsinline
              controls
            ></video>
            <DrawerMasterVideo class="video-overlay" />
          </div>
        </v-row>
        <v-row>
          <audio
            v-show="false"
            class="video_feed rounded-s"
            id="remote_audio_feed"
            autoplay
            controls
          >
            Remote audio
          </audio>
        </v-row>
        <ContentDiv v-show="connected" class="video_settings">
          <ContentDiv class="video_settings basic">
            <v-switch
              v-model="recording"
              ref="recordingSwitch"
              label="Recording"
              color="secondary"
            >
            </v-switch>
            <v-switch
              v-model="computerVision"
              :label="`Computer Vision (Coming Soon)`"
              color="secondary"
              disabled
            >
            </v-switch>
          </ContentDiv>
          <ContentDiv class="video_settings advanced">
            <v-select
              id="videoSource"
              v-model="videoSource"
              :items="videoSources"
              :return-object="true"
              dense
              item-text="label"
              item-value="label"
              label="Video source"
              outlined
            >
            </v-select>
            <v-select
              id="resolution"
              v-model="resolution"
              :items="resolutions()"
              :return-object="true"
              dense
              item-text="to_string"
              item-value="to_string"
              label="Resolution"
              outlined
            >
            </v-select>
            <v-select
              id="framerate"
              v-model="framerate"
              :items="frameRates()"
              dense
              label="Frame rate"
              outlined
            >
            </v-select>
          </ContentDiv>
        </ContentDiv>
        <ContentDiv v-show="connected" class="audio_settings">
          <AudioController
            :audioFail="audioFail"
            @audioSourceChange="(source) => (audioSource = source)"
          >
          </AudioController>
        </ContentDiv>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AudioController from "./controllers/AudioController.vue";
import ContentDiv from "@/components/ContentDiv.vue";
import StreamAddViewersCard from "@/components/streams/StreamAddViewersCard.vue";
import WebRTCConnect from "@/components/buttons/WebRTCConnect.vue";
import { mapState } from "vuex";
import { axiosApi } from "src/modules/axios/axiosApi.js";
import { Stream } from "src/modules/webrtc/stream.mjs";
import { SessionStorageItems } from "src/modules/globalConstants.mjs";
import DrawerMasterVideo from "@/components/drawers/DrawerMasterVideo.vue";
import { resolutions, frameRates } from "@/modules/webrtc/mediaSettings";

export default {
  name: "VideoFeedDiv",
  components: {
    AudioController,
    ContentDiv,
    DrawerMasterVideo,
    StreamAddViewersCard,
    WebRTCConnect,
    /*, ConnectionStats */
  },
  data() {
    return {
      currentStream: {},
      bitrate: null,
      jitter: null,
      connected: false,
      connection_state_wait: false,
      recording: false,
      computerVision: false,
      videoSource: null,
      videoSources: [],
      resolution: null,
      framerate: null,
      audioSource: null,
      audioFail: false,
    };
  },
  watch: {
    recording: {
      handler() {
        this.recordingChange();
      },
    },
    videoSource: {
      handler() {
        if (this.videoSource == null || this.videoSource.label === "") {
          this.loadVideoDevices();
        }
        this.userMediaSourceChanges();
      },
    },
    audioSource: {
      handler() {
        this.userMediaSourceChanges();
      },
    },
    resolution: {
      handler() {
        this.userMediaSourceChanges();
      },
    },
    framerate: {
      handler() {
        this.userMediaSourceChanges();
      },
    },
    viewers(val) {
      if (val.length > 8) {
        this.$nextTick(() => this.viewers.pop());
      }
    },
  },
  methods: {
    frameRates() {
      return frameRates;
    },
    resolutions() {
      return resolutions;
    },
    async connectHandler() {
      if (this.connected) return;
      this.connection_state_wait = true;
      const videoSourceIntervalID = setInterval(() => {
        this.videoSources = this.videoSources.filter((item) => {
          if (typeof item != "object") {
            return false;
          }
          return !(item.id.length === 0 && item.label.length === 0);
        });
        if (this.videoSources.length < 1) {
          this.loadVideoDevices();
        } else {
          this.videoSource = this.videoSources[0];
          clearInterval(videoSourceIntervalID);
        }
      }, 500);
      document
        .getElementById("native_video_feed")
        .addEventListener("play", () => {
          this.connection_state_wait = false;
        });
      let uuid = this.activeStreamStore.uuid;
      if (!uuid) {
        const resp = await axiosApi.post("/api/streams/live/", {
          name: this.activeStreamStore.name,
          organisation: this.organisationStore.companyID,
          viewers: this.activeStreamStore.viewers,
        });
        uuid = resp.data.uuid;
        this.$store.dispatch("setStreamUUID", { uuid: uuid }).then();
      }
      this.Stream.setChannelName(uuid);
      this.currentStream = {
        name: this.channelName,
        uuid: uuid,
      };

      this.connected = true;
      this.$localDB.pauseUploads();
      this.Stream.start();
    },
    async disconnectCleanUp() {
      this.$store.dispatch("resetActiveStreamStore").then();
      this.connected = false;
      this.$localDB.resumeUploads();
      this.recording = false;
      this.Stream.mediaRecorderHandler(this.recording);
      await this.Stream.stop();
      await axiosApi.put("/api/streams/live/", {
        uuid: this.currentStream.uuid,
      });
      this.Stream.setChannelName(null);
      sessionStorage.removeItem(`MASTER_${SessionStorageItems.ChannelUUID}`);
      this.currentStream = {};
      this.$localDB.initialiseUploadProgressStore().then();
    },
    userMediaSourceChanges() {
      if (this.videoSources.length === 0) {
        this.loadVideoDevices();
      }
      if (this.Stream == null) {
        return;
      }
      if (this.videoSource == null) {
        this.videoSource = this.videoSources[0];
      }
      if (this.resolution == null) {
        this.resolution = resolutions[2];
      }
      if (this.framerate == null) {
        this.framerate = frameRates[2];
      }

      if (
        !this.Stream.updateUserMedia(
          this.videoSource,
          this.resolution,
          this.framerate,
          this.audioSource
        )
      ) {
        this.audioFail = true;
        this.videoSource = null;
        this.audioSource = null;
        this.recording = null;
        this.framerate = null;
      }
    },
    async loadVideoDevices() {
      this.videoSources = [];
      await navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices.forEach((device) => {
          switch (device.kind) {
            case "videoinput":
              this.videoSources.push({
                id: device.deviceId,
                label: device.label,
              });
              break;
            default:
              break;
          }
        });
      });
    },
    recordingChange() {
      this.recording = this.Stream.mediaRecorderHandler(this.recording);
    },
  },
  mounted() {
    if (this.Stream == null) {
      this.Stream = new Stream(
        document.getElementById("native_video_feed"),
        "MASTER",
        this.userStore.uuid
      );
      // this.Stream.attachAudioElement(
      //   document.getElementById("remote_audio_feed")
      // );
      /* setInterval(() => {
                if (this.connected){
                    console.log("Calling getStats");
                    this.jitter = this.Stream.getStat("jitter");
                    this.bitrate = this.Stream.getStat("bitrate");
                    console.log(this.jitter);
                    console.log(this.bitrate);
                }
            }, 1500); */
    }

    // If session there is an active session, connect to it.
    if (sessionStorage.getItem(`MASTER_${SessionStorageItems.ChannelARN}`)) {
      this.valid = true;
      this.connectHandler();
    }
  },
  computed: {
    ...mapState(["userStore", "organisationStore", "activeStreamStore"]),
  },
};
</script>

<style lang="scss" scoped>
.video-feed {
  width: auto;
  margin: auto;
}

.video-container {
  position: relative;
  overflow: hidden;
  margin: auto;
  max-height: 60%;
  max-width: 90%;

  video {
    width: auto;
    height: 80vh;
    display: block;
  }
}

.v-toolbar__title {
  font-size: 1rem;
}

.w-100 {
  width: 100%;
}
</style>