<template>
  <ContentDiv>
    <PageTitle>About Us</PageTitle>
    <strong
      >Welcome<span class="secondary--text">{{
        userStore.firstName ? " " + userStore.firstName : ""
      }}</span></strong
    >
    <p>
      To increase situational awareness, we have developed a streaming solution,
      ESP (extra sensory perception), to consume, analyse and distribute payload
      data in real-time.
    </p>
    <p>
      ESP streams live video and data from a drone to end users: pilots,
      operators and a control centre(s). The uniqueness of our solution is our
      ability to incorporate AI to draw insights from the stream. Our payload
      capabilities enable us to provide reconnaissance to control centres to
      address the incident.
    </p>
    <PageTitleDivider />
    <WebsocketTestButton />
  </ContentDiv>
</template>

<script>
import ContentDiv from "@/components/ContentDiv.vue";
import PageTitle from "@/components/PageTitle.vue";
import PageTitleDivider from "@/components/dividers/PageTitleDivider.vue";
import { mapState } from "vuex";
import WebsocketTestButton from "@/components/buttons/WebsocketTestButton.vue";

export default {
  name: "AboutView",
  components: {
    ContentDiv,
    PageTitle,
    PageTitleDivider,
    WebsocketTestButton,
  },
  computed: {
    ...mapState(["userStore"]),
  },
};
</script>
