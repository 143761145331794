<template>
  <v-form id="form" ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="12" :lg="6" :md="12" :sm="12">
        <v-text-field
          v-model="firstName"
          label="First name"
          dense
          outlined
          type="text"
          :counter="64"
          :rules="nameRules"
          required
        />
      </v-col>
      <v-col cols="12" :lg="6" :md="12" :sm="12">
        <v-text-field
          v-model="lastName"
          label="Last name"
          dense
          outlined
          type="text"
          :counter="64"
          :rules="nameRules"
          required
        />
      </v-col>
    </v-row>
    <TextFieldEmail v-model="email" />
    <v-divider class="mb-6 primary" />
    <v-row>
      <v-col cols="12" :lg="6" :md="12" :sm="12">
        <AutocompleteCountry v-model="country" />
        <AutocompleteStates v-model="state" :country="country" />
        <AutocompleteCities v-model="city" :country="country" :state="state" />
      </v-col>
      <v-col cols="12" :lg="6" :md="12" :sm="12">
        <v-text-field
          v-model="zip"
          label="ZIP code"
          dense
          outlined
          type="text"
          :counter="5"
        />
        <v-textarea v-model="address" label="Physical Address" dense outlined />
      </v-col>
    </v-row>
    <v-divider class="mb-6 primary" />
    <v-row>
      <v-col cols="12" :lg="6" :md="12" :sm="12">
        <v-menu
          v-model="dobPicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dob"
              label="Date of birth"
              hint="YYYY-MM-DD format"
              persistent-hint
              prepend-inner-icon="mdi-calendar"
              dense
              outlined
              type="text"
              :rules="dobRules"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dob"
            no-title
            @input="dobPicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" :lg="6" :md="12" :sm="12">
        <v-radio-group
          v-model="darkMode"
          label="Theme preference"
          class="ma-0 pa-0"
        >
          <v-radio label="Dark mode" :value="true" />
          <v-radio label="Light mode" :value="false" />
          <v-radio label="System default" :value="null" />
        </v-radio-group>
      </v-col>
    </v-row>
    <ButtonPrimaryAction
      class="mt-3"
      :color="primary"
      size="small"
      @custom-click="submit"
      icon="mdi-content-save"
      >Save</ButtonPrimaryAction
    >
  </v-form>
</template>

<script>
import AutocompleteCountry from "@/components/autocomplete/AutocompleteCountry.vue";
import AutocompleteCities from "@/components/autocomplete/AutocompleteCities.vue";
import AutocompleteStates from "@/components/autocomplete/AutocompleteStates.vue";
import { mapState } from "vuex";
import TextFieldEmail from "@/components/textField/TextFieldEmail.vue";
import ButtonPrimaryAction from "../buttons/ButtonPrimaryAction.vue";

export default {
  name: "UserProfile",
  components: {
    TextFieldEmail,
    AutocompleteCountry,
    AutocompleteStates,
    AutocompleteCities,
    ButtonPrimaryAction,
  },
  data() {
    return {
      valid: null,
      email: "",
      firstName: "",
      lastName: "",
      nameRules: [
        (v) => !!v.trim() || "Name is required",
        (v) =>
          (v.trim() && v.trim().length >= 2) ||
          "Minimum length is 2 characters",
        (v) =>
          (v.trim() && v.trim().length <= 64) ||
          "Maximum length is 64 characters",
      ],
      dob: "1999-06-09",
      dobRules: [
        (v) =>
          /([0-9]){4}-([0-9]){2}-([0-9]){2}/g.test(v) ||
          !v ||
          "Date of birth must be a valid ISO date",
      ],
      dobPicker: false,
      country: "",
      state: "",
      city: "",
      zip: "",
      address: "",
      darkMode: null,
    };
  },
  methods: {
    async validate() {
      return await this.$refs.form.validate();
    },
    reset() {
      this.name = this.storeName || "";
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async submit() {
      const formValid = await this.validate();
      if (!formValid) {
        window.scrollTo(0, 0);
        return;
      }
      await this.$store
        .dispatch("updateUser", {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          dob: this.dob,
          country: this.country,
          countryState: this.state,
          city: this.city,
          zip: this.zip,
          address: this.address,
          darkMode: this.darkMode,
        })
        .then(() => {
          this.reloadUserFromStore();
        })
        .catch((err) => {
          console.log(err.response.request.response);
        });
    },
    reloadUserFromStore() {
      this.email = this.userStore.email;
      this.firstName = this.userStore.firstName;
      this.lastName = this.userStore.lastName;
      this.country = this.userStore.country;
      this.state = this.userStore.state;
      this.city = this.userStore.city;
      this.zip = this.userStore.zip;
      this.address = this.userStore.address;
      this.dob = this.userStore.dob;
      this.darkMode = this.userStore.darkMode;
    },
  },
  created() {
    this.reloadUserFromStore();
  },
  computed: {
    ...mapState(["userStore"]),
  },
};
</script>

<style scoped lang="scss">
#form {
  width: 85%;
  margin: auto;
}
</style>