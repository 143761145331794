<template>
  <ContentDiv>
    <PageTitle>Start Live Stream</PageTitle>
    <p>
      To start a live video stream choose a stream name. Then select which users
      should have access to the stream
    </p>
    <PageTitleDivider />
    <VideoFeedDiv/>
  </ContentDiv>
</template>

<script>
import ContentDiv from "../components/ContentDiv.vue";
import PageTitle from "../components/PageTitle.vue";
import PageTitleDivider from "../components/dividers/PageTitleDivider.vue";
import VideoFeedDiv from "@/components/Master.vue"

export default {
  name: "StartStreamView",
  components: {
    ContentDiv,
    PageTitle,
    PageTitleDivider,
    VideoFeedDiv
  },
};
</script>

<style scoped lang="scss">
</style>