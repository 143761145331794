import axios from "axios";
import { memorizedRefreshToken } from "./refreshToken";
import {axiosApi} from "./axiosApi"
import { APIBaseURL } from "src/modules/globalConstants.mjs";

const api = axiosApi

api.defaults.baseURL = APIBaseURL;

api.interceptors.request.use(
  async (config) => {
    const accessToken = sessionStorage.getItem('accessToken')
    if (accessToken) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${accessToken}`,
        
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await memorizedRefreshToken();

      if (result?.access) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${result?.access}`,
        };
      }

      return axios(config);
    }
    return Promise.reject(error);
  }
);

export const axiosInterceptor = axios;