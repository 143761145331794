<template>
  <v-card class="pa-4 ma-4" outlined>
    <v-row>
      <v-card-title>{{ teamName }}</v-card-title>
      <v-spacer />
      <v-card-actions>
        <DialogTeamManagement
          :team_id="team_id"
          @updatingTeam="readTeamStore()"
          @createdTeam="teamCreated"
        />
        <ButtonPrimaryAction
          class="mx-2 mb-3"
          size="small"
          color="red"
          @custom-click="deleteDialog = true"
          icon="mdi-delete"
          >Delete</ButtonPrimaryAction
        >
        <v-dialog v-model="deleteDialog">
          <v-card width="20%">
            <v-card-title>Delete team</v-card-title>
            <v-card-text>
              Are you sure you want to delete this team?
            </v-card-text>
            <v-card-actions>
              <ButtonPrimaryAction
                class="mx-3 mb-3"
                size="small"
                color="error"
                @custom-click="deleteTeam"
                icon="mdi-delete"
                >Delete</ButtonPrimaryAction
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-row>

    <v-card-text width="100%">
      <v-data-table
        :headers="headers"
        :items="members"
        :items-per-page="15"
        :loading="loadingData"
        loading-text="Loading members... Please wait"
        width="100%"
        dense
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import DialogTeamManagement from "@/components/dialogs/DialogTeamManagement.vue";
import ButtonPrimaryAction from "../buttons/ButtonPrimaryAction.vue";

export default {
  name: "TeamCard",
  components: { DialogTeamManagement, ButtonPrimaryAction },
  props: {
    team_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name",
          align: "left",
        },
        {
          text: "Email",
          value: "email",
          align: "left",
        },
        {
          text: "Role",
          value: "role",
          align: "right",
        },
      ],
      teamName: null,
      members: [],
      loadingData: true,
      deleteDialog: false,
    };
  },
  methods: {
    async getTeamMembers() {
      this.loadingData = true;
      await this.$store.dispatch("fetchTeamMembers", this.team_id);
      this.members = this.$store.getters.getTeamMembers(this.team_id);
      this.loadingData = false;
    },
    readTeamStore() {
      this.teamName = this.$store.getters.getTeamName(this.team_id);
      this.members = this.$store.getters.getTeamMembers(this.team_id);
    },
    deleteTeam() {
      this.$store
        .dispatch("deleteTeam", this.team_id)
        .then(() => this.$emit("updatedTeams"));
      this.deleteDialog = false;
    },
    teamCreated() {
      this.$emit("updatedTeams");
    },
  },
  watch: {
    teamName(value) {
      if (value) {
        this.teamName = value;
      }
    },
    teamMembers(value) {
      if (value) {
        this.members = value;
      }
    },
  },
  mounted() {
    this.getTeamMembers();
    this.readTeamStore();
  },
  computed: {
    ...mapState(["teamStore"]),
  },
};
</script>

<style lang="sass" scoped>
</style>