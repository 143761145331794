<template>
  <v-container class="ma-0" fluid>
    <v-container v-if="!connected" class="pa-0">
      <v-row>
        <v-col :lg="9" :md="12" :sm="12" cols="12" class="d-flex flex-column">
          <v-card flat min-height="150">
            <v-toolbar flat dense>
              <v-toolbar-title class=""
                >Select an available stream</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-icon color="secondary">mdi-numeric-1-box-outline</v-icon>
            </v-toolbar>
            <v-layout>
              <v-flex>
                <v-col
                  :lg="10"
                  :md="10"
                  :sm="10"
                  cols="10"
                  class="d-flex flex-row ma-auto"
                >
                  <v-select
                    dense
                    outlined
                    class="w-50"
                    v-model="streamSelected"
                    :items="availableStreams"
                    item-text="name"
                    item-value="uuid"
                    no-data-text="No streams available"
                    :label="availableStreamsLabel"
                    :disabled="availableStreamsDisabled"
                  >
                  </v-select
                  ><v-btn icon @click="getViewerStreams">
                    <v-icon>mdi-cached</v-icon>
                  </v-btn>
                </v-col>
              </v-flex>
            </v-layout>
          </v-card>
        </v-col>
        <v-col :lg="3" :md="12" :sm="12" cols="12" class="d-flex flex-column"
          ><v-card flat min-height="150">
            <v-toolbar flat dense>
              <v-toolbar-title class="">Join Stream!</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon color="secondary">mdi-numeric-2-box-outline</v-icon>
            </v-toolbar>
            <v-layout>
              <v-flex>
                <v-col class="d-flex flex-row justify-center">
                  <WebRTCConnect
                    :disabled="streamSelected != null"
                    :connected="connected"
                    :enabled="!connection_state_wait"
                    @connectClicked="connect"
                    @disconnectClicked="disconnect"
                  ></WebRTCConnect>
                </v-col>
              </v-flex>
            </v-layout> </v-card
        ></v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col class="justify-center" cols="12" :lg="12" :md="12" :sm="12">
          <v-btn
            v-if="connected"
            small
            elevation="0"
            color="error"
            @click="disconnect"
            class="button_main"
            id="disconnect_webrtc"
          >
            Disconnect
            <v-icon right dark> mdi-stop-circle </v-icon>
          </v-btn>
          <video
            v-show="connected"
            class="video_feed"
            id="remote_video_feed"
            autoplay
            playsinline
            controls
          >
            Remote feed
          </video>
          <ContentDiv v-show="connected" class="audio_settings">
            <AudioController
              :audioFail="audioFail"
              @audioSourceChange="(source) => audioSourceChange(source)"
            >
            </AudioController>
          </ContentDiv>
        </v-col> </v-row
    ></v-container>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { Stream } from "src/modules/webrtc/stream.mjs";
import { SessionStorageItems } from "src/modules/globalConstants.mjs";
import ContentDiv from "../ContentDiv.vue";
import WebRTCConnect from "../buttons/WebRTCConnect.vue";
import AudioController from "../controllers/AudioController.vue";
/* import ConnectionStats from "./banners/connectionStats.vue"; */

import { axiosApi } from "src/modules/axios/axiosApi.js";

export default {
  name: "VideoFeedDiv",
  data() {
    return {
      connected: false,
      connection_state_wait: false,
      jitter: null,
      bitrate: null,
      audioSource: null,
      audioFail: false,
      streamSelected: null,
      streamsLoading: false,
      availableStreams: [],
      availableStreamsLabel: null,
      availableStreamsDisabled: true,
    };
  },
  methods: {
    connect() {
      this.connection_state_wait = true;
      document
        .getElementById("remote_video_feed")
        .addEventListener("play", () => {
          this.connection_state_wait = false;
        });
      setTimeout(() => {
        this.connection_state_wait = false;
      }, 1000);
      this.connected = true;
      this.Stream.start();
    },
    disconnect() {
      this.connected = false;
      this.Stream.stop();
    },
    userMediaSourceChanges() {
      if (this.Stream == null) {
        return;
      }
      if (this.audioSource == null) {
        console.log("No audio source selected.");
      }

      if (!this.Stream.updateUserMedia(null, null, null, this.audioSource)) {
        this.audioFail = true;
        this.audioSource = null;
      } else {
        this.audioFail = false;
      }
    },
    audioSourceChange(source) {
      console.log(`Audio source change event handler ${this.audioSource}`);
      this.audioSource = source;
    },
    async getViewerStreams() {
      this.streamsLoading = true;
      this.availableStreams = [];
      this.streamSelected = null;
      const resp = await axiosApi.get("/api/streams/live/viewers/");
      this.availableStreams = JSON.parse(JSON.stringify(resp.data));
      this.availableStreamsDisabled = this.availableStreams.length === 0;
      this.streamsLoading = false;
    },
  },
  watch: {
    audioSource: {
      handler() {
        console.log(`Audio source change handler ${this.audioSource}`);
        this.userMediaSourceChanges();
      },
    },
    streamSelected: {
      handler() {
        this.Stream.setChannelName(this.streamSelected);
      },
    },
  },
  mounted() {
    if (this.Stream == null) {
      this.Stream = new Stream(
        document.getElementById("remote_video_feed"),
        "VIEWER",
        this.userStore.uuid
      );
      /* setInterval(() => {
                this.jitter = this.Stream.getStat("jitter");
                this.bitrate = this.Stream.getStat("bitrate");
            }, 1500); */
      // If session there is an active session, connect to it.
      if (sessionStorage.getItem(`VIEWER_${SessionStorageItems.ChannelARN}`)) {
        this.connect();
      }
    }
  },
  created() {
    this.getViewerStreams();
  },
  components: {
    ContentDiv,
    WebRTCConnect,
    AudioController /*, ConnectionStats */,
  },
  computed: {
    ...mapState(["userStore"]),
  },
};
</script>

<style lang="scss" scoped>
.video_feed {
  width: 100%;
  margin: auto;
  border-radius: 5px;
}
.v-toolbar__title {
  font-size: 1rem;
}

.w-50 {
  width: 50%;
}
</style>