import {
    Role
} from "amazon-kinesis-video-streams-webrtc";

import {
    startMaster,
    stopMaster,
    getMediaRecorder,
    setUserMedia as setUserMediaMaster,
    setRemoteAudio
} from "src/modules/webrtc/master/master.mjs";

import {
    startViewer,
    stopViewer,
    reloadRemoteView,
    setUserMedia as setUserMediaViewer
} from "src/modules/webrtc/viewer/viewer.mjs";

import {
    checkAccessTokens,
    setClientID
} from "../getAccessKeys.mjs";


export class Stream {
    #connected;
    #stats;
    #channelName;
    #clientID;
    #customAxiosInstance;

    constructor(videoElement, role, clientID) {
        if (!(videoElement instanceof HTMLVideoElement)) {
            throw new TypeError("videoElement must be of type HTMLVideoElement.")
        }
        this.#connected = false;
        this.#customAxiosInstance = null;
        this.#stats = {};
        this.#clientID = clientID
        if (role === Role.MASTER) {
            this.startMethod = startMaster;
            this.stopMethod = stopMaster;
            this.mediaRecorder = getMediaRecorder();
            this.setUserMedia = setUserMediaMaster;
            this.setRemoteAudioElement = setRemoteAudio;
        } else if (role === Role.VIEWER) {
            this.startMethod = startViewer;
            this.stopMethod = stopViewer;
            this.setUserMedia = setUserMediaViewer;
        } else {
            throw new TypeError(`role must be ${Role.MASTER} or ${Role.VIEWER}`);
        }
        this.role = role;
        this.videoElement = videoElement;

        this.videoElement.addEventListener('stalled', (event) => {
            alert("Stalled local view.")
            console.log(`[MASTER] Local view stalled: ${event}`)
        });
        setClientID(this.#clientID);
    }

    setChannelName(channel) {
        this.#channelName = channel;
    }

    async start() {
        if (this.#channelName == null) {
            throw new ReferenceError(`Channel name not set.`);
        }
        if (this.#clientID == null) {
            console.log(this.#clientID);
            throw new ReferenceError(`Client ID not set.`);
        }
        await checkAccessTokens(this.#customAxiosInstance);
        await this.startMethod(this.videoElement, this.#onStatsReport, this.#onRemoteDataMessage, this.#channelName);
        this.#connected = true;
    }

    async stop() {
        this.#connected = false;
        await this.stopMethod();
    }

    attachAudioElement(element) {
        if (!(element instanceof HTMLAudioElement)) {
            throw TypeError("element must be of type HTMLAudioElement")
        }
        this.setRemoteAudioElement(element);
    }

    setCustomAxiosInstance(instance) {
        if (instance) {
            this.#customAxiosInstance = instance;
        }
    }

    mediaRecorderHandler(recording) {
        if (!this.#connected) {
            console.log("No active stream");
            return false;
        }
        console.log("Media Recorder handler called");

        if (this.role === Role.VIEWER) {
            throw new ReferenceError(`Method not available for ${Role.VIEWER}.`)
        }

        if (this.mediaRecorder == null) { 
            this.mediaRecorder = getMediaRecorder(); // try once more
            if (this.mediaRecorder == null) {
                console.log("Media Recorder is null");
                return false;
            }
        }

        switch (this.mediaRecorder.state) {
            case "inactive":
                console.log("Media recorder inactive");
                if (recording) {
                    try {
                        this.mediaRecorder.start(500);
                        this.mediaRecorder.addEventListener("dataavailable", (event) => {
                            window.$localDB.commitVideoPacket(this.#channelName, event.data);
                        });
                    } catch (error) {
                        console.error(error);
                        return false
                    }
                }
                break;
            case "recording":
                console.log("Media recorder is recording");
                if (!recording) {
                    this.mediaRecorder.stop();
                }
                break;
            case "paused":
                console.log("Media recorder has been paused.");
                break;
            default:
                console.log("Media recorder state unknown.");
                break;
        }
        return recording;
    }

    updateUserMedia(video_source, resolution, frameRate, audio_source) {
        if (!this.#connected) {
            return false;
        }
        if (this.role !== Role.MASTER) {
            video_source = null;
        }
        if ((typeof video_source !== 'object') || (typeof resolution !== 'object') || ((frameRate) && (typeof frameRate !== "number")) || (typeof audio_source !== 'object')) {
            console.error('Source type invalid')
            return false;
        }

        let constraints = {};

        let video = {};
        if (video_source) {
            if ('id' in video_source) {
                video['deviceId'] = {
                    'exact': video_source['id']
                }
            }
            if ('width' in resolution) {
                video['width'] = { ideal: resolution['width'] }
            }
            if ('height' in resolution) {
                video['height'] = { ideal: resolution['height'] }
            }
            video["framerate"] = {
                ideal: frameRate
            }
        } else {
            video = false;
        }
        constraints['video'] = video;

        let audio = {}
        if (audio_source == null) {
            audio = false;
        } else if ('id' in audio_source) {
            audio['deviceId'] = {
                'exact': audio_source['id'] || 'default'
            }
        }
        constraints["audio"] = audio;

        return this.setUserMedia(constraints);
    }

    reloadFeed() {
        if (this.role === Role.VIEWER) {
            return reloadRemoteView();
        } else {
            throw new ReferenceError(`Method not available for ${Role.MASTER}.`)
        }
    }

    getStat(statName) {
        if (statName) {
            switch (typeof statName) {
                case "string":
                    return this.#stats[statName];
                case "object":
                    if (Array.isArray(statName)) {
                        const statsObj = {};
                        statName.forEach((name) => {
                            statsObj[name] = this.#stats[name];
                        });
                        return statsObj;
                    } else {
                        throw TypeError("statName must be string or array.");
                    }
                default:
                    throw TypeError("statName must be string or array.");
            }
        } else {
            return this.#stats;
        }
    }

    #onRemoteDataMessage() {

    }

    #onStatsReport(statsReport) {
        // eslint-disable-next-line no-unused-vars
        let statsOutput = "";
        statsReport.forEach((report) => {
            statsOutput += `Report: ${report.type} ID: ${report.id}\n` + `Timestamp: ${report.timestamp}\n`;

            Object.keys(report).forEach((statName) => {
                if (statName !== "id" && statName !== "timestamp" && statName !== "type") {
                    statsOutput += `${statName}: ${report[statName]}\n`;
                    //this.#stats[statName] = report[statName];
                }
            });
        });

        /* console.log("\nConnection stats:");
        console.log(statsOutput);
        console.log(`\n`); */
    }

}