<template>
  <ContentDiv>
    <v-form v-model="valid" width="100%" ref="form">
      <v-row align="center" justify="space-between" width="100%">
        <v-autocomplete
          v-model="email"
          :items="organisationMembers"
          label="Member email address"
          required
        />
        <v-spacer />
        <v-select
          label="Member role"
          v-model="selectedRole"
          :items="allRoles"
          required
        />
      </v-row>
      <v-row>
        <ButtonPrimaryAction
          v-if="!connected"
          size="small"
          :disabled="!valid"
          @custom-click="newMember"
          id="connect_webrtc"
          icon="mdi-plus"
          >Add</ButtonPrimaryAction
        >
      </v-row>
    </v-form>
  </ContentDiv>
</template>

<script>
import { mapState } from "vuex";
import ContentDiv from "@/components/ContentDiv.vue";
import { axiosApi } from "@/modules/axios/axiosApi";
import ButtonPrimaryAction from "../buttons/ButtonPrimaryAction.vue";

export default {
  name: "TeamAddMemberPanel",
  components: { ContentDiv, ButtonPrimaryAction },
  props: {
    team_id: {
      type: Number,
      require: false,
      default: null,
    },
  },
  data() {
    return {
      valid: false,
      organisationMembers: [],
      allRoles: [],
      selectedRole: null,
      email: null,
    };
  },
  methods: {
    newMember() {
      this.valid = this.$refs.form.validate();
      if (!this.valid) return;
      const roleNumber = this.$store.getters.getRoleNumberFromName(
        this.selectedRole
      );
      this.$emit("memberAdded", {
        email: this.email,
        role: roleNumber,
      });
    },
  },
  created() {
    this.$store.dispatch("getRoles").then(() => {
      this.allRoles = this.teamStore.roles;
    });
    const url = this.team_id
      ? `api/teams/${this.team_id}/organisation_members/`
      : "api/teams/organisation_members/";
    axiosApi
      .get(url, {
        params: { id: this.organisationStore.companyID },
      })
      .then((resp) => {
        resp.data.forEach((user) => {
          this.organisationMembers.push(user);
        });
      });
  },
  computed: {
    ...mapState(["teamStore", "organisationStore"]),
  },
};
</script>

<style scoped>
</style>