<template>
  <hr class="secondary page-title-divider mb-5" />
</template>

<script>
export default {
  name: "PageTitleDivider",
};
</script>

<style lang="scss" scoped>
.page-title-divider {
  width: 30%;
  height: 1px;
  margin: auto;
  border: none;
}
</style>