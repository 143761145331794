<template>
  <!-- TODO Inherit a shared component with Organisation Members -->
  <v-container>
    <v-row class="my-4 px-4 justify-center">
      <PageSubtitle>Active members</PageSubtitle>
      <v-spacer />
      <MemberCUDialog
        :permissions="allPermissions"
        @member-updated="getMembers()"
      />
    </v-row>
    <v-data-table
      v-if="loadedHeaders"
      :headers="headers"
      :items="members"
      :items-per-page="15"
      :loading="loadingData"
      loading-text="Loading members... Please wait"
      dense
    >
      <template
        v-for="n in permissionCount"
        v-slot:[`item.${Object.keys(allPermissions)[n-1]}`]="{ item }"
      >
        <IconMemberPermission
          :key="n"
          :permissionValue="Number(item[Object.keys(allPermissions)[n - 1]])"
        >
        </IconMemberPermission>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.actions="{ item }">
        <MemberCUDialog
          :memberEmail="item.email"
          :memberDefaultPermissions="createMemberPermissionDict(item)"
          :editIcon="true"
          @member-updated="updateMember"
        />
        <v-icon small @click="deleteMember(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <PageTitleDivider />
    <PageSubtitle class="text-start">Pending member</PageSubtitle>
    <OrganisationPendingMembers ref="pendingMembers" />
  </v-container>
</template>

<script>
import IconMemberPermission from "src/components/icons/IconMemberPermission.vue";
import MemberCUDialog from "src/components/dialogs/MemberCUDialog.vue";
import { axiosApi } from "src/modules/axios/axiosApi";
import { mapState } from "vuex";
import PageTitleDivider from "src/components/dividers/PageTitleDivider.vue";
import PageSubtitle from "src/components/PageSubtitle.vue";
import OrganisationPendingMembers from "src/components/organistions/OrganisationPendingMembers.vue";

export default {
  name: "OrganisationMembers",
  components: {
    PageSubtitle,
    PageTitleDivider,
    IconMemberPermission,
    OrganisationPendingMembers,
    MemberCUDialog /* MemberCUDialogDataTable */,
  },
  data() {
    return {
      loadingData: true,
      loadedHeaders: false,
      allPermissions: [],
      headers: [],
      members: [],
      permissionCount: 1,
      showNewMemberDialog: false,
    };
  },
  watch: {
    organisationID: {
      handler() {
        this.getMembers();
      },
    },
  },
  methods: {
    async getAllPermissions() {
      if (!this.organisationStore.allPermissions) {
        await this.$store.dispatch("getAllPermissions");
      }
      this.allPermissions = this.organisationStore.allPermissions;
      this.permissionCount = Object.keys(this.allPermissions).length;
      this.setHeaders(this.allPermissions);
      this.loadedHeaders = true;
    },
    setHeaders(permissions) {
      this.headers = [
        {
          text: "Name",
          value: "name",
          align: "left",
        },
        {
          text: "Email",
          value: "email",
          align: "left",
        },
      ];
      for (let key in permissions) {
        this.headers.push({
          text: permissions[key]["display_name"],
          value: key,
          align: "center",
        });
      }
      this.headers.push({
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "right",
      });
    },
    getMembers() {
      this.loadingData = true;
      axiosApi
        .get("/api/user/organisation/members/", {
          params: {
            id: this.organisationStore.companyID,
          },
        })
        .then((response) => {
          this.loadingData = false;
          this.setMembersList(response.data);
        })
        .catch((e) => {
          console.error(e);
        });
      if (this.$refs.pendingMembers) this.$refs.pendingMembers.getMembers();
    },
    setMembersList(details) {
      this.members = [];
      details.forEach((mem) => {
        const entry = {
          name: (mem["first_name"] + " " + mem["last_name"]).trim(),
          email: mem["email"],
        };
        for (const perm in this.allPermissions) {
          entry[perm] = 0;
        }
        for (const [key, value] of Object.entries(mem["permissions"])) {
          entry[key] = value ? "2" : "1";
        }
        this.members.push(entry);
      });
      this.loadingData = false;
    },
    updateMember(memberDetails) {
      this.members.forEach((member) => {
        if (member.email === memberDetails.email) {
          for (const perm in this.allPermissions) {
            member[perm] = 0;
          }
          for (const [key, value] of Object.entries(
            memberDetails["permissions"]
          )) {
            member[key] = value ? "2" : "1";
          }
        }
      });
    },
    deleteMember(item) {
      axiosApi
        .delete("/api/user/organisation/members/", {
          params: { id: this.organisationStore.companyID },
          data: {
            members: [item.email],
          },
        })
        .then(this.getMembers);
    },
    createMemberPermissionDict(item) {
      const permissionDict = {};
      for (const permission in this.allPermissions) {
        permissionDict[permission] = Number(item[permission]) || 0;
      }
      return permissionDict;
    },
  },
  created() {
    this.getAllPermissions();
    this.getMembers();
  },
  computed: {
    ...mapState(["organisationStore"]),
    organisationID() {
      return this.organisationStore.companyID;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-head-group {
  text-align: center;
  border-top: 1px solid $primary;
  border-left: 1px solid $primary;
  border-right: 1px solid $primary;
  border-collapse: collapse;
}
</style>