import {undefinedOrValue} from "@/store/helpers";

export const sessionStorageKeys = {
    arn: "activeStreamStoreARN",
    connected: "activeStreamStoreStreamConnected",
    uuid: "activeStreamStoreUUID",
    name: "activeStreamStoreName",
    viewers: "activeStreamStoreViewers",
    invalidViewers: "activeStreamStoreInvalidViewers",
    unknownViewers: "activeStreamStoreUnknownViewers"
}

export default {
    arn: undefinedOrValue(sessionStorage.getItem(sessionStorageKeys.arn)),
    connected: undefinedOrValue(sessionStorage.getItem(sessionStorageKeys.connected)) ?
        JSON.parse(sessionStorage.getItem(sessionStorageKeys.connected)) : false,
    uuid: undefinedOrValue(sessionStorage.getItem(sessionStorageKeys.uuid)),
    name: undefinedOrValue(sessionStorage.getItem(sessionStorageKeys.name)),
    viewers: undefinedOrValue(sessionStorage.getItem(sessionStorageKeys.viewers)) ?
        JSON.parse(sessionStorage.getItem(sessionStorageKeys.viewers)) : [],
    invalidViewers: undefinedOrValue(sessionStorage.getItem(sessionStorageKeys.invalidViewers)) ?
        JSON.parse(sessionStorage.getItem(sessionStorageKeys.invalidViewers)) : [],
    unknownViewers: undefinedOrValue(sessionStorage.getItem(sessionStorageKeys.unknownViewers)) ?
        JSON.parse(sessionStorage.getItem(sessionStorageKeys.unknownViewers)) : []
}