export default {
    setStreamStore(state, { streams }) {
        state.streams = streams;
    },
    setAllStreams(state, { streams }) {
        state.streams = streams;
    },
    setStream(state, { stream }) {
        const idx = state.streams.findIndex(x => x.uuid === stream.uuid);
        state.streams[idx] = stream;
    },
}