<template>
  <ContentDiv>
    <v-row class="logo-container ma-6">
      <a href="/user/account/create" class="my-0 pa-0 mx-auto">
        <v-img
            id="logo-img"
            src="@/assets/logo.svg"
            contain
        />
      </a>
    </v-row>

    <v-row v-if="validURL === true && validOTP === false">
      <AuthOTP
          v-model="otp"
          @submit="validateOTP()"
          @otp-expired="otpExpiredHandler"
          :otp-error-message="otpErrorMessage"
      />
    </v-row>

    <v-row v-if="validOTP === true">
      <template v-if="type === 'viewer'">
        <ViewerUnauthorised
            :stream-u-u-i-d="data['stream']"
            :viewer-i-d="data['identifier']"
            :axios-instance="api"
        />
      </template>
      <template v-else-if="type === 'recording'">
        <ViewerUnauthorisedRecording
          :source="data['recording']"
          :title="data['identifier']"
        />
      </template>
    </v-row>

  </ContentDiv>
</template>

<script>
import ContentDiv from "src/components/ContentDiv.vue";
import {axiosUnsafeApi} from "src/modules/axios/axiosApi";
import AuthOTP from "src/components/auth/AuthOTP.vue";
import ViewerUnauthorised from "../components/Viewer/ViewerUnauthorised.vue";
import axios from "axios";
import ViewerUnauthorisedRecording from "../components/Viewer/ViewerUnauthorisedRecording.vue";

export default {
  name: "ViewUnauthorisedViewing",
  components: {ViewerUnauthorisedRecording, ViewerUnauthorised, AuthOTP, ContentDiv},
  data() {
    return {
      validURL: false,
      otpErrorMessage: null,
      validOTP: false,
      otp: null,
      key: null,
      type: null,
      data: null,
      api: null
    }
  },
  methods: {
    async validateLink() {
      const resp = await axiosUnsafeApi
          .get(`api/unauthorised-viewer/${this.$route.params.uuid}`)
          .catch(() => {
            this.$router.push({name: 'Not Found'});
          });
      this.validURL = resp.status === 200;
      if (!this.validURL) {
        await this.$router.push({name: 'Not Found'});
      }
    },
    validateOTP() {
      axiosUnsafeApi
          .put(`api/unauthorised-viewer/${this.$route.params.uuid}`, {},{
            params: { otp: this.otp }
          })
          .then((resp) => {
            this.validOTP = resp.status === 200;
            if (this.validOTP) {
              this.setAPI(resp.data['key']);
              this.getDetails();
            }
          })
          .catch((err) => {
            this.otpErrorMessage = err.response.data;
          });
    },
    async getDetails() {
      const resp = await this.api.get(`api/unauthorised-viewer/${this.$route.params.uuid}/details`);
      this.type = resp.data['type'];
      this.data = resp.data['data'];
    },
    setAPI(key) {
      this.key = key;
      this.api = axios.create({
        baseURL: axiosUnsafeApi.defaults.baseURL,
        timeout: axiosUnsafeApi.defaults.timeout,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'TempAuthorisation': this.key
        }
      });
    },
    otpExpiredHandler() {
      console.log("OTP expired handler called")
    }
  },
  created() {
    this.validateLink();
  }
}
</script>

<style scoped lang="scss">
.logo-container {
  height: 15%;

  #logo-img {
    width: 17rem;
  }
}

a {
  text-decoration: none;
}

</style>