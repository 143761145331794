<template>
  <v-app-bar elevation="0" id="app-bar" app height="56px">
    <slot name="start"></slot>
    <v-spacer />
    <slot name="center"></slot>
    <v-spacer />
    <slot name="end"></slot>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
};
</script>
