<template>
  <v-list-item id="logo-container">
    <LogoImg v-if="ImgOrIcon === 'img'" />
    <LogoIcon v-if="ImgOrIcon === 'icon' || ImgOrIcon == null" />
  </v-list-item>
</template>

<script>
import LogoImg from "./LogoImg.vue";
import LogoIcon from "./LogoIcon.vue";

export default {
  props: ["ImgOrIcon"],
  name: "LogoContainer",
  components: { LogoImg, LogoIcon },
};
</script>

<style>
#logo-container {
  flex: 1 0 auto;
  justify-content: center;
  padding: 0;
  height: 55px;
  margin: auto;
  max-width: 80%;
}
</style>
