<template>
  <v-card class="fill-height pa-0 ma-0 transparent" width="100%" elevation="0">
    <v-card-title class="mb-1 pa-0 card-stream-team-title"
      >Select by Teams</v-card-title
    >
    <v-divider />
    <v-card-text class="px-3 py-0">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        clear-icon="mdi-close"
        clearable
        dense
        flat
        hide-details
        class="my-2 w-100"
      />
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-treeview
          dense
          v-model="selections"
          selection-type="leaf"
          :items="teams"
          item-children="members"
          :load-children="fetchMembers"
          return-object
          :search="search"
          :disabled="!canAddViewers"
          color="secondary"
          expand-icon="mdi-chevron-down"
          open-all
          selectable
          selected-color="secondary"
        >
        </v-treeview>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "StreamTeamSelection",
  data: () => ({
    teams: [],
    search: null,
    selections: [],
    viewers: [],
    canAddViewers: true,
  }),
  watch: {
    selections: {
      handler() {
        this.createViewersArray();
      },
    },
    viewers: {
      handler() {
        this.validateViewers();
        this.ensureSelectionMatchesViewers();
      },
    },
    viewersActiveStore: {
      handler(value) {
        this.viewers = value;
      },
    },
    companyIDOrganisationStore: {
      handler() {
        this.getTeams();
        if (this.activeStreamStore) {
          this.viewers = this.viewersActiveStore;
        }
      },
    },
  },
  methods: {
    async getTeams() {
      this.teams = [];
      await this.$store.dispatch("getAllTeams");
      await this.teamStore.teams.forEach((e) => {
        this.fetchMembers(e);
      });
    },
    async fetchMembers(team) {
      await this.$store.dispatch("fetchTeamMembers", team.id);
      const idx = this.teams.findIndex(x => x.id === team.id);
      if (idx >= 0) {
        this.teams[idx] = this.$store.getters.getTeam(team.id);
      } else {
        this.teams.push(this.$store.getters.getTeam(team.id));
      }
    },
    removeSelection(email) {
      let idx = this.selections.findIndex((x) => x.email === email);
      do {
        this.selections.splice(idx, 1);
        idx = this.selections.findIndex((x) => x.email === email);
      } while (idx > -1);
    },
    createViewersArray() {
      let teamViewers = [];
      this.selections.forEach((element) => teamViewers.push(element.email));
      if (teamViewers.length === 0) return;
      this.$store.dispatch("addStreamViewers", {
        viewers: teamViewers,
      });
    },
    validateViewers() {
      this.canAddViewers = this.$store.getters.canAddViewers;
    },
    /**
     * Ensures the viewers removed from the active viewers store are removed from the tree-view selection.
     * 'selections' needs to be updated in place, otherwise an infinite loop will occur (watchers).
     */
    ensureSelectionMatchesViewers() {
      this.selections.forEach((selection) => {
        if (!this.viewersActiveStore.includes(selection.email)) {
          this.removeSelection(selection.email);
        }
      });
    },
  },
  created() {
    this.getTeams();
    if (this.activeStreamStore) {
      this.viewers = this.viewersActiveStore;
    }
  },
  computed: {
    ...mapState(["teamStore", "activeStreamStore", "organisationStore"]),
    viewersActiveStore() {
      return this.activeStreamStore.viewers;
    },
    companyIDOrganisationStore() {
      return this.organisationStore.companyID;
    },
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}

.card-stream-team-title {
  font-size: 1rem;
}
</style>