if (!window.indexedDB) {
	window.alert("Your browser doesn't support a stable version of IndexedDB.")
}

const VideoObjectStoreName = "video";
const UploadProgressObjectStoreName = "upload";
const Indexes = {
	streamUUID: 'streamUuid',
	index: 'snippetIndex',
	snippet: 'snippet',
	total: 'total',
	uploaded: 'uploaded',
	uploadStarted: 'started'
}

/**
 * A class to interact with the local DB.
 */
export class LocalDatabase {
	#db
	#videoObjectStore
	#uploadObjectStore
	#ready
	#uploadProgressStoreReady
	#pauseUploads


	constructor(dbName, version) {
		this.#ready = false;
		this.#uploadProgressStoreReady = false;
		this.#pauseUploads = false;
		if (!version) version = 1;
		if (!(typeof version === 'number')) {
			throw TypeError("version must be a number");
		}
		if (!(typeof dbName === 'string')) {
			throw TypeError("dbName must be a string");
		}

		const request = window.indexedDB.open(dbName, version);
		request.onerror = (event) => {
			console.error(event);
			throw event
		};
		request.onsuccess = (event) => {
			this.#db = request.result;
			this.#ready = true;
		}
		request.onupgradeneeded = (event) => {
			this.#db = event.target.result;
			this.#createVideoObjectStore();
			this.#createUploadProgressObjectStore();
			this.initialiseUploadProgressStore().then(() => {
				this.#ready = true;
				window.dispatchEvent(new Event('indexDBSuccess'))
			});
		}
	}


	/**
	 * initialisation progress indication
	 * @return {boolean} indicative of whether initialisation is complete
	 */
	isReady() {
		return this.#ready;
	}


	/**
	 * initialisation progress indication
	 * @return {boolean} indicative of whether initialisation is complete
	 */
	isUploadProgressStoreReady() {
		return this.#uploadProgressStoreReady;
	}


	/**
	 * Boolean indicating whether uploads should be paused.
	 * @return {boolean}
	 */
	isUploadPaused() {
		return this.#pauseUploads
	}


	/**
	 * Sets pauseUploads to true
	 */
	pauseUploads() {
		console.log("Uploads have been paused");
		this.#pauseUploads = true;
	}


	/**
	 * Sets pauseUploads to false
	 */
	resumeUploads() {
		window.dispatchEvent(new Event("resumeUploads"));
		this.#pauseUploads = false;
	}


	/**
	 * Commit a video packet to the DB
	 * @param streamUUID The stream's uuid with which the video is associated
	 * @param packet The blob of data to be committed
	 * @type {(streamUUID : string, packet : Blob) => void}
	 */
	commitVideoPacket(streamUUID, packet) {
		const objectStore = this.#db
			.transaction(VideoObjectStoreName, 'readwrite')
			.objectStore(VideoObjectStoreName);

		let frameNumber = 0;

		const index = objectStore.index(Indexes.streamUUID);
		const cursor = index.openCursor(streamUUID, 'prev');
		cursor.onsuccess = (event) => {
			const result = event.target.result;
			if (result) {
				frameNumber = result['value'][Indexes.index] + 1;
			}
			const entry = {};
			entry[Indexes.streamUUID] = streamUUID;
			entry[Indexes.index] = frameNumber;
			entry[Indexes.snippet] = packet;
			objectStore.add(entry);
		}
		index.onerror = (event) => {
			console.error(event);
			throw event
		};
	}


	/**
	 * Get all stream with packets in the DB.
	 * @return {Promise<string[]>} Array of all the uuids present in the DB.
	 * @type {() => Promise<string[]>}
	 */
	async getAllStreams() {
		if (!this.#ready) return null;
		const objectStore = this.#db
			.transaction(VideoObjectStoreName, 'readonly')
			.objectStore(VideoObjectStoreName);

		const uuidList = []
		const index = objectStore.index(Indexes.streamUUID);
		const cursor = index.openCursor(null, 'nextunique');
		return await new Promise((resolve, reject) => {
			cursor.onsuccess = (event) => {
				const result = event.target["result"];
				if (result) {
					uuidList.push(result.key);
					result.continue()
				} else {
					resolve(uuidList);
				}
			}
			index.onerror = (event) => {
				console.error(event);
				reject(event);
			};
		})
	}


	/**
	 * Setup upload progress store with all pending streams
	 * @return {Promise<void>}
	 */
	async initialiseUploadProgressStore() {
		this.#uploadProgressStoreReady = false;
		const allStreams = await this.getAllStreams();
		const allUploads = await this.#getAllUploads();
		for (const uuid of allStreams) {
			const recordExists = await this.#existsUploadProgressRecord(uuid);
			if (recordExists) {
				await this.#setUploadSize(uuid);
			} else {
				await this.#createUploadProgressRecord(uuid);
			}
		}
		for (const uuid of allUploads) {
			if (!allStreams.includes(uuid)) {
				await this.#setUploadSize(uuid);
			}
		}
		this.#uploadProgressStoreReady = true;
		window.dispatchEvent(new Event('uploadProgressStoreReadySuccess'));
	}


	/**
	 * Get the first available packet in the DB for the associated stream.
	 * Removes the packet after the value has been returned.
	 * @param streamUUID - the applicable stream's uuid
	 * @return {Promise<Blob>} the earliest video packet available for the stream
	 * @type {(streamUUID : string) => Promise<Blob>}
	 */
	async getNextVideoPacket(streamUUID) {
		const objectStore = this.#db
			.transaction(VideoObjectStoreName, 'readonly')
			.objectStore(VideoObjectStoreName);

		const index = objectStore.index(Indexes.streamUUID);
		const cursor = index.openCursor(streamUUID, 'next');
		return await new Promise((resolve, reject) => {
			cursor.onsuccess = async (event) => {
				const result = event.target["result"];
				if (result) {
					await this.#deleteVideoStoreObject(result.primaryKey);
					this.#setUploadSize(streamUUID);
					resolve(result.value);
				} else {
					resolve(null)
				}
			}
			index.onerror = (event) => {
				console.error(event);
				reject(event);
			};
		});
	}


	/**
	 * Returns whether a recording has started uploading.
	 * @param uuid The recording identifier
	 * @return {Promise<boolean>} true if started, false if not
	 * @type {(uuid: string) => Promise<boolean>}
	 */
	async hasUploadStarted(uuid) {
		if (!(this.#ready && this.#uploadProgressStoreReady)) return null;
		const objectStore = this.#db
			.transaction(UploadProgressObjectStoreName, 'readonly')
			.objectStore(UploadProgressObjectStoreName);

		return await new Promise((resolve, reject) => {
			const resp = objectStore.get(uuid);
			resp.onsuccess = (event) => {
				const result = event.target['result'];
				if (result) {
					resolve(result[Indexes.uploadStarted]);
				} else {
					resolve(null);
				}
			}
			resp.onerror = (event) => reject(event);
		});
	}


	/**
	 * Sets that a recording has started uploading.
	 * @param uuid The recording identifier
	 * @return {Promise<void>}
	 * @type {(uuid: string) => Promise<void>}
	 */
	async setUploadStarted(uuid) {
		const objectStore = this.#db
			.transaction(UploadProgressObjectStoreName, 'readwrite')
			.objectStore(UploadProgressObjectStoreName);

		return await new Promise((resolve) => {
			objectStore.get(uuid).onsuccess = (event) => {
				const data = event.target['result'];
				if (data) {
					data[Indexes.uploadStarted] = true;
					objectStore.put(data).onsuccess = () => {
						window.dispatchEvent(new CustomEvent('streamUploadStarted', {
							detail: {
								uuid: uuid
							}
						}));
						resolve();
					}
				} else {
					resolve();
				}
			}
		});
	}


	/**
	 * Returns a recording's upload progress.
	 * @param uuid The recording identifier
	 * @return {Promise<number>} value between 0 and 100 as an upload percentage
	 * @type {(uuid: string) => Promise<number>}
	 */
	async uploadProgress(uuid) {
		const objectStore = this.#db
			.transaction(UploadProgressObjectStoreName, 'readonly')
			.objectStore(UploadProgressObjectStoreName);

		return await new Promise((resolve) => {
			objectStore.get(uuid).onsuccess = (event) => {
				const result = event.target['result'];
				if (result) {
					const total = Number(result[Indexes.total]);
					const uploaded = Number(result[Indexes.uploaded]);
					const percentage = Math.round((uploaded / total) * 100);
					resolve(percentage);
				} else {
					resolve(100);
				}
			}
		});
	}


	/**
	 * Delete an object in the video store with a specified id.
	 * @param id The id identifying the object
	 */
	#deleteVideoStoreObject(id) {
		return new Promise((resolve) => {
			this.#db
				.transaction(VideoObjectStoreName, 'readwrite')
				.objectStore(VideoObjectStoreName)
				.delete(id).onsuccess = () => {
					resolve();
				};
		});
	}


	/**
	 * Delete an object in the upload store with a specified id.
	 * @param id The id identifying the object
	 */
	#deleteUploadStoreObject(id) {
		return new Promise((resolve) => {
			this.#db
				.transaction(UploadProgressObjectStoreName, 'readwrite')
				.objectStore(UploadProgressObjectStoreName)
				.delete(id).onsuccess = () => {
					resolve();
				};
		});
	}


	/**
	 * Get the total size for the
	 * @param uuid The recording identifier
	 * @return {Promise<number>}
	 */
	async #getVideoTotalSize(uuid) {
		const objectStore = this.#db.transaction(VideoObjectStoreName, 'readonly').objectStore(VideoObjectStoreName);

		let size = 0;
		const index = objectStore.index(Indexes.streamUUID);
		const cursor = index.openCursor(uuid, 'next');
		return await new Promise((resolve, reject) => {
			cursor.onsuccess = (event) => {
				const result = event.target["result"];
				if (result) {
					size += result.value[Indexes.snippet].size
					result.continue()
				} else {
					resolve(size);
				}
			}
			index.onerror = (event) => {
				console.error(event);
				reject(event);
			};
		})
	}


	/**
	 * Calculates the amount of data already uploaded for a video recording.
	 * @param uuid The recording identifier
	 * @type {(uuid: string) => void}
	 */
	async #setUploadSize(uuid) {
		const remaining = await this.#getVideoTotalSize(uuid);
		if (remaining === 0) {
			window.dispatchEvent(new CustomEvent("uploadComplete", {detail: uuid}))
			await this.#deleteUploadStoreObject(uuid);
			return;
		}
		const objectStore = this.#db
			.transaction(UploadProgressObjectStoreName, 'readwrite')
			.objectStore(UploadProgressObjectStoreName);

		objectStore.get(uuid).onsuccess = (event) => {
			const data = event.target['result'];
			if (!data) return;
			data[Indexes.uploaded] = data[Indexes.total] - remaining;
			objectStore.put(data);
		}
	}


	/**
	 * Creates the video object store.
	 */
	#createVideoObjectStore() {
		this.#videoObjectStore = this.#db.createObjectStore(
			VideoObjectStoreName, {
				keyPath: 'id',
				autoIncrement: true
			});
		this.#videoObjectStore.createIndex(
			Indexes.streamUUID, Indexes.streamUUID, {
				unique: false
			});
		this.#videoObjectStore.createIndex(
			Indexes.index, Indexes.index, {
				unique: false
			});
		this.#videoObjectStore.createIndex(
			Indexes.snippet, Indexes.snippet, {
				unique: false
			});
	}


	/**
	 * Creates a store to track video upload progress
	 */
	#createUploadProgressObjectStore() {
		this.#uploadObjectStore = this.#db.createObjectStore(
			UploadProgressObjectStoreName, {
				keyPath: Indexes.streamUUID
			});
		this.#uploadObjectStore.createIndex(
			Indexes.total, Indexes.total, {
				unique: false
			});
		this.#uploadObjectStore.createIndex(
			Indexes.uploaded, Indexes.uploaded, {
				unique: false
			});
		this.#uploadObjectStore.createIndex(
			Indexes.uploadStarted, Indexes.uploadStarted, {
				unique: false
			});
	}


	/**
	 * Checks if a record in the upload progress store exists.
	 * @param uuid The record identifier
	 * @return {Promise<boolean>}
	 * @type {(uuid:string) => (Promise<boolean>)}
	 */
	async #existsUploadProgressRecord(uuid) {
		const objectStore = this.#db.transaction(UploadProgressObjectStoreName, 'readonly').objectStore(UploadProgressObjectStoreName);

		return await new Promise((resolve) => {
			objectStore.get(uuid).onsuccess = (event) => {
				const result = event.target['result'];
				resolve(result !== undefined)
			}
		});
	}


	/**
	 * Creates a record in the upload progress store.
	 * @param uuid The record identifier
	 * @return {Promise<void>}
	 * @type {(uuid:string) => (Promise<void>)}
	 */
	async #createUploadProgressRecord(uuid) {
		const record = {};
		record[Indexes.streamUUID] = uuid;
		record[Indexes.total] = await this.#getVideoTotalSize(uuid);
		record[Indexes.uploaded] = 0;
		record[Indexes.uploadStarted] = false;

		const objectStore = this.#db.transaction(UploadProgressObjectStoreName, 'readwrite').objectStore(UploadProgressObjectStoreName);

		return await new Promise((resolve) => {
			objectStore.add(record).onsuccess = (event) => {
				resolve()
			}
		});
	}


	/**
	 * Get all stream with uploads in progress.
	 * @return {Promise<string[]>} Array of all the uuids present in the DB.
	 * @type {() => Promise<string[]>}
	 */
	async #getAllUploads() {
		const objectStore = this.#db.transaction(UploadProgressObjectStoreName, 'readonly').objectStore(UploadProgressObjectStoreName);

		const request = objectStore.getAllKeys();
		return await new Promise((resolve, reject) => {
			request.onsuccess = (event) => {
				const result = event.target["result"];
				resolve(result);
			}
			request.onerror = (event) => {
				console.error(event);
				reject(event);
			};
		})
	}
}