import { axiosApi } from 'src/modules/axios/axiosApi'
import router from 'src/routes'

export default {
    userLogin(context, userCredentials) {
        return new Promise((resolve, reject) => {
            axiosApi.post('/api/token/', {
                email: userCredentials.username,
                username: userCredentials.username,
                password: userCredentials.password
            }).then(response => {
                context.commit('updateAuthStore', {
                    access: response.data.access,
                    refresh: response.data.refresh,
                    isAuthenticated: true
                })
                resolve()
            }).catch(err => {
                reject(err)
            })
        })
    },
    userLogout(context) {
        context.commit('updateAuthStore', { access: null, refresh: null, isAuthenticated: false });
        router.push({ name: "LoginView" }).then();
    }
}