import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import router from './routes.js'
import store from './store/index.js'
import { localDB } from "@/modules/localDB";
import colourContrastMixin from "./mixins/colorContrastMixin";
import websocketModule from "./modules/websockets/websockets"
import { WSBaseURL } from "src/modules/globalConstants.mjs";
import { axiosInterceptor } from "./modules/axios/axiosInterceptor";

Vue.prototype.$http = axiosInterceptor;
Vue.prototype.$localDB = localDB;
Vue.use(VueMeta)
Vue.use(websocketModule, {
  url: WSBaseURL,
  store,
})
Vue.config.productionTip = false
Vue.mixin(colourContrastMixin);

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta['requiresLogin'])) {
    if (!store.getters.loggedIn) {
      next({ name: 'LoginView' })
    }
    else {
      next()
    }
  }
  else {
    next()
  }
})

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
