import {setClientID} from "@/modules/getAccessKeys.mjs";

export default {
	updateUserStore(state, {
		username,
		email,
		firstName,
		lastName,
		uuid,
		dob,
		country,
		countryState,
		city,
		zip,
		address,
		darkMode,
		emailVerified
	}) {
		state.username = username;
		state.email = email;
		state.firstName = firstName;
		state.lastName = lastName;
		state.uuid = uuid;
		state.dob = dob;
		state.country = country;
		state.state = countryState;
		state.city = city;
		state.zip = zip;
		state.address = address;
		state.darkMode = darkMode;
		state.emailVerified = emailVerified;
		setClientID(uuid);
		sessionStorage.setItem('username', username);
		sessionStorage.setItem('email', email);
		sessionStorage.setItem('firstName', firstName);
		sessionStorage.setItem('lastName', lastName);
		sessionStorage.setItem('uuid', uuid);
		sessionStorage.setItem('dob', dob);
		sessionStorage.setItem('country', country);
		sessionStorage.setItem('state', countryState);
		sessionStorage.setItem('city', city);
		sessionStorage.setItem('zip', zip);
		sessionStorage.setItem('address', address);
		sessionStorage.setItem('darkMode', darkMode);
		sessionStorage.setItem('emailVerified', emailVerified);
	},
	updateDarkMode(state, darkMode) {
		state.darkMode = darkMode;
		sessionStorage.setItem('darkMode', darkMode);
	}
}