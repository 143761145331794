<template>
  <v-list class="text-left" dense nav>
    <v-list-item-group color="secondary">
      <template v-for="item in items">
        <template v-if="item.path && !item.children">
          <v-list-item :key="item.path" :to="item.path">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-else-if="item.children">
          <v-list-group
            :value="$route.path.indexOf(item.path) >= 0"
            :key="item.title"
            color="secondary"
            :prepend-icon="item.icon"
          >
            <template v-slot:activator>
              <v-list-item :ripple="false" class="ma-0 pa-0">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item
              v-for="child in item.children"
              :key="child.path"
              :to="child.path"
            >
              <v-list-item-icon class="justify-center">
                <v-icon
                  v-if="globalComponentStore.menuMiniState"
                  color="primary"
                  small
                >
                  {{ child.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon class="justify-center">
                <v-icon color="primary" small>{{ child.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
      </template>
      <slot name="end"></slot>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LinksNavigation",
  data: () => ({
    items: [
      // { title: "About Us", icon: "mdi-help-box", path: "/" },
      {
        title: "Live Video",
        icon: "mdi-video-box",
        children: [
          {
            title: "Start Stream",
            icon: "mdi-video-wireless-outline",
            path: "/start-stream",
          },
          {
            title: "Join Stream",
            icon: "mdi-video-wireless",
            path: "/join-stream",
          },
        ],
      },
      { title: "Stream History", icon: "mdi-history", path: "/streams" },
      {
        title: "Settings",
        icon: "mdi-cog",
        path: "/settings",
        children: [
          // {title: "All settings", icon: "mdi-cog", path: "/settings"},
          {
            title: "Organisation",
            icon: "mdi-office-building-cog",
            path: "/settings-organisation",
          },
          { title: "My profile", icon: "mdi-account", path: "/settings-user" },
          { title: "All Settings", icon: "mdi-cog", path: "/settings" },
        ],
      },
      { title: "Support", icon: "mdi-lifebuoy", path: "/support" },
    ],
  }),
  computed: {
    ...mapState(["globalComponentStore"]),
  },
};
</script>

<style scoped>
.theme--dark.v-list-item:hover::before,
.theme--dark.v-list-item--active:focus::before,
.theme--dark.v-list-item--active:hover::before,
.theme--dark.v-list-item--active::before {
  opacity: 0;
}
</style>
