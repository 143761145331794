<template>
  <v-app>
    <!-- App Bar Start-->
    <SystemBar v-if="authStore.isAuthenticated" />
    <AppBar v-if="authStore.isAuthenticated">
      <template v-slot:start>
        <IconDesktopNav />
        <IconMobileNav />
      </template>
      <template v-slot:center>
        <LogoContainer ImgOrIcon="img" v-if="$vuetify.breakpoint.mobile" />
      </template>
      <template v-slot:end>
        <IconNotificationBell />
        <DrawerProfile>
          <template v-slot:start>
            <IconTheme />
            <OrganisationSelect />
          </template>
          <template v-slot:center>
            <LinksProfile />
          </template>
          <template v-slot:end>
            <LinksLogout />
          </template>
        </DrawerProfile>
      </template>
    </AppBar>
    <!-- App Bar End-->

    <!--Desktop Nav Drawer Start-->
    <DrawerNavigationDesktop v-if="authStore.isAuthenticated">
      <template v-slot:start>
        <LogoContainer
          ImgOrIcon="img"
          v-if="!globalComponentStore.menuMiniState"
        />
        <LogoContainer
          ImgOrIcon="icon"
          v-if="globalComponentStore.menuMiniState"
        />
      </template>
      <template v-slot:center>
        <LinksNavigation />
      </template>
      <template v-slot:end>
        <LinksLogout />
      </template>
    </DrawerNavigationDesktop>
    <!--Desktop Nav Drawer End-->

    <!--Mobile Nav Drawer-->
    <DrawerNavigationMobile
      v-if="$vuetify.breakpoint.mobile && authStore.isAuthenticated"
    >
      <template v-slot:start>
        <LogoContainer ImgOrIcon="icon" />
      </template>
      <template v-slot:center>
        <LinksNavigation />
      </template>
      <template v-slot:end>
        <LinksLogout />
      </template>
    </DrawerNavigationMobile>
    <!--Mobile Nav End-->

    <!--Main Start-->
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <!--Main End-->
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import AppBar from "./components/appBars/AppBar";
import IconNotificationBell from "./components/icons/IconNotificationBell.vue";
import SystemBar from "./components/systemBars/SystemBar";
import IconDesktopNav from "./components/icons/IconDesktopNav";
import IconMobileNav from "./components/icons/IconMobileNav";
import LogoContainer from "./components/logos/LogoContainer";
import DrawerNavigationDesktop from "./components/drawers/DrawerNavigationDesktop";
import DrawerNavigationMobile from "./components/drawers/DrawerNavigationMobile";
import DrawerProfile from "./components/drawers/DrawerProfile";
import LinksNavigation from "./components/links/LinksNavigation";
import LinksProfile from "./components/links/LinksProfile";
import LinksLogout from "./components/links/LinksLogout";
import OrganisationSelect from "./components/selects/OrganisationSelect.vue";
import IconTheme from "@/components/icons/IconTheme.vue";

export default {
  name: "App",
  components: {
    IconTheme,
    IconNotificationBell,
    AppBar,
    SystemBar,
    DrawerProfile,
    IconDesktopNav,
    IconMobileNav,
    LogoContainer,
    DrawerNavigationDesktop,
    DrawerNavigationMobile,
    LinksNavigation,
    LinksProfile,
    LinksLogout,
    OrganisationSelect,
  },
  watch: {
    userDarkModePreference: {
      handler(value) {
        if (value == null) {
          if (window.matchMedia) {
            this.$vuetify.theme.dark = window.matchMedia(
              "(prefers-color-scheme: dark)"
            ).matches;
            window
              .matchMedia("(prefers-color-scheme: dark)")
              .addEventListener("change", (event) => {
                this.$vuetify.theme.dark = event.matches;
              });
          }
        } else {
          this.$vuetify.theme.dark = value;
        }
      },
    },
  },
  computed: {
    ...mapState([
      "userStore",
      "authStore",
      "organisationStore",
      "globalComponentStore",
      "websocketStore",
    ]),
    userDarkModePreference() {
      try {
        return this.userStore.darkMode;
      } catch (e) {
        if (e instanceof TypeError) {
          return null;
        }
        throw e;
      }
    },
  },
  metaInfo: {
    title: "Iris",
    titleTemplate: "%s | Driving the Innovation of Surveillance Systems",
  },
};
</script>

<style scoped lang="scss">
.v-application--wrap {
  flex-direction: row !important;
}

.container {
  padding: 0;
  display: flex;
  height: 100%;
  width: 100%;
}

.v-divider {
  border-color: rgba(0, 0, 0, 0.12) !important;
}
</style>

