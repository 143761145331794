<template>
  <v-img
    id="logo-icon"
    :src="organisationStore.icon"
    :lazy-src="organisationStore.icon"
    height="50%"
    contain
  />
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LogoIcon",
  created() {
    if (this.organisationStore.companyID) {
      this.$store.dispatch("getCompanyAssets");
    }
  },
  computed: {
    ...mapState(["organisationStore"]),
  },
};
</script>

<style scoped lang="scss">
#logo-icon {
  flex: 0 0 auto;
  justify-content: center;
}
</style>
