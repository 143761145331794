import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';
import {DefaultColours} from '@/store/modules/organisationStore/state'
import {undefinedOrValue} from "@/store/helpers";

Vue.use(Vuetify);

//Dark Theme Colours
let THEME_DARK_PRIMARY_COLOR = undefinedOrValue(localStorage.getItem("darkPrimaryColour"))
	|| DefaultColours.darkPrimary;
let THEME_DARK_SECONDARY_COLOR = undefinedOrValue(localStorage.getItem("darkSecondaryColour"))
	|| DefaultColours.darkSecondary;
let THEME_LIGHT_PRIMARY_COLOR = undefinedOrValue(localStorage.getItem("lightPrimaryColour"))
	|| DefaultColours.lightPrimary;
let THEME_LIGHT_SECONDARY_COLOR = undefinedOrValue(localStorage.getItem("lightSecondaryColour"))
	|| DefaultColours.lightSecondary;

const vuetify = new Vuetify({
	icons: {
		iconfont: 'mdi'
	},
	theme: {
		dark: true,
		themes: {
			dark: {
				primary: THEME_DARK_PRIMARY_COLOR,
				secondary: THEME_DARK_SECONDARY_COLOR,
				navigationDrawer: '#1E1E1E',
				navigationDrawerLinks: '#FFF',
				appBar: '#1E1E1E',
				appBarIcon: '#FFF',
				cardBackground: '#1E1E1E'
			},
			light: {
				primary: THEME_LIGHT_PRIMARY_COLOR,
				secondary: THEME_LIGHT_SECONDARY_COLOR,
				navigationDrawer: '#1E1E1E',
				navigationDrawerLinks: '#FFF',
				appBar: '#E0E0E0',
				appBarIcon: '#1E1E1E',
				cardBackground: '#E0E0E0'
			},
		},
	},
})

export default vuetify
