import { render, staticRenderFns } from "./OrganisationPendingMembers.vue?vue&type=template&id=a5cdad34&scoped=true&"
import script from "./OrganisationPendingMembers.vue?vue&type=script&lang=js&"
export * from "./OrganisationPendingMembers.vue?vue&type=script&lang=js&"
import style0 from "./OrganisationPendingMembers.vue?vue&type=style&index=0&id=a5cdad34&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5cdad34",
  null
  
)

export default component.exports