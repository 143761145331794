<template>
  <ContentDiv class="pa-0">
    <MapNodes/>
  </ContentDiv>
</template>

<script>
import ContentDiv from "../components/ContentDiv.vue";
import MapNodes from "../components/maps/MapNodes.vue";


export default {
  name: "AboutView",
  components: {
    ContentDiv,
    MapNodes
  },
};

</script>

<style scoped lang="scss">
</style>